import styled from '@emotion/styled';
import {
  fontWeight,
  fontStyle,
  color,
  textAlign,
  FontWeightProps,
  FontStyleProps,
  TextColorProps,
  TextAlignProps,
  SpaceProps,
  space,
  background,
  backgroundColor,
  BackgroundColorProps,
  display,
  DisplayProps,
  FontSizeProps,
  fontSize,
  MarginProps,
  margin,
} from 'styled-system';
import { down } from 'styled-breakpoints';
import { css } from '@emotion/react';
import { BaseTheme, dim, ThemeInputSize } from '../../theme';

export type HeaderProps = FontWeightProps &
  FontStyleProps &
  FontSizeProps &
  TextColorProps &
  SpaceProps &
  MarginProps &
  DisplayProps;

const headerBase = ({ theme }: { theme: BaseTheme }) => css`
  font-family: '${theme.fonts['header']}', sans-serif;
  margin-bottom: ${theme.space[2]};
`;

export const Header1 = styled.h1<HeaderProps>`
  ${headerBase};
  font-size: ${({ theme }) => theme.fontSizes[7]};
  ${down('mobile')} {
    font-size: ${dim(64)};
  }
  ${fontStyle}
  ${color}
  ${space}
  ${display}
  ${margin})
  font-weight: ${({ theme }) => theme.fontWeights['bold']};
  line-height: ${({ theme }) => theme.lineHeights[0]};
  letter-spacing: ${({ theme }) => theme.letterSpacings[0]};
`;

export const Header2 = styled.h2<HeaderProps>`
  ${headerBase};
  font-size: ${({ theme }) => theme.fontSizes[6]};
  ${down('mobile')} {
    font-size: ${({ theme }) => theme.fontSizes[5]};
  }
  font-weight: ${({ theme }) => theme.fontWeights['bold']};
  line-height: ${({ theme }) => theme.lineHeights[1]};
  letter-spacing: ${({ theme }) => theme.letterSpacings[2]};
  ${fontStyle}
  ${color}
  ${space}
`;

export const Header3 = styled.h3<HeaderProps>`
  ${headerBase};
  font-size: ${({ theme }) => theme.fontSizes[5]};
  ${down('mobile')} {
    font-size: ${dim(32)};
  }
  font-weight: ${({ theme }) => theme.fontWeights['bold']};
  line-height: ${({ theme }) => theme.lineHeights[1]};
  letter-spacing: ${({ theme }) => theme.letterSpacings[2]};
  ${fontStyle}
  ${color}
  ${space}
`;

export const Header4 = styled.h4<HeaderProps>`
  ${headerBase};
  font-size: ${({ theme }) => theme.fontSizes[4]};
  ${down('mobile')} {
    font-size: ${({ theme }) => theme.fontSizes[3]};
    font-weight: ${({ theme }) => theme.fontWeights['bold']};
    ${fontSize}
    ${fontWeight}
  }
  font-weight: ${({ theme }) => theme.fontWeights['medium']};
  line-height: ${({ theme }) => theme.lineHeights[2]};
  letter-spacing: ${({ theme }) => theme.letterSpacings[2]};
  ${fontStyle}
  ${fontSize}
  ${fontWeight}
  ${color}
  ${space}
  ${display}
`;

export const Header5 = styled.h5<HeaderProps>`
  ${headerBase};
  font-family: ${({ theme }) => theme.fonts['body']}, sans-serif;
  font-size: ${({ theme }) => theme.fontSizes[3]};
  ${down('mobile')} {
    font-size: ${({ theme }) => theme.fontSizes[2]};
  }
  font-weight: ${({ theme }) => theme.fontWeights['semiBold']};
  line-height: ${({ theme }) => theme.lineHeights[3]};
  letter-spacing: ${({ theme }) => theme.letterSpacings[1]};
  ${fontStyle}
  ${fontSize}
  ${fontWeight}
  ${color}
  ${space}
`;

export const Header6 = styled.h6<HeaderProps>`
  ${headerBase};
  font-family: ${({ theme }) => theme.fonts['body']}, sans-serif;
  font-size: ${dim(21)};
  ${down('mobile')} {
    font-size: ${dim(18)};
    ${fontSize}
    ${fontWeight}
  }
  font-weight: ${({ theme }) => theme.fontWeights['medium']};
  line-height: ${({ theme }) => theme.lineHeights[2]};
  letter-spacing: ${({ theme }) => theme.letterSpacings[2]};
  color: ${({ theme }) => theme.colors.grays['90']};
  ${fontStyle}
  ${fontSize}
  ${fontWeight}
  ${color}
  ${space}
`;

export const Header7 = styled.h6<HeaderProps>`
  ${headerBase};
  font-family: ${({ theme }) => theme.fonts['body']}, sans-serif;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  ${down('mobile')} {
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }
  font-weight: ${({ theme }) => theme.fontWeights['regular']};
  line-height: ${({ theme }) => theme.lineHeights[2]};
  letter-spacing: ${({ theme }) => theme.letterSpacings[2]};
  color: ${({ theme }) => theme.colors.grays['90']};
  ${fontStyle}
  ${color}
  ${space}
`;

export type TextProps = FontWeightProps &
  FontStyleProps &
  TextColorProps &
  TextAlignProps &
  SpaceProps;

const textBase = ({ theme }: { theme: BaseTheme }) => css`
  font-family: ${theme.fonts['body']}, sans-serif;
  font-weight: ${theme.fontWeights['regular']};
  font-style: normal;
  padding: 0;
  margin: 0;
`;

export const Text = styled.p<TextProps>`
  ${textBase};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  ${down('mobile')} {
    font-size: ${({ theme }) => theme.fontSizes[1]};
  }
  line-height: ${({ theme }) => theme.lineHeights[4]};
  letter-spacing: ${({ theme }) => theme.letterSpacings[2]};
  color: ${({ theme }: { theme: BaseTheme }) =>
    theme.colors.grays['90']}; // TODO: see if this can be a themed gray

  ${fontStyle}
  ${color}
  ${textAlign}
  ${space}
`;

export const Text2 = styled.p<TextProps>`
  ${textBase};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  ${down('mobile')} {
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }
  line-height: ${({ theme }) => theme.lineHeights[4]};
  letter-spacing: ${({ theme }) => theme.letterSpacings[2]};
  color: ${({ theme }: { theme: BaseTheme }) =>
    theme.colors.grays['90']}; // TODO: see if this can be a themed gray

  ${fontStyle}
  ${color}
  ${textAlign}
  ${space}
`;

export const Caption = styled.figcaption<TextProps>`
  ${textBase};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: ${({ theme }) => theme.fontWeights['regular']};
  line-height: ${({ theme }) => theme.lineHeights[3]};
  letter-spacing: ${({ theme }) => theme.letterSpacings[2]};
  color: ${({ theme }: { theme: BaseTheme }) =>
    theme.colors.grays['50']}; // TODO: see if this can be a themed gray
  ${down('mobile')} {
    font-size: ${dim(12)};
  }
  ${fontWeight}
  ${fontStyle}
  ${textAlign}
  ${space}
  ${color}
`;

export const Caption2 = styled.span<
  TextProps & BackgroundColorProps & { variant?: 'reverse' | 'plain' }
>`
  ${textBase};
  display: inline-block;
  margin: ${({ variant }) => (variant === 'plain' ? 0 : `${dim(2)} ${dim(4)}`)};
  padding: ${({ variant }) =>
    variant === 'plain' ? 0 : `${dim(2)} ${dim(4)}`};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  font-weight: ${({ theme }) => theme.fontWeights['semiBold']};
  line-height: ${({ theme }) => theme.lineHeights[3]};
  letter-spacing: ${({ theme }) => theme.letterSpacings[3]};
  ${down('mobile')} {
    letter-spacing: ${({ theme }) => theme.letterSpacings[2]};
  }
  color: ${({ theme, variant }) =>
    variant === 'plain' ? theme.colors.black : theme.colors.white};
  background-color: ${({ theme, variant }) =>
    variant === 'plain' ? 'none' : theme.colors.black};
  box-shadow: ${({ variant }) =>
    variant === 'plain' ? 'none' : `0 ${dim(4)} ${dim(4)} rgba(0, 0, 0, 0.35)`};
  ${fontWeight}
  ${fontStyle}
  ${textAlign}
  ${space}
  ${background}
  ${backgroundColor}
  ${color}
`;

export const ButtonText = styled.span<TextProps>`
  ${textBase};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights['regular']};
  line-height: ${({ theme }) => theme.lineHeights[5]};
  letter-spacing: ${({ theme }) => theme.letterSpacings[2]};
  ${down('mobile')} {
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }
  ${fontStyle}
  ${color}
  ${textAlign}
  ${space}
`;

export const ButtonText2 = styled.span<
  TextProps & {
    isSkewed?: boolean;
    size?: ThemeInputSize;
    sizeOverride?: string;
  }
>`
  ${textBase};
  display: inline-flex;
  align-items: center;
  font-size: ${({ theme, size, sizeOverride }) =>
    sizeOverride ? sizeOverride : theme.fontSizes[size === 'small' ? 0 : 1]};
  ${down('mobile')} {
    font-size: ${dim(12)};
  }
  font-weight: ${({ theme }) => theme.fontWeights['regular']};
  line-height: ${({ theme }) => theme.lineHeights[2]};
  letter-spacing: ${({ theme }) => theme.letterSpacings[2]};
  transform: ${({ isSkewed }) => (isSkewed ? 'skewX(0.286051442rad)' : 'none')};
  ${fontStyle}
  ${color}
  ${textAlign}
  ${space}
`;

export const FieldLabel = styled.label<TextProps>`
  ${textBase};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  padding-right: ${({ theme }) => theme.space[2]};
  ${fontWeight}
  ${fontStyle}
  ${color}
  ${textAlign}
  ${space}
`;

export const OptionLabel = styled.label<TextProps>`
  ${textBase};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  margin: 0 0 ${({ theme }) => theme.space[2]};
  ${down('mobile')} {
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }
  ${fontWeight}
  ${fontStyle}
  ${color}
  ${textAlign}
  ${space}
`;

export * from './RichText';
