import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import closeBig from '@iconify/icons-ci/close-big';
import plusIcon from '@iconify/icons-bx/plus';
import {
  GenericOverlay,
  roundedContainer,
  roundedContainerMobile,
} from '../GenericOverlay';
import Box from '../../Box';
import { Header5, Header6 } from '../../typography';
import IconButton from '../../inputs/IconButton';
import Button2 from '../../inputs/Button2';
import { ReadingListEntryInput } from './readingListEntryInput';
import { useTheme } from '@emotion/react';
import { BaseTheme, dim } from '../../../theme';
import { down } from 'styled-breakpoints';
import { LimitedTextarea } from '../../inputs/LimitedTextarea';
import { ReadingListEntry } from '../../../types';

const StyledReadingListsOverlay = styled(GenericOverlay)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ReadingListsOverlayContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${({ theme }) => roundedContainer(theme)};
  ${down('mobile')} {
    ${({ theme }) => roundedContainerMobile(theme)};
  }
`;

const ReadingListsOverlayHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ReadingListsOverlayBody = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow-y: auto;
  max-height: 67vh;
`;

const ReadingListEntriesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: ${dim(40)};
  margin-bottom: ${({ theme }) => theme.space[3]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grays[20]};
`;

const ReadingListCreationContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

const ReadingListCreationNew = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: ${dim(6)};
`;

export interface ReadingListsOverlayProps {
  title: string;
  isNew?: boolean;
  isDeleting?: boolean;
  readingListEntries?: ReadingListEntry[];
  listName?: string;
  listDescription?: string;
  onReadingListEntrySelect?: (id: string, value: boolean) => void;
  onReadingListChange(name: string, description: string): void;
  onRequestClose(): void;
}

type Overlay = React.FC<ReadingListsOverlayProps>;

export const ReadingListsOverlay: Overlay = ({
  title,
  readingListEntries = [],
  isNew = true,
  isDeleting = false,
  listName: _listName,
  listDescription: _listDescription,
  onReadingListEntrySelect,
  onReadingListChange,
  onRequestClose,
}) => {
  const [isEditingList, setIsEditingList] = React.useState(false);
  const [listName, setListName] = React.useState(_listName || '');
  const [listDescription, setListDescription] = React.useState(
    _listDescription || '',
  );
  useEffect(() => {
    setListName(_listName || '');
    setListDescription(_listDescription || '');
  }, [_listName, _listDescription]);

  const handleBackdropClick = (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (ev.target === ev.currentTarget) {
      onRequestClose();
    }
  };
  const handleListNameChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    setListName(ev.target.value);
  };
  const handleListDescriptionChange = (
    ev: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setListDescription(ev.target.value);
  };
  const handleChangeListClick = () => {
    setIsEditingList(false);
    onReadingListChange(listName, listDescription);
  };
  const {
    colors: { black },
  } = useTheme() as BaseTheme;
  return (
    <StyledReadingListsOverlay onClick={handleBackdropClick}>
      <ReadingListsOverlayContent aria-modal role={'dialog'}>
        <ReadingListsOverlayHeader>
          <Header5 mb="0">{title}</Header5>
          <IconButton onClick={onRequestClose}>
            <Icon icon={closeBig} height={30} color={black} />
          </IconButton>
        </ReadingListsOverlayHeader>
        <ReadingListsOverlayBody>
          {isNew && (
            <ReadingListEntriesContainer>
              {readingListEntries.map((entry) => (
                <ReadingListEntryInput
                  key={entry.id}
                  id={entry.id}
                  name={entry.name}
                  length={entry.length}
                  selected={entry.selected}
                  onSelect={(value) =>
                    onReadingListEntrySelect?.(entry.id, value)
                  }
                />
              ))}
            </ReadingListEntriesContainer>
          )}
          <ReadingListCreationContainer>
            {!isEditingList && isNew && (
              <ReadingListCreationNew>
                <IconButton onClick={() => setIsEditingList(true)}>
                  <Icon icon={plusIcon} height={32} color={black} />
                </IconButton>
                <Header6 m={0} ml={dim(24)}>
                  Create new Reading List
                </Header6>
              </ReadingListCreationNew>
            )}
            {!isEditingList && isDeleting && (
              <>
                <Header6 mt="3">
                  Are you sure you want to delete this list?
                </Header6>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  width="100%"
                  mt="3"
                >
                  <Button2
                    palette="blue"
                    variant="secondary"
                    onClick={handleChangeListClick}
                  >
                    Delete
                  </Button2>
                </Box>
              </>
            )}
            {(isEditingList || (!isNew && !isDeleting)) && (
              <>
                <LimitedTextarea
                  labelText="Name"
                  limit={300}
                  value={listName}
                  onChange={handleListNameChange}
                  style={{ marginTop: '1em', marginBottom: '1em' }}
                  placeholder="Enter reading list name..."
                  rows={1}
                />
                <LimitedTextarea
                  labelText="Description (optional)"
                  limit={600}
                  value={listDescription}
                  onChange={handleListDescriptionChange}
                  style={{ marginTop: '1em' }}
                  placeholder="Add a description..."
                  rows={1}
                />
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  width="100%"
                  mt="3"
                >
                  <Button2
                    palette="blue"
                    variant="secondary"
                    onClick={handleChangeListClick}
                  >
                    {isNew ? 'Create' : 'Save'}
                  </Button2>
                </Box>
              </>
            )}
          </ReadingListCreationContainer>
        </ReadingListsOverlayBody>
      </ReadingListsOverlayContent>
    </StyledReadingListsOverlay>
  );
};
