import React from 'react';
import styled from '@emotion/styled';
import { Text } from '../typography';
import { NavLocData } from '../../types';

export const StyledBreadcrumbWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const StyledBreadcrumb = styled(Text)``;
export const StyledActiveBreadcrumb = styled(Text)`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.grays['400']};
`;

export interface BreadcrumbProps {
  location: NavLocData[];
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ location }) => {
  return (
    <StyledBreadcrumbWrapper>
      {location
        .filter((navloc) => navloc.title)
        .map((navloc, index, data) => {
          if (index < data.length - 1)
            return (
              <StyledBreadcrumb key={navloc.title}>
                {navloc.title} &gt;&nbsp;
              </StyledBreadcrumb>
            );
          return (
            <StyledActiveBreadcrumb key={navloc.title}>
              {navloc.title}
            </StyledActiveBreadcrumb>
          );
        })}
    </StyledBreadcrumbWrapper>
  );
};

export default Breadcrumb;
