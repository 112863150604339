import styled from '@emotion/styled';
import { dim } from '../../../theme';

export const ReadingExperienceStepBoundary = styled.div`
  display: flex;
  flex-direction: column;
`;
export const READING_EXPERIENCE_CHOICE_THUMBNAIL_SIZE = {
  width: 168,
  height: 159,
};
export const ReadingExperienceChoices = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: ${({ theme }) => theme.space[4]} 0;
`;
export const ReadingExperienceChoice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ReadingExperienceImage = styled.img`
  margin: 0 ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[3]};
  width: ${dim(READING_EXPERIENCE_CHOICE_THUMBNAIL_SIZE.width)};
  height: ${dim(READING_EXPERIENCE_CHOICE_THUMBNAIL_SIZE.height)};
`;
