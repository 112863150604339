import styled from '@emotion/styled';
import { Caption2 } from '../../typography';

export const PanelCaption = styled(Caption2)`
  background: none;
  box-shadow: none;
  color: ${({ theme }) => theme.colors.black};
  margin: ${({ theme }) => theme.space[3]} 0 0 0;
  padding: 0;
  font-weight: ${({ theme }) => theme.fontWeights['semiBold']};
`;
