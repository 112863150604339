import styled from '@emotion/styled';
import Box from '../../Box';
import React, { ReactElement } from 'react';
import { Header6 } from '../../typography';
import { RecentlyReadStory } from './story';
import { dim } from '../../../theme';
import { RoundedBox } from '../../RoundedBox';
import { down } from 'styled-breakpoints';

const RecentlyReadHeader = styled(Header6)`
  font-weight: 700;
  ${down('mobile')} {
    margin: 0 0 ${({ theme }) => theme.space[3]}
      ${({ theme }) => theme.space[3]};
  }
`;

const RecentlyReadContent = styled(Box)`
  display: flex;
  width: 100%;
  ${down('mobile')} {
    padding: 0 0 0 ${({ theme }) => theme.space[3]};
    overflow-x: auto;
  }
`;

const RecentlyReadStories = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  //width: 100%;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

const _RecentlyRead: React.FC<{
  className?: string;
  children?:
    | ReactElement<typeof RecentlyReadStory>
    | ReactElement<typeof RecentlyReadStory>[];
}> = ({ className, children }) => {
  return (
    <RoundedBox className={className}>
      <RecentlyReadHeader mb="3">Recently Read</RecentlyReadHeader>
      <RecentlyReadContent>
        <RecentlyReadStories>{children}</RecentlyReadStories>
      </RecentlyReadContent>
    </RoundedBox>
  );
};

export { RecentlyReadStory } from './story';

export const RecentlyRead = styled(_RecentlyRead)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin: ${({ theme }) => theme.space[4]} 0;
  padding: ${({ theme }) => theme.space[4]} ${dim(60)};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 ${({ theme }) => theme.space[1]}
    ${({ theme }) => theme.space[1]} rgba(0, 0, 0, 0.25);
  border-radius: ${({ theme }) => theme.space[2]};

  ${down('mobile')} {
    width: 100%;
    padding-top: 0;
    padding-bottom: ${({ theme }) => theme.space[3]};
    margin: ${({ theme }) => theme.space[3]} auto;
    border-bottom: none;
  }
`;
