import styled from '@emotion/styled';
import React from 'react';
import { down, up } from 'styled-breakpoints';
import { Icon } from '@iconify/react';
import menuOutlined from '@iconify/icons-ant-design/menu-outlined';
import Box from '../../Box';
import { ProfileButton } from './ProfileButton';
import { dim } from '../../../theme';
import IconButton from '../../inputs/IconButton';
import { isReactElementWithKey } from '../../../types/utils';
import { SignupButton } from './ProfileButton';

const StyledPageHeader = styled(Box)`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  top: 0;
  left: 0;
  right: 0;
  padding: ${({ theme }) => theme.space[1]} ${dim(23)}
    ${({ theme }) => theme.space[1]} ${dim(32)};
  height: ${dim(104)};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 10;
  ${down('mobile')} {
    background-color: transparent;
    height: ${dim(66)};
    padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]}
      ${({ theme }) => theme.space[2]};
  }
`;

const PageHeaderMenu = styled.ul`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: ${dim(917)};
  margin-right: ${dim(40)};
  justify-content: space-between;
  align-items: center;
  ${down('mobile')} {
    display: none;
  }
`;

const PageHeaderMenuItem = styled.li`
  font-family: ${({ theme }) => theme.fonts['body']};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights['regular']};
  font-size: ${dim(20)};
  text-transform: uppercase;
  line-height: 1;
  & a,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};
  }
`;

const PageHeaderMenuIcon = styled(IconButton)`
  font-family: ${({ theme }) => theme.fonts['body']};
  font-style: normal;
  font-weight: 400;
  font-size: ${dim(20)};
  line-height: 1;
  display: none;
  ${down('mobile')} {
    display: flex;

    padding: ${dim(12)};

    width: ${dim(49)};
    height: ${dim(46)};

    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0 ${dim(4)} ${dim(4)} rgba(0, 0, 0, 0.25);
    border-radius: ${dim(8)};
  }
`;

const PageHeaderLogo = styled(Box)`
  img {
    cursor: pointer;
    display: block;
  }

  ${down('mobile')} {
    display: none;
  }
`;

const PageHeaderMobileLogo = styled(Box)`
  img {
    cursor: pointer;
    display: block;
  }

  ${up('mobile')} {
    display: none;
  }
`;

const PageHeaderDesktopProfile = styled.div`
  display: block;
  ${down('mobile')} {
    display: none;
  }
`;

export interface PageHeaderProps {
  hasUser: boolean;
  desktopLogo: JSX.Element;
  mobileLogo: JSX.Element;
  children: React.ReactNode[];
  onLogoClick(): void;
  onMenuClick(): void;
  onProfileClick(): void;
  onSignupClick(): void;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  children,
  hasUser = false,
  mobileLogo,
  desktopLogo,
  onLogoClick,
  onMenuClick,
  onProfileClick,
  onSignupClick,
}) => {
  return (
    <StyledPageHeader>
      <PageHeaderMobileLogo aria-label="MOBILE LOGO" onClick={onLogoClick}>
        {mobileLogo}
      </PageHeaderMobileLogo>
      <PageHeaderMenu>
        <li>
          <PageHeaderLogo aria-label="LOGO" onClick={onLogoClick}>
            {desktopLogo}
          </PageHeaderLogo>
        </li>
        {React.Children.map(children, (child) => {
          if (!isReactElementWithKey(child)) return null;
          return (
            <PageHeaderMenuItem key={'parent_' + child.key}>
              {child}
            </PageHeaderMenuItem>
          );
        })}
      </PageHeaderMenu>
      <PageHeaderDesktopProfile>
        {hasUser && (
          <ProfileButton onClick={onProfileClick} aria-label="open profile" />
        )}
        {!hasUser && (
          <SignupButton onClick={onSignupClick} aria-label="sign up" />
        )}
      </PageHeaderDesktopProfile>
      <PageHeaderMenuIcon onClick={onMenuClick} aria-label="open menu">
        <Icon icon={menuOutlined} color="black" height="24" />
      </PageHeaderMenuIcon>
    </StyledPageHeader>
  );
};

export default PageHeader;
