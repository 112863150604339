import styled from '@emotion/styled';
import { BaseTheme, dim } from '../../../theme';
import { css } from '@emotion/react';
import { down } from 'styled-breakpoints';

export const GenericOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) =>
    (theme as BaseTheme).colors.primary.overlay};
  padding: ${({ theme }) => theme.space[5]} ${({ theme }) => theme.space[3]} 0;
  z-index: 20;

  ${down('mobile')} {
    padding: ${({ theme }) => theme.space[5]} ${({ theme }) => theme.space[3]} 0;
  }
`;
export const roundedContainer = (theme: BaseTheme) => {
  return css`
    padding: ${dim(24)} ${dim(24)} ${theme.space[4]};
    background: ${theme.colors.white};
    box-shadow: 0 ${dim(4)} ${dim(4)} rgba(0, 0, 0, 0.25);
    border-radius: ${dim(8)};
    width: ${dim(354)};
  `;
};

export const roundedContainerMobile = (theme: BaseTheme) => {
  return css`
    width: 100%;
    margin: 0;
    padding: ${dim(24)} ${dim(24)} ${theme.space[4]};
  `;
};
