import React, { ReactNode } from 'react';
import { PTBlock } from '../../../types';
import { RoundedBox } from '../../RoundedBox';
import { Header4, Text } from '../../typography';
import { RichText } from '../../typography/RichText';
import styled from '@emotion/styled';
import { dim } from '../../../theme';
import { down } from 'styled-breakpoints';
import Box from '../../Box';
import { Maybe } from '../../../types/utils';

const StoryInfoContentMediumBody = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: ${({ theme }) => theme.space[2]};
`;

const _StoryInfoContentMedium: React.FC<{
  name: Maybe<string>;
  date?: Maybe<string>;
  description: string | Maybe<PTBlock>[] | null | undefined;
  image?: ReactNode;
  className?: string;
}> = ({ name, date, description, className, image }) => (
  <RoundedBox className={className}>
    <Header4 fontWeight="500" fontSize={[dim(36), dim(36)]}>
      {name}
    </Header4>
    <StoryInfoContentMediumBody>
      {image}
      <Box ml="2" flex={1}>
        {date && <Text mb="2">Age: {date}</Text>}
        <Text>
          <RichText text={description} />
        </Text>
      </Box>
    </StoryInfoContentMediumBody>
  </RoundedBox>
);

export const StoryInfoContentMedium = styled(_StoryInfoContentMedium)`
  margin-top: ${dim(43)};
  padding: ${dim(37)} ${dim(24)} ${({ theme }) => theme.space[4]};
  ${down('mobile')} {
    padding-bottom: ${({ theme }) => theme.space[3]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
    border-radius: 0;
    margin-bottom: ${({ theme }) => theme.space[3]};
  }
  p > p {
    margin-bottom: ${dim(12)};
  }
`;
