import React from 'react';
import styled from '@emotion/styled';
import { down } from 'styled-breakpoints';
import { CountryData, GenreData, StoryIntroData } from '../../../types';
import Box from '../../Box';
import { Caption, Header5, Header7, Text } from '../../typography';
import { dim } from '../../../theme';
import {
  VerticalComicCardArt,
  VerticalComicCardLayerLower,
  VerticalComicCardLayerMid,
  VerticalComicCardLayerUpper,
} from '../VerticalComicCardArt';
import { transition } from '../utis';
import { RichText } from '../../typography/RichText';

export type VerticalComicCardProps = {
  lowerLayer?: React.ReactNode;
  midLayer?: React.ReactNode;
  upperLayer?: React.ReactNode;
  onClick: () => void;
} & Omit<StoryIntroData, 'images' | 'characters'>;

const VerticalComicCardHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ theme }) => theme.space[3]};
`;

const VerticalComicCardContent = styled(Box, {
  target: 'styledVerticalComicCardContent',
})`
  width: 100%;
  height: ${dim(80)};
  margin: ${({ theme }) => theme.space[3]} 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  white-space: normal;
  ${down('mobile')} {
    margin: ${({ theme }) => theme.space[2]} 0 0;
  }
`;

const VerticalComicCardCaption = styled(Caption, {
  target: 'styledVerticalComicCardCaption',
})`
  transition: ${transition('color')};
`;

const VerticalComicCardTitle = styled(Header5, {
  target: 'styledVerticalComicCardTitle',
})`
  transition: ${transition('color')};
  margin: ${({ theme }) => theme.space[2]} 0 0;
  ${down('mobile')} {
    margin: ${({ theme }) => theme.space[1]} 0 0;
  }
`;

const VerticalComicCardBody = styled(Box)`
  display: flex;
  width: ${dim(400)};
  margin: 0 ${({ theme }) => theme.space[3]};
  flex-direction: column;
  ${down('mobile')} {
    width: 100%;
    margin: 0 ${({ theme }) => theme.space[2]};
  }
`;

const VerticalComicCardAuthors = styled(Header7, {
  target: 'styledVerticalComicCardAuthors',
})`
  margin: ${({ theme }) => theme.space[2]} 0 0;
  color: ${({ theme }) => theme.colors.grays['80']};
  transition: ${transition('color')};
  ${down('mobile')} {
    margin: ${({ theme }) => theme.space[1]} 0 0;
  }
`;

const StyledVerticalComicCard = styled(Box)`
  display: flex;
  flex-direction: row;
  width: ${dim(604)};
  min-height: ${dim(269)};
  ${down('mobile')} {
    width: ${dim(313)};
  }
  &:hover ${VerticalComicCardLayerLower} {
    transform: scale(1.04);
  }
  &:active ${VerticalComicCardLayerLower}:after {
    content: '';
    background-color: ${({ theme }) => theme.colors.blues['30']};
    opacity: 0.25;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: ${dim(10)};
  }
  &:hover ${VerticalComicCardLayerMid} {
    transform: scale(1.06);
  }
  &:hover ${VerticalComicCardLayerUpper} {
    transform: scale(1.08);
  }
  &:active ${VerticalComicCardCaption} {
    color: ${({ theme }) => theme.colors.blues['30']};
  }
  &:active ${VerticalComicCardAuthors} {
    color: ${({ theme }) => theme.colors.blues['60']};
  }
  &:active ${VerticalComicCardContent} {
    color: ${({ theme }) => theme.colors.blues['60']};
  }
  ${down('mobile')} {
    width: ${dim(313)};
    min-height: ${dim(147)};
  }
}
`;

export const VerticalComicCard: React.FC<VerticalComicCardProps> = ({
  lowerLayer,
  midLayer,
  upperLayer,
  title,
  country,
  genres,
  creators,
  prologue,
  onClick,
}) => {
  const tags: (CountryData | GenreData)[] = [
    country || { name: '' },
    ...genres,
  ];

  return (
    <StyledVerticalComicCard onClick={onClick} aria-label={title} role="link">
      <VerticalComicCardArt
        lowerLayer={lowerLayer}
        midLayer={midLayer}
        upperLayer={upperLayer}
      />
      <VerticalComicCardBody>
        <VerticalComicCardHeader>
          <VerticalComicCardCaption>
            {tags.map((tag) => tag?.name || '').join(', ')}
          </VerticalComicCardCaption>
          <VerticalComicCardTitle>{title}</VerticalComicCardTitle>
          {creators.length > 0 && (
            <VerticalComicCardAuthors>
              {creators.map(({ creator }) => creator.name).join(', ')}
            </VerticalComicCardAuthors>
          )}
        </VerticalComicCardHeader>
        <VerticalComicCardContent>
          <Text>
            <RichText text={prologue} />
          </Text>
        </VerticalComicCardContent>
      </VerticalComicCardBody>
    </StyledVerticalComicCard>
  );
};
