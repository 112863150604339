import React from 'react';
import styled from '@emotion/styled';
import Box from '../../Box';
import Button2 from '../../inputs/Button2';
import { Text } from '../../typography';
import { MultipleChoicePanelProps } from '../../../types';
import { dim } from '../../../theme';
import { PanelCTA } from '../PanelCTA';
import { down } from 'styled-breakpoints';
import { PanelCaption } from '../PanelCaption';

const StyledMultipleChoicePanel = styled(Box)`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: ${dim(56)} 0 ${({ theme }) => theme.space[4]};
  ${down('mobile')} {
    padding: ${dim(40)} 0 ${({ theme }) => theme.space[3]};
  }
`;

const AnswerButton = styled(Button2)`
  width: 100%;
`;

export const MultipleChoicePanel: React.FC<MultipleChoicePanelProps> = ({
  width,
  height,
  caption,
  question,
  answers,
  onAnswerClick,
  image,
}) => {
  const handleAnswerClick = (value: string) => () => {
    onAnswerClick(value);
  };

  return (
    <StyledMultipleChoicePanel width={dim(width)} minHeight={dim(height)}>
      {caption.replaceAll(' ', '') ? <PanelCTA>{caption}</PanelCTA> : null}
      {caption.replaceAll(' ', '') && image ? image : null}

      {caption.replaceAll(' ', '') ? (
        <PanelCaption>Select a response</PanelCaption>
      ) : null}
      <Text mt="1" mb="0">
        {question}
      </Text>
      <Box mt="3">
        {answers.map((answer) => {
          return (
            <Box mb="2" key={answer.id}>
              <AnswerButton
                onClick={handleAnswerClick(answer.value)}
                palette={answer.selected ? 'green' : 'blue'}
                variant="secondary"
              >
                {answer.label}
              </AnswerButton>
            </Box>
          );
        })}
      </Box>
    </StyledMultipleChoicePanel>
  );
};

export default MultipleChoicePanel;
