import styled from '@emotion/styled';
import { variant } from 'styled-system';
import { css } from '@emotion/react';
import { ReactComponent as LogoComponent } from './logo.svg';

const StyledLogoComponent = styled.div<{
  variant?: 'regular' | 'dark' | 'light';
}>`
  display: flex;
  padding: 0;
  ${({ theme }) =>
    variant({
      variants: {
        regular: css`
          svg {
            path {
              fill: ${theme.colors.blues['60']} !important;
            }
          }
        `,
        dark: css`
          svg {
            path {
              fill: ${theme.colors.black} !important;
            }
          }
        `,
        light: css`
          background-color: ${theme.colors.primary.background};
          svg {
            path {
              fill: ${theme.colors.white} !important;
            }
          }
        `,
      },
    })}
`;

export const Logo: React.FC<{
  width?: number | string;
  height?: number | string;
  variant?: 'regular' | 'dark' | 'light';
}> = ({ width, height, variant }) => {
  return (
    <StyledLogoComponent variant={variant || 'regular'}>
      <LogoComponent
        width={width}
        height={height}
        viewBox="0 0 58 44"
        aria-label="ASTC Logo"
      />
    </StyledLogoComponent>
  );
};

export default Logo;
