import styled from '@emotion/styled';
import Box from '../../Box';
import { dim } from '../../../theme';
import { down } from 'styled-breakpoints';
import React from 'react';
import { transition } from '../utis';

export const VerticalComicCardLayerLower = styled(Box, {
  target: 'styledVerticalComicCardArtLower',
})`
  display: flex;
  position: absolute;
  transition: ${transition('transform', 0.3)};
  min-width: ${dim(120)};

  &:after {
    content: '';
    background-color: ${({ theme }) => theme.colors.blues['30']};
    opacity: 0;
    //width: 100%;
    height: 100%;
    position: absolute;
    border-radius: ${dim(10)};
    transition: ${transition('opacity')};
  }
  ${down('mobile')} {
    min-width: ${dim(108)};
  }
`;

export const VerticalComicCardLayerMid = styled(Box, {
  target: 'styledVerticalComicCardArtMid',
})`
  display: flex;
  position: absolute;
  transition: ${transition('transform', 0.3)};
`;

export const VerticalComicCardLayerUpper = styled(VerticalComicCardLayerMid, {
  target: 'styledVerticalComicCardArtUpper',
})``;

export const StyledVerticalComicCardArt = styled(Box, {
  target: 'styledVerticalComicCardArt',
})<{ forceSmall: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: ${({ forceSmall }) => dim(forceSmall ? 120 : 200)};
  height: ${({ forceSmall }) => dim(forceSmall ? 221 : 273)};
  ${down('mobile')} {
    min-width: ${dim(120)};
    height: ${dim(221)};
  }
`;

export const VerticalComicCardArt: React.FC<{
  lowerLayer: React.ReactNode;
  midLayer: React.ReactNode;
  upperLayer: React.ReactNode;
  forceSmall?: boolean;
}> = ({ lowerLayer, midLayer, upperLayer, forceSmall = false }) => (
  <StyledVerticalComicCardArt forceSmall={forceSmall}>
    {lowerLayer && (
      <VerticalComicCardLayerLower>{lowerLayer}</VerticalComicCardLayerLower>
    )}
    {midLayer && (
      <VerticalComicCardLayerMid>{midLayer}</VerticalComicCardLayerMid>
    )}
    {upperLayer && (
      <VerticalComicCardLayerUpper>{upperLayer}</VerticalComicCardLayerUpper>
    )}
  </StyledVerticalComicCardArt>
);
