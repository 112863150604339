import React from 'react';
import styled from '@emotion/styled';
import { BaseTheme, dim } from '../../../theme';
import IconButton from '../../inputs/IconButton';
import { Caption2, Header6 } from '../../typography';
import { ReactComponent as LockedBadgeArt } from '../locked_badge.svg';
import Box from '../../Box';
import {
  GenericOverlay,
  roundedContainer,
  roundedContainerMobile,
} from '../../overlays/GenericOverlay';
import { down } from 'styled-breakpoints';
import { Icon } from '@iconify/react';
import closeBig from '@iconify/icons-ci/close-big';
import { useTheme } from '@emotion/react';
import bxsShareAlt from '@iconify/icons-bx/bxs-share-alt';
import downloadIcon from '@iconify/icons-bx/download';
import playFill from '@iconify/icons-bi/play-fill';

export const BADGE_FULL_IMAGE_SIZE = { width: 131, height: 174 };

const StyledBadgeModal = styled(GenericOverlay)`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${down('mobile')} {
    padding: ${({ theme }) => theme.space[5]} ${({ theme }) => theme.space[2]} 0;
  }
`;

const BadgeModalContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${({ theme }) => roundedContainer(theme)};
  margin: 0 auto;
  ${down('mobile')} {
    ${({ theme }) => roundedContainerMobile(theme)};
    padding: ${dim(12)} ${dim(12)} ${({ theme }) => theme.space[4]};
  }
`;

const BadgeModalHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space[3]};
  width: 100%;
`;

const BadgeModalMedia = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const _BadgeFullImage: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ className, children }) => {
  return <Box className={className}>{children}</Box>;
};

const BadgeFullImage = styled(_BadgeFullImage)`
  display: flex;
  flex-grow: 0;
`;

const BadgeModalActions = styled(Box)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  margin-top: ${({ theme }) => theme.space[2]};
`;

const BadgeModalStory = styled(Box)`
  display: flex;
  flex-direction: row;
  list-style: none;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: ${({ theme }) => theme.space[4]};
`;

const BadgeModalRequiredStory = styled(Header6)`
  line-height: 1.1;
  letter-spacing: -0.015em;
  font-size: ${dim(16)};

  ${down('mobile')} {
    font-size: ${dim(14)};
  }
`;

const BadgeModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: ${dim(20)};
`;

export interface BadgeModalProps {
  unlocked: boolean;
  badgeThumbnail: JSX.Element;
  trigger: 'reading' | 'interactive';
  storyTitle: string;
  storyThumbnail: JSX.Element;
  onShare(): void;
  onDownload(): void;
  onStoryClick(): void;
  onRequestClose(): void;
}

export const BadgeModal: React.FC<BadgeModalProps> = ({
  unlocked,
  badgeThumbnail,
  trigger,
  storyTitle,
  storyThumbnail,
  onShare,
  onDownload,
  onStoryClick,
  onRequestClose,
}) => {
  const {
    colors: { blues },
  } = useTheme() as BaseTheme;

  const handleBackdropClick = (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (ev.target === ev.currentTarget) {
      onRequestClose();
    }
  };

  const handleStoryClick = (event: React.MouseEvent<HTMLElement>) => {
    onStoryClick();
    event.stopPropagation();
  };

  const handleShareClick = (event: React.MouseEvent<HTMLElement>) => {
    onShare();
    event.stopPropagation();
  };

  const handleDownloadClick = (event: React.MouseEvent<HTMLElement>) => {
    onDownload();
    event.stopPropagation();
  };

  const verb = trigger === 'reading' ? 'reading' : 'interacting with';

  return (
    <StyledBadgeModal onClick={handleBackdropClick}>
      <BadgeModalContent>
        <BadgeModalHeader>
          <IconButton onClick={onRequestClose}>
            <Icon icon={closeBig} height={35} color={blues['60']} />
          </IconButton>
        </BadgeModalHeader>
        <BadgeModalMedia
          aria-label={`Badge ${unlocked ? 'unlocked' : 'locked'}`}
        >
          {unlocked ? (
            <BadgeFullImage>{badgeThumbnail}</BadgeFullImage>
          ) : (
            <LockedBadgeArt width="76" height="101" viewBox="0 0 76 101" />
          )}
        </BadgeModalMedia>
        <BadgeModalActions>
          {unlocked ? (
            <>
              <IconButton onClick={handleDownloadClick} aria-label="Download">
                <Icon icon={downloadIcon} height={44} color={blues['60']} />
              </IconButton>
              <IconButton onClick={handleShareClick} aria-label="Share">
                <Icon icon={bxsShareAlt} height={44} color={blues['60']} />
              </IconButton>
            </>
          ) : null}
        </BadgeModalActions>
        <BadgeModalStory>
          <Box width={dim(60)} height={dim(60)}>
            {storyThumbnail}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            ml="2"
            flex="1"
          >
            <BadgeModalRequiredStory>Required Story</BadgeModalRequiredStory>
            <Header6>{storyTitle}</Header6>
          </Box>
          <IconButton onClick={handleStoryClick}>
            <Icon icon={playFill} width={32} color={blues['60']} />
          </IconButton>
        </BadgeModalStory>
        <BadgeModalFooter>
          <Caption2 fontWeight={600} variant="plain">
            Awarded for {verb} {storyTitle}.
          </Caption2>
        </BadgeModalFooter>
      </BadgeModalContent>
    </StyledBadgeModal>
  );
};
