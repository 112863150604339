import React from 'react';
import styled from '@emotion/styled';
import { Header4, Text } from '../typography';
import { dim } from '../../theme';
import { CreatorData } from '../../types';
import { RichText } from '../typography/RichText';
import { down } from 'styled-breakpoints';

export const CREATOR_BIO_PROFILE_IMAGE_SIZE = { width: 112, height: 156 };

const StyledCreatorBio = styled.div<{ solo: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ solo }) => (solo ? '100%' : dim(338))};
  margin-bottom: 3.2rem;
  ${down('mobile')} {
    width: 100%;
  }
`;

const CreatorBioHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

const CreatorBioBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.space[3]};
  p > p {
    margin-bottom: ${({ theme }) => theme.space[3]};

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const CreatorBioDetail = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextBold = styled(Text)`
  font-weight: 500;
`;

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const CreatorBio: React.FC<
  CreatorData & {
    role: string;
    solo: boolean;
  }
> = ({ bio, locations, name, role, solo }) => {
  return (
    <StyledCreatorBio solo={solo}>
      <CreatorBioHeader>
        <CreatorBioDetail>
          <Text>About the {capitalize(role)}</Text>
          <Header4>{name}</Header4>
          {locations && locations.length > 0 && (
            <TextBold>{locations?.filter((loc) => !!loc).join(' / ')}</TextBold>
          )}
        </CreatorBioDetail>
      </CreatorBioHeader>
      {bio && bio.length > 0 && (
        <CreatorBioBody>
          <RichText text={bio} />
        </CreatorBioBody>
      )}
    </StyledCreatorBio>
  );
};

export default CreatorBio;
