import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import Box from '../../Box';
import AnimationArea from './AnimationArea';
import { AnimationPanelProps } from '../../../types';
import { dim } from '../../../theme';

const StyledAnimationPanel = styled(Box)`
  // margin-bottom: ${({ theme }) => theme.space[2]};
  // padding: ${({ theme }) => theme.space[2]};
  display: flex;
  flex-direction: column;
`;

export const AnimationPanel: React.FC<AnimationPanelProps> = ({
  width,
  height,
  alt,
  src,
  autoplay,
  onReady,
}) => {
  const handleReady = useCallback(() => {
    onReady();
  }, [onReady]);

  return (
    <StyledAnimationPanel
      width={width ? dim(width) : 'auto'}
      minHeight={height ? dim(height) : 'auto'}
    >
      <AnimationArea
        src={src}
        alt={alt}
        width={width}
        height={height}
        autoplay={autoplay}
        onReady={handleReady}
      />
    </StyledAnimationPanel>
  );
};

export default AnimationPanel;
