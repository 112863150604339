import React from 'react';
import styled from '@emotion/styled';
import { dim } from '../../../theme';
import { Header2, Text } from '../../typography';
import { backgroundImage, BackgroundImageProps } from 'styled-system';
import { StoryIntroData } from '../../../types';

const StyledFeaturedArt = styled.div<
  BackgroundImageProps & { align: 'left' | 'right' }
>`
  display: flex;
  flex-grow: 0;
  position: relative;
  width: ${dim(343)};
  background: no-repeat ${({ align }) => (align === 'left' ? 'right' : 'left')}
    bottom; // align image opposite text
  flex-direction: ${({ align }) => (align === 'left' ? 'row' : 'row-reverse')};
  ${backgroundImage}
`;

const FeaturedArtDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space[3]};
  width: ${dim(200)};
  height: 100%;
  box-sizing: border-box;
`;

export type FeaturedArtProps = Omit<
  StoryIntroData,
  'prologue' | 'countries' | 'country' | 'creators' | 'genres' | 'characters'
> & {
  align: 'left' | 'right';
};

export const FeaturedArt: React.FC<FeaturedArtProps> = ({
  title,
  images,
  align,
}) => {
  return (
    <StyledFeaturedArt
      align={align}
      backgroundImage={images?.promo?.src ? `url(${images.promo.src})` : ''}
    >
      <FeaturedArtDetails>
        <Header2>{title}</Header2>
        <Text>Read Now</Text>
      </FeaturedArtDetails>
    </StyledFeaturedArt>
  );
};
