import styled from '@emotion/styled';
import { dim } from '../../../theme';
import { RoundedBox } from '../../RoundedBox';

export const ReadingListsCollection = styled(RoundedBox)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${dim(40)};
  width: 100%;
  max-width: ${dim(632)};
`;
