import React, { useCallback, useEffect } from 'react';
import {
  baseTheme,
  dim,
  ThemeElementState,
  ThemeInputSize,
} from '../../../theme';
import Box from '../../Box';
import { ThemePalette, ThemeVariant } from '../../../theme/palettes/types';

export const GenericButtonText: React.FC<{
  StyledText: JSX.Element;
  isSkewed: boolean;
}> = ({ StyledText, isSkewed, children }) => {
  return React.cloneElement(
    StyledText,
    {
      isSkewed,
    },
    children,
  );
};

export const GenericButton: React.FC<{
  palette: ThemePalette;
  variant?: ThemeVariant;
  disabled?: boolean;
  state?: ThemeElementState;
  size?: ThemeInputSize;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  onClick?: () => void;
  StyledButton: JSX.Element;
  StyledText: JSX.Element;
  isSkewed?: boolean;
}> = (props) => {
  const {
    palette,
    variant,
    disabled,
    state,
    size,
    iconLeft,
    iconRight,
    StyledButton,
    StyledText,
    isSkewed = false,
    children,
    ...styleProps
  } = props;
  const [inside, setInside] = React.useState<boolean>(state === 'hover');
  const [down, setDown] = React.useState<boolean>(state === 'down');

  useEffect(() => {
    const handleDocMouseUp = () => {
      if (state) return;
      setDown(false);
    };
    document.addEventListener('mouseup', handleDocMouseUp);
    return () => {
      document.removeEventListener('mouseup', handleDocMouseUp);
    };
  }, [state]);

  const handleMouseOver = useCallback(() => {
    if (state) return;
    setInside(!disabled && true);
  }, [state, disabled]);

  const handleMouseDown = useCallback(() => {
    if (state) return;
    setDown(true);
  }, [state]);

  const handleMouseUp = useCallback(() => {
    if (state) return;
    setDown(false);
  }, [state]);

  const handleMouseLeave = useCallback(() => {
    if (state) return;
    setInside(false);
  }, [state]);

  const iconState = disabled
    ? 'disabled'
    : down
    ? 'down'
    : inside
    ? 'hover'
    : 'default';

  const paddingOverride =
    iconLeft && !children && !iconRight
      ? { px: `${props.size === 'small' ? dim(6) : dim(12)} !important` }
      : {};

  return React.cloneElement(
    StyledButton,
    {
      ...styleProps,
      ...paddingOverride,
      onMouseOver: handleMouseOver,
      onMouseDown: handleMouseDown,
      onMouseUp: handleMouseUp,
      onMouseLeave: handleMouseLeave,
    },
    <GenericButtonText StyledText={StyledText} isSkewed={isSkewed}>
      {iconLeft && (
        <Box
          mr={!children && !iconRight ? 0 : 1}
          as="span"
          display="inline-flex"
        >
          {React.cloneElement(iconLeft, {
            color:
              baseTheme.colors.palettes[palette].button[variant || 'primary'][
                iconState
              ].text,
          })}
        </Box>
      )}
      {children}
      {iconRight && (
        <Box ml="1" as="span" display="inline-flex">
          {React.cloneElement(iconRight, {
            color:
              baseTheme.colors.palettes[palette].button[variant || 'primary'][
                iconState
              ].text,
          })}
        </Box>
      )}
    </GenericButtonText>,
  );
};

export default GenericButton;
