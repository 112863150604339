import React from 'react';
import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import bookmarkIcon from '@iconify/icons-bi/bookmark';
import bookmarkFillIcon from '@iconify/icons-bi/bookmark-fill';
import bxsShareAlt from '@iconify/icons-bx/bxs-share-alt';
import infoFill from '@iconify/icons-eva/info-fill';
import { Caption, Header5, Header7 } from '../../typography';
import { StoryIntroData } from '../../../types';
import { dim } from '../../../theme';
import { RoundedBox } from '../../RoundedBox';
import { useTheme } from '@emotion/react';
import { down } from 'styled-breakpoints';
import IconButton from '../../inputs/IconButton';

export const StyledStoryIntro = styled(RoundedBox)`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  width: ${dim(1016)};
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: 0;
  padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[3]};
  border-radius: ${({ theme }) => theme.radii['md']};
  ${down('mobile')} {
    width: 100%;
    border: none;
  }
`;

export const StoryIntroImage = styled.div`
  display: flex;
  flex-grow: 0;
`;

export const StoryIntroBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: ${({ theme }) => theme.space[3]};
`;

export const StoryIntroActions = styled.div`
  display: flex;
  width: ${dim(98)};
  flex-direction: row;
  flex-grow: 0;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  ${down('mobile')} {
    display: none;
  }
`;

export const StoryIntroActionsMobile = styled.div`
  display: none;
  width: ${dim(98)};
  flex-direction: row;
  flex-grow: 0;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  ${down('mobile')} {
    display: flex;
    align-items: flex-start;
    align-self: flex-start;
    justify-content: flex-end;
  }
`;

export const COMIC_INTRO_IMAGE_SIZE = { width: 88, height: 135 };

export const StoryIntro: React.FC<
  Omit<StoryIntroData, 'images' | 'characters' | 'medium'> & {
    cardArt: React.ReactNode;
    hasUser?: boolean;
    hasBookmark: boolean;
    onBookmarkClick: () => void;
    onShareClick: () => void;
    onShowInfoClick: () => void;
  }
> = ({
  title,
  creators,
  country,
  genres,
  cardArt,
  hasUser,
  hasBookmark,
  onBookmarkClick,
  onShareClick,
  onShowInfoClick,
}) => {
  const {
    colors: { blues },
  } = useTheme();

  return (
    <StyledStoryIntro>
      {cardArt}
      <StoryIntroBody>
        <Caption>
          {country?.name}
          {genres.map((genre) => genre.name).join(', ')}
        </Caption>
        <Header5 mb="0">{title}</Header5>
        <Header7 fontWeight="bold">
          By: {creators.map((creator) => creator.creator.name).join(', ')}
        </Header7>
      </StoryIntroBody>
      <StoryIntroActions>
        {hasUser && (
          <IconButton
            onClick={() => {
              onBookmarkClick();
            }}
            aria-label="Bookmark This"
          >
            {hasBookmark ? (
              <Icon icon={bookmarkFillIcon} color={blues['60']} height="32" />
            ) : (
              <Icon icon={bookmarkIcon} color={blues['60']} height="32" />
            )}
          </IconButton>
        )}
        <IconButton
          onClick={() => {
            onShareClick();
          }}
          aria-label="Share This"
        >
          <Icon icon={bxsShareAlt} color={blues['60']} height="32" />
        </IconButton>
      </StoryIntroActions>
      <StoryIntroActionsMobile>
        {!hasUser && (
          <IconButton
            onClick={() => {
              onBookmarkClick();
            }}
            aria-label="Bookmark This"
          >
            {hasBookmark ? (
              <Icon icon={bookmarkFillIcon} color={blues['60']} height="24" />
            ) : (
              <Icon icon={bookmarkIcon} color={blues['60']} height="24" />
            )}
          </IconButton>
        )}
        <IconButton
          style={{ marginLeft: dim(8) }}
          onClick={() => {
            onShowInfoClick();
          }}
          aria-label="Show More Info"
        >
          <Icon icon={infoFill} color={blues['60']} height="24" />
        </IconButton>
      </StoryIntroActionsMobile>
    </StyledStoryIntro>
  );
};

export default StoryIntro;
