import React, { useCallback, useEffect, useState } from 'react';
import { Text } from '../../typography';
import Textarea from '../../inputs/Textarea';
import Button2 from '../../inputs/Button2';
import { ComparisonPanelProps } from '../../../types';
import styled from '@emotion/styled';
import Box from '../../Box';
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from 'react-compare-slider';
import { dim } from '../../../theme';
import { down } from 'styled-breakpoints';
import { PanelCTA } from '../PanelCTA';

const StyledComparisonPanel = styled(Box)`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: ${dim(56)} 0 ${({ theme }) => theme.space[4]};
  ${down('mobile')} {
    padding: ${dim(40)} 0 ${({ theme }) => theme.space[3]};
  }
`;

export const ComparisonPanel: React.FC<ComparisonPanelProps> = ({
  width,
  height,
  src,
  src2,
  alt,
  alt2,
  cta,
  question,
  answer: _answer,
  value,
  onSubmit,
  onComparisonChange,
}) => {
  const [comparisonValue, setComparisonValue] = useState(value || 0);

  const [answer, setAnswer] = useState<string>('');

  const handleComparisonChange = useCallback(
    (value: number) => {
      setComparisonValue(value);
      onComparisonChange(Number(value));
    },
    [onComparisonChange],
  );

  const handleAnswerChange = useCallback<
    (ev: React.FormEvent<HTMLTextAreaElement>) => void
  >(
    (ev) => {
      setAnswer(ev.currentTarget.value);
    },
    [setAnswer],
  );
  const handleSubmit = useCallback<
    (ev: React.FormEvent<HTMLFormElement>) => void
  >(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      if (answer) onSubmit(answer);
    },
    [onSubmit, answer],
  );
  useEffect(() => {
    if (_answer) setAnswer(_answer);
  }, [_answer]);

  return (
    <StyledComparisonPanel width={dim(width)} minHeight={dim(height)}>
      <form onSubmit={handleSubmit}>
        <PanelCTA>{cta}</PanelCTA>
        <ReactCompareSlider
          itemOne={<ReactCompareSliderImage src={src} alt={alt} />}
          itemTwo={<ReactCompareSliderImage src={src2} alt={alt2} />}
          position={comparisonValue || 0}
          onPositionChange={handleComparisonChange}
        />
        <Text mt={3}>{question}</Text>
        <Textarea
          aria-label="answer text"
          onChange={handleAnswerChange}
          value={answer}
        />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          mt="3"
        >
          <Button2
            type="submit"
            disabled={!answer}
            palette={'blue'}
            variant={'secondary'}
          >
            Done
          </Button2>
        </Box>
      </form>
    </StyledComparisonPanel>
  );
};

export default ComparisonPanel;
