import styled from '@emotion/styled';
import { Text2 } from '../../typography';
import { dim } from '../../../theme';
import { down } from 'styled-breakpoints';

export const PanelCTA = styled(Text2)<{ align?: 'left' | 'right' }>`
  position: absolute;
  top: ${({ theme }) => theme.space[4]};
  ${({ align }) => (align === 'right' ? 'right: 0;' : 'left: 0;')};
  display: inline-flex;
  color: ${({ theme }) => theme.colors.blues['60']};
  background-color: ${({ theme }) => theme.colors.white};
  border: ${dim(1)} solid ${({ theme }) => theme.colors.blues['60']};
  margin: 0 0 0 0;
  padding: ${dim(13)} ${({ theme }) => theme.space[3]};
  width: auto;
  font-weight: ${({ theme }) => theme.fontWeights['regular']};
  z-index: 5;

  ${down('mobile')} {
    top: ${({ theme }) => theme.space[3]};
  }
`;
