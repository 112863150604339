import React from 'react';
import { PTBlocks, StoryCreatorData } from '../../../types';
import { RoundedBox } from '../../RoundedBox';
import { Caption, Caption2, Header4, Text } from '../../typography';
import { RichText } from '../../typography/RichText';
import styled from '@emotion/styled';
import { dim } from '../../../theme';
import { down } from 'styled-breakpoints';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Box from '../../Box';
//  import { Maybe, SanityImageAsset } from '@astc/frontend/graphql-types';

const StoryInfoContentAuthor = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: ${dim(32)};
  &:last-child {
    margin-bottom: 0;
  }
`;

const ContentContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled(Box)`
  position: relative;
  width: 224px;
  flex-shrink: 0;
  aspect-ratio: 224 / 267;
  ${down('mobile')} {
    display: none;
  }

  img,
  .gatsby-image-wrapper {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 224 / 267;
  }
`;

const _StoryInfoContentAuthors: React.FC<{
  creators: StoryCreatorData[];
  className?: string;
}> = ({ creators, className }) => {
  const elements = creators
    .filter(
      (
        creator,
      ): creator is {
        role: string;
        creator: {
          name: string;
          bio?: string | PTBlocks;
          image?: any;
          locations?: string[];
          location?: string;
        };
      } => {
        return !!creator && !!creator.creator && !!creator.creator.name;
      },
    )
    .map(({ role, creator: { name, bio, locations, location, image } }) => {
      const imageData =
        image && image?.asset?.gatsbyImageData
          ? getImage(image.asset?.gatsbyImageData)
          : undefined;

      return (
        <StoryInfoContentAuthor key={name}>
          {imageData ? (
            <ImageWrapper>
              <GatsbyImage alt="ABC" image={imageData} />
            </ImageWrapper>
          ) : (
            <ImageWrapper>
              <div />
            </ImageWrapper>
          )}
          <ContentContainer>
            <Caption mb="2">About the {role}</Caption>
            <Header4
              mb="2"
              color="#313131"
              fontWeight={['700', '500']}
              fontSize={[dim(27), dim(36)]}
            >
              {name}
            </Header4>
            {locations && (
              <Caption2 mb="2" fontWeight={500} color="#313131" variant="plain">
                {locations.join(' / ')}
              </Caption2>
            )}
            {!!location && (
              <Caption2 mb="2" fontWeight={500} color="#313131" variant="plain">
                {location}
              </Caption2>
            )}
            <Text mt="2" key={name}>
              <RichText text={bio} />
            </Text>
          </ContentContainer>
        </StoryInfoContentAuthor>
      );
    });

  return (
    <RoundedBox className={className}>
      <Header4 mb="3" fontWeight={500} fontSize={[dim(36), dim(36)]}>
        About the Artists
      </Header4>
      {elements}
    </RoundedBox>
  );
};

export const StoryInfoContentAuthors = styled(_StoryInfoContentAuthors)`
  margin-top: ${dim(43)};
  padding: ${dim(37)} ${dim(24)} ${({ theme }) => theme.space[4]};
  ${down('mobile')} {
    padding-bottom: ${({ theme }) => theme.space[3]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
    border-radius: 0;
    margin-bottom: ${({ theme }) => theme.space[3]};
  }
`;
