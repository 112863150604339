import React from 'react';
import styled from '@emotion/styled';
import { ReadingListsItemData } from '../../../types';
import IconButton from '../../inputs/IconButton';
import { Icon } from '@iconify/react';
import baselineReportProblem from '@iconify/icons-ic/baseline-report-problem';
import bxsShareAlt from '@iconify/icons-bx/bxs-share-alt';
import overflowMenuVertical from '@iconify/icons-carbon/overflow-menu-vertical';
import { Caption, Header4, Header6 } from '../../typography';
import Box from '../../Box';
import { dim } from '../../../theme';
import { useTheme } from '@emotion/react';
import { down } from 'styled-breakpoints';
import { space, SpaceProps } from 'styled-system';
import {
  DetailDropDown,
  DetailDropDownEntry,
} from '../../inputs/DetailDropDown';

export type ReadingListHeaderProps = Omit<ReadingListsItemData, 'image'> & {
  isReported: boolean;
  onShare(): void;
  onEditClick(): void;
  onDeleteClick(): void;
  onReportClick(): void;
};

const StyledReadingListHeader = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  margin-top: ${dim(39)};
  margin-bottom: 0;
  width: 100%;
  height: ${dim(246)};
  padding: ${dim(10)};
  ${down('mobile')} {
    margin-top: 0;
    margin-bottom: ${dim(16)};
    height: ${dim(240)};
  }
`;

const ReadingListHeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
`;

const ReadingListHeaderTitle = styled(Header4)`
  margin-bottom: ${({ theme }) => theme.space[3]};
  color: ${({ theme }) => theme.colors.white};
`;

const ReadingListHeaderDetails = styled(Caption)<SpaceProps>`
  color: ${({ theme }) => theme.colors.white};
  display: inline-flex;
  align-items: center;
  ${space}
`;

const ReadingListHeaderDescription = styled(ReadingListHeaderDetails)`
  flex: 1;
  align-items: flex-start;
`;

const ReadingListHeaderWarning = styled(ReadingListHeaderDetails)`
  white-space: nowrap;
`;

const ReadingListHeaderWarningText = styled(Caption)`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.white};
  margin-right: ${({ theme }) => theme.space[2]};
  ${down('mobile')} {
    display: none;
  }
`;

const ReadingListDetailButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.white}
  flex: 0;
  margin-left: ${({ theme }) => theme.space[2]};
  ${down('mobile')} {
    //color: ${({ theme }) => theme.colors.black};
  }
`;

const ReadingListShareButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.white}
  flex: 0;
  margin-left: ${({ theme }) => theme.space[2]};
  ${down('mobile')} {
    //color: ${({ theme }) => theme.colors.black};
  }
`;

export const ReadingListHeader: React.FC<ReadingListHeaderProps> = ({
  name,
  body,
  count: { read, total },
  isReported,
  onShare,
  onReportClick,
  onEditClick,
  onDeleteClick,
}) => {
  const { colors } = useTheme();
  const [showDetails, setShowDetails] = React.useState(false);
  const handleDetailClick = () => {
    setShowDetails((prevState) => !prevState);
  };
  const handleEditClick = () => {
    setShowDetails(false);
    onEditClick();
  };
  const handleDeleteClick = () => {
    setShowDetails(false);
    onDeleteClick();
  };

  const listOrigin = `User made reading list`;
  return (
    <StyledReadingListHeader>
      <ReadingListHeaderDetails as="div" mb={dim(10)}>
        {listOrigin}
      </ReadingListHeaderDetails>
      <ReadingListHeaderTitle>{name}</ReadingListHeaderTitle>
      <ReadingListHeaderDescription as="div">
        {body}
      </ReadingListHeaderDescription>
      <ReadingListHeaderActions>
        <Box flex="1">
          <Header6 color={colors.white} mb="0">
            {read} of {total} comics read
          </Header6>
        </Box>
        <Box flex="0">
          {!isReported && (
            <ReadingListHeaderWarning
              onClick={onReportClick}
              aria-label="Report Reading List"
            >
              <ReadingListHeaderWarningText>
                Report Reading List
              </ReadingListHeaderWarningText>
              <Icon
                icon={baselineReportProblem}
                color={colors.white}
                height={19}
              />
            </ReadingListHeaderWarning>
          )}
          {isReported && (
            <ReadingListHeaderWarning>
              <ReadingListHeaderWarningText>
                Reported&nbsp;
              </ReadingListHeaderWarningText>
              <Icon
                icon={baselineReportProblem}
                color={colors.reds['50']}
                height={19}
              />
            </ReadingListHeaderWarning>
          )}
        </Box>
        <ReadingListDetailButton
          onClick={handleDetailClick}
          aria-label={`View ${name} details`}
        >
          <Icon icon={overflowMenuVertical} height={24} color={colors.white} />
        </ReadingListDetailButton>
        <ReadingListShareButton onClick={onShare} aria-label={`Share ${name}`}>
          <Icon icon={bxsShareAlt} height={24} color={colors.white} />
        </ReadingListShareButton>
      </ReadingListHeaderActions>
      {showDetails && (
        <DetailDropDown inset={50}>
          <DetailDropDownEntry onClick={handleEditClick} label="Edit" />
          <DetailDropDownEntry onClick={handleDeleteClick} label="Delete" />
        </DetailDropDown>
      )}
    </StyledReadingListHeader>
  );
};

export default ReadingListHeader;
