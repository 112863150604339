export const themeValues = [
  '10',
  '20',
  '30',
  '40',
  '50',
  '60',
  '70',
  '80',
  '90',
  '100',
] as const;
export type ThemeValueKey = typeof themeValues[number];
export type ThemeValue = {
  readonly [K in ThemeValueKey]: string & {};
};

export const themeHues = ['blues', 'reds', 'grays', 'greens'] as const;
export type ThemeHue = typeof themeHues[number];

export const themeFixedColors = [
  'white',
  'black',
  'list-pink',
  'list-red',
  'list-orange',
  'list-yellow',
  'list-green',
  'list-blue',
  'list-cyan',
  'list-purple',
] as const;
export type ThemeFixedColor = typeof themeFixedColors[number];

export type ThemeState = Record<typeof themeStates[number], string & {}>;
export const themeStates = [
  'background',
  'regular',
  'disabled',
  'highlight',
  'error',
  'overlay',
  'active',
  'hovered',
] as const;

export const blues: ThemeValue = {
  '10': '#EDF2FF',
  '20': '#D4E0FF',
  '30': '#B7CBFF',
  '40': '#749BFF',
  '50': '#3E74FF',
  '60': '#0047FF',
  '70': '#0038C9',
  '80': '#002A95',
  '90': '#001C63',
  '100': '#00103A',
} as const;

export const reds: ThemeValue = {
  '10': '#fff1f1',
  '20': '#ffd7d9',
  '30': '#ffb3b8',
  '40': '#FF8383',
  '50': '#FF333E',
  '60': '#FF000E',
  '70': '#A30007',
  '80': '#730006',
  '90': '#4E0004',
  '100': '#2E0002',
} as const;

export const grays: ThemeValue = {
  '10': '#f2f4f8',
  '20': '#dde1e6',
  '30': '#c1c7cd',
  '40': '#a2a9b0',
  '50': '#878d96',
  '60': '#697077',
  '70': '#4d5358',
  '80': '#343a3f',
  '90': '#21272a',
  '100': '#121619',
} as const;

export const greens: ThemeValue = {
  '10': '#defbe6',
  '20': '#B1FBC5',
  '30': '#6FEB90',
  '40': '#2DDC5E',
  '50': '#1AA642',
  '60': '#198038',
  '70': '#128234',
  '80': '#03501A',
  '90': '#022d0d',
  '100': '#071908',
} as const;

export const listColors: Record<ThemeFixedColor, string & {}> = {
  white: '#ffffff',
  black: '#000000',
  'list-pink': '#FF70B4',
  'list-red': '#FF333E',
  'list-orange': '#FBD874',
  'list-yellow': '#FFF739',
  'list-green': '#D7FF39',
  'list-cyan': '#1CE8E5',
  'list-blue': '#0047FF',
  'list-purple': '#6929C4',
} as const;

const randomizeableColors = Object.entries(listColors)
  .filter(([key]) => key.includes('list-'))
  .map(([_, value]) => value);

export const randomListColor = (seed: string): string => {
  const randomIndex = Number(`0x${seed}`) % randomizeableColors.length;
  return randomizeableColors[randomIndex];
};

export const white = listColors.white;
export const black = listColors.black;
