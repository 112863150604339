import React from 'react';
import styled from '@emotion/styled';
import { Header4, Text } from '../../typography';
import Button2 from '../../inputs/Button2';
import { ReadingExperience } from '../../../types';
import {
  READING_EXPERIENCE_CHOICE_THUMBNAIL_SIZE,
  ReadingExperienceChoice,
  ReadingExperienceChoices,
  ReadingExperienceImage,
} from '../../onboarding';
import { GenericOverlay } from '../GenericOverlay';

const StyledReadingExperienceOverlay = styled(GenericOverlay)``;

const ReadingExperienceOverlayContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export interface ReadingExperienceOverlayProps {
  subtitle: string;
  body: string;
  footer: string;
  onReadingExperienceSelect(mode: ReadingExperience): () => void;
  onRequestClose(): void;
}

type Overlay = React.FC<ReadingExperienceOverlayProps>;

export const ReadingExperienceOverlay: Overlay = ({
  subtitle,
  body,
  footer,
  onReadingExperienceSelect,
  onRequestClose,
}) => {
  return (
    <StyledReadingExperienceOverlay onClick={onRequestClose}>
      <ReadingExperienceOverlayContent>
        <Header4 color="white">{subtitle}</Header4>
        <Text color="white">{body}</Text>
        <ReadingExperienceChoices>
          <ReadingExperienceChoice>
            <ReadingExperienceImage
              src={`https://placehold.jp/${READING_EXPERIENCE_CHOICE_THUMBNAIL_SIZE.width}x${READING_EXPERIENCE_CHOICE_THUMBNAIL_SIZE.height}.png`}
              alt="interactive mode example thumbnail"
            />
            <Button2
              onClick={onReadingExperienceSelect(ReadingExperience.INTERACTIVE)}
              palette="red"
            >
              Interactive Mode
            </Button2>
          </ReadingExperienceChoice>
          <ReadingExperienceChoice>
            <ReadingExperienceImage
              src={`https://placehold.jp/${READING_EXPERIENCE_CHOICE_THUMBNAIL_SIZE.width}x${READING_EXPERIENCE_CHOICE_THUMBNAIL_SIZE.height}.png`}
              alt="read-only mode example thumbnail"
            />
            <Button2
              onClick={onReadingExperienceSelect(ReadingExperience.READ_ONLY)}
              palette="red"
            >
              Read Only Mode
            </Button2>
          </ReadingExperienceChoice>
        </ReadingExperienceChoices>
        <Text textAlign="center" color="white">
          {footer}
        </Text>
      </ReadingExperienceOverlayContent>
    </StyledReadingExperienceOverlay>
  );
};

export default ReadingExperienceOverlay;
