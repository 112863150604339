import styled from '@emotion/styled';
import {
  backgroundColor,
  BackgroundColorProps,
  BorderProps,
  borders,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';

export type BoxProps = ColorProps &
  SpaceProps &
  FlexboxProps &
  LayoutProps &
  PositionProps &
  TypographyProps &
  BorderProps &
  ShadowProps &
  BackgroundColorProps;

export const Box = styled.div<BoxProps>`
  box-sizing: border-box;
  ${color}
  ${space}
  ${flexbox}
  ${layout}
  ${position}
  ${typography}
  ${borders}
  ${shadow}
  ${backgroundColor}
`;

export default Box;
