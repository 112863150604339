import { CreatorData, CountryData, ImageData, PTBlocks } from './index';
import { isPlainObject, Maybe } from './utils';
import { PanelProps } from './panel';

export interface GenreData {
  name: string;
}

export interface StoryCreatorData {
  creator: CreatorData;
  role: string;
}

export const isStoryCreatorData = (
  creator: unknown,
): creator is StoryCreatorData => {
  if (!creator) return false;
  if (!isPlainObject(creator)) return false;
  return (
    !!creator.hasOwnProperty('creator') &&
    !!creator.hasOwnProperty('role') &&
    !!creator.role &&
    typeof creator.role === 'string' &&
    isPlainObject(creator.creator) &&
    !!creator.creator.hasOwnProperty('name') &&
    !!creator.creator.name &&
    typeof creator.creator.name === 'string'
  );
};

export interface StoryMediumData {
  name: string;
  date?: string;
  description: Maybe<string | PTBlocks>;
  image?: ImageData;
}

export interface StoryLocationData {
  name: string;
  date?: string;
  description: Maybe<string | PTBlocks>;
  image?: ImageData;
}

export interface StoryCharacterData {
  name: string;
  location?: string;
  age?: string;
  bio: Maybe<string | PTBlocks>;
  image?: ImageData;
}

export interface StoryIntroData {
  id?: string;
  title: string;
  creators: StoryCreatorData[];
  country: Maybe<CountryData>;
  characters: StoryCharacterData[];
  medium?: StoryMediumData;
  genres: GenreData[];
  images: {
    thumbnail?: ImageData;
    intro?: ImageData;
    promo?: ImageData;
  };
  prologue?: Maybe<string | PTBlocks>;
}

export interface StoryData {
  intro: StoryIntroData;
  panels: PanelProps[];
}
