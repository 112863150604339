import styled from '@emotion/styled';
import { dim } from '../../../theme';
import { down } from 'styled-breakpoints';
import Box from '../../Box';

export const PageBoundary = styled(Box)<{ width?: number }>`
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: ${({ width }) => dim(width || 632)};
  ${down('mobile')} {
    width: 100%;
    padding: ${({ theme }) => theme.space[3]};
  }
`;
