import React, { ReactNode } from 'react';
import { PTBlock } from '../../../types';
import { Header4, Text } from '../../typography';
import { RichText } from '../../typography/RichText';
import styled from '@emotion/styled';
import Box from '../../Box';
import { Maybe } from '../../../types/utils';
import { dim } from '../../../theme';

const _StoryInfoContentCharacter: React.FC<{
  name: Maybe<string>;
  age?: Maybe<string>;
  location?: Maybe<string>;
  bio: string | Maybe<PTBlock>[] | null | undefined;
  image?: ReactNode;
  className?: string;
}> = ({ name, age, location, bio, image, className }) => (
  <Box className={className}>
    {image}
    <Box ml="2" flex={1}>
      <Header4 mb="2" fontWeight="500" fontSize={[dim(27), dim(27)]}>
        {name}
      </Header4>
      {location && <Text>{location}</Text>}
      {age && <Text>Age: {age}</Text>}
      <Text mt={2}>
        <RichText text={bio} />
      </Text>
    </Box>
  </Box>
);

export const StoryInfoContentCharacter = styled(_StoryInfoContentCharacter)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: ${({ theme }) => theme.space[2]};
  margin-bottom: ${({ theme }) => theme.space[2]};
  p > p {
    margin-bottom: ${dim(12)};
  }
`;
