import { StoryIntroData } from '../../../types';
import styled from '@emotion/styled';
import Box from '../../Box';
import { Caption, Header7, Text } from '../../typography';
import React from 'react';
import { dim } from '../../../theme';
import {
  HorizontalComicCardArt,
  HorizontalComicCardLayerLower,
  HorizontalComicCardLayerMid,
  HorizontalComicCardLayerUpper,
} from '../HorizontalComicCardArt';
import { transition } from '../utis';
import { down } from 'styled-breakpoints';
import { RichText } from '../../typography/RichText';

export type HorizontalComicCardProps = {
  lowerLayer?: React.ReactNode;
  midLayer?: React.ReactNode;
  upperLayer?: React.ReactNode;
  onClick: () => void;
} & Omit<StoryIntroData, 'images' | 'characters'>;

const HorizontalComicCardHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ theme }) => theme.space[2]};
  padding: 0 ${({ theme }) => theme.space[2]};
`;

const HorizontalComicCardContent = styled(Box, {
  target: 'styledHorizontalComicCardContent',
})`
  width: 100%;
  margin-top: ${({ theme }) => theme.space[3]};
  padding: 0 ${({ theme }) => theme.space[2]};
  height: ${dim(80)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  white-space: normal;
`;

const HorizontalComicCardCaption = styled(Caption, {
  target: 'styledHorizontalComicCardCaption',
})`
  transition: ${transition('color')};
`;

const HorizontalComicCardAuthors = styled(Header7, {
  target: 'styledHorizontalComicCardAuthors',
})`
  margin-top: ${dim(6)};
  color: ${({ theme }) => theme.colors.grays['80']};
  transition: ${transition('color')};
`;

const StyledHorizontalComicCard = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dim(412)};
  min-height: ${dim(412)};
  &:hover ${HorizontalComicCardLayerLower} {
    transform: scale(1.04);
  }
  &:active ${HorizontalComicCardLayerLower}:after {
    content: '';
    background-color: ${({ theme }) => theme.colors.blues['30']};
    opacity: 0.25;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: ${dim(10)};
  }
  &:hover ${HorizontalComicCardLayerMid} {
    transform: scale(1.06);
  }
  &:hover ${HorizontalComicCardLayerUpper} {
    transform: scale(1.08);
  }
  &:active ${HorizontalComicCardCaption} {
    color: ${({ theme }) => theme.colors.blues['30']};
  }
  &:active ${HorizontalComicCardAuthors} {
    color: ${({ theme }) => theme.colors.blues['60']};
  }
  &:active ${HorizontalComicCardContent} {
    color: ${({ theme }) => theme.colors.blues['60']};
  }
  ${down('mobile')} {
    width: ${dim(254)};
    min-height: ${dim(311)};
  }
`;

export const HorizontalComicCard: React.FC<HorizontalComicCardProps> = ({
  lowerLayer,
  midLayer,
  upperLayer,
  title,
  country,
  genres,
  creators,
  prologue,
  onClick,
}) => {
  return (
    <StyledHorizontalComicCard onClick={onClick} aria-label={title} role="link">
      <HorizontalComicCardArt
        lowerLayer={lowerLayer}
        midLayer={midLayer}
        upperLayer={upperLayer}
      />
      <HorizontalComicCardHeader>
        <HorizontalComicCardCaption>{country?.name}</HorizontalComicCardCaption>
        <HorizontalComicCardCaption>
          {genres.map((genre) => genre.name).join(', ')}
        </HorizontalComicCardCaption>
        <HorizontalComicCardAuthors>
          {creators.map(({ creator }) => creator.name).join(', ')}
        </HorizontalComicCardAuthors>
      </HorizontalComicCardHeader>
      <HorizontalComicCardContent>
        <Text>
          <RichText text={prologue} />
        </Text>
      </HorizontalComicCardContent>
    </StyledHorizontalComicCard>
  );
};
