import React from 'react';
import styled from '@emotion/styled';
import { Text } from '../../typography';
import { dim } from '../../../theme';
import Box from '../../Box';
import { down } from 'styled-breakpoints';
import { isReactElementWithKey } from '../../../types/utils';
import { Logo } from '../../index';

const StyledPageFooter = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 100%;
  max-width: ${dim(1232)};
  justify-content: space-between;
  align-items: center;
  margin-top: ${dim(48)};
  padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[3]};
  border-top: ${({ theme }) => theme.borderWidths[1]} solid
    ${({ theme }) => theme.colors.grays['10']};
  ${down('mobile')} {
    margin-top: ${({ theme }) => theme.space[4]};
    flex-direction: column;
    align-items: flex-start;
    padding: ${({ theme }) => theme.space[3]};
  }
`;

const PageFooterNav = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${down('mobile')} {
    width: 100%;
  }
`;

const PageFooterText = styled(Box)`
  display: flex;
  flex-direction: row;
  flex: 0;
  justify-content: flex-end;
  align-items: center;
`;

const PageFooterMenu = styled.ul`
  display: flex;
  flex-direction: row;
  flex: 0;
  justify-content: space-between;
  align-items: center;
  ${down('mobile')} {
    flex: 1;
    flex-wrap: wrap;
  }
`;

const PageFooterMenuItem = styled.li`
  margin-left: ${dim(20)};
  font-family: ${({ theme }) => theme.fonts['body']};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights['regular']};
  font-size: ${dim(16)};
  line-height: 1;
  white-space: nowrap;
`;

const PageFooterCopyright = styled(Box)`
  margin-left: ${dim(20)};
  font-family: ${({ theme }) => theme.fonts['body']};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights['regular']};
  font-size: ${({ theme }) => theme.space[3]};
  line-height: 1;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.grays['50']};
  ${down('mobile')} {
    margin-left: 0;
    margin-top: ${dim(13)};
  }
`;

export const PageFooter: React.FC<{ children: React.ReactNode[] }> = ({
  children,
}) => {
  return (
    <StyledPageFooter>
      <PageFooterNav>
        <Logo width={dim(59)} height={dim(38)} />
        <PageFooterMenu>
          {React.Children.map(children, (child) => {
            if (!isReactElementWithKey(child)) return null;
            return (
              <PageFooterMenuItem key={'parent_' + child.key}>
                {child}
              </PageFooterMenuItem>
            );
          })}
        </PageFooterMenu>
      </PageFooterNav>
      <PageFooterText>
        <PageFooterCopyright>
          <Text>ⓒ Asia Society Texas Center</Text>
        </PageFooterCopyright>
      </PageFooterText>
    </StyledPageFooter>
  );
};

export default PageFooter;
