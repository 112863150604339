import React, { useCallback, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { Player } from '@lottiefiles/react-lottie-player';
import { AnimationAreaProps } from '../../../types';
import { dim } from '../../../theme';

const StyledAnimationArea = styled.div`
  display: flex;
`;

export const AnimationArea: React.FC<AnimationAreaProps> = ({
  src,
  width,
  height,
  alt,
  autoplay,
  onReady,
}) => {
  const player = useRef<Player | null>(null);
  const onEvent = useCallback(
    (ev: string) => {
      if (ev === 'ready') {
        onReady();
      }
    },
    [onReady],
  );
  const style = useMemo(
    () =>
      Object.entries({ width, height }).reduce((arr, [key, value]) => {
        if (value) {
          return { ...arr, [key]: dim(value) };
        }
        return arr;
      }, {} as Record<string, string>),
    [width, height],
  );
  return (
    <StyledAnimationArea aria-label={alt}>
      <Player
        ref={player}
        onEvent={onEvent}
        src={src}
        style={style}
        autoplay={autoplay}
        loop
      />
    </StyledAnimationArea>
  );
};

export default AnimationArea;
