import React from 'react';
import styled from '@emotion/styled';
import Box from '../../Box';
import { dim } from '../../../theme';
import { Text } from '../../typography';

const TEXT_PADDING = 32;

const ToggleSwitch = styled(Box)`
  position: relative;
  width: 75px;
  display: inline-block;
  text-align: left;
  top: 8px;
  transform: rotate(90deg);
`;

const ToggleCheckbox = styled.input`
  display: none;
`;

const ToggleTextTop = styled(Text)<{ value: boolean }>`
  padding-bottom: ${dim(TEXT_PADDING / 2)};
  font-weight: ${({ value }) => (value ? 'normal' : 'bold')};
`;

const ToggleTextBottom = styled(Text)<{ value: boolean }>`
  padding-top: ${dim(TEXT_PADDING)};
  font-weight: ${({ value }) => (value ? 'bold' : 'normal')};
`;

const ToggleLabel = styled.label<{ value: boolean }>`
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #bbb;
  border-radius: 20px;
`;

const ToggleInner = styled.span<{ value: boolean }>`
  display: block;
  width: 200%;
  margin-left: ${({ value }) => (value ? 0 : '-100%')};);
  transition: margin 0.3s ease-in 0s;

  &:before,
  &:after {
    float: left;
    width: 50%;
    height: 36px;
    padding: 0;
    line-height: 36px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
  }
  &:before {
    content: '';
    padding-left: 10px;
    background-color: white;
    color: #fff;
  }
  &:after {
    content: '';
    padding-right: 10px;
    background-color: white;
    color: #fff;
    text-align: right;
  }
`;

const ToggleSwitchInput = styled.span<{ value: boolean }>`
  display: block;
  width: 30px;
  margin: 3px;
  background: ${({ theme }) => theme.colors.blues['50']};
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${({ value }) => (value ? dim(0) : dim(40))};);
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
`;

const _Toggle: React.FC<{
  top: string;
  bottom: string;
  className?: string;
  initial: boolean;
  onChange?(value: boolean): void;
}> = ({ top, bottom, initial, className, onChange }) => {
  const [value, setValue] = React.useState(initial);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(value);
    setValue(e.target.checked);
  };
  return (
    <Box className={className}>
      <ToggleTextTop value={value}>{top}</ToggleTextTop>
      <ToggleSwitch>
        <ToggleCheckbox
          type="checkbox"
          name="toggle"
          id="toggle"
          checked={value}
          onChange={handleChange}
        />
        <ToggleLabel htmlFor="toggle" value={value}>
          <ToggleInner value={value} />
          <ToggleSwitchInput value={value} />
        </ToggleLabel>
      </ToggleSwitch>
      <ToggleTextBottom value={value}>{bottom}</ToggleTextBottom>
    </Box>
  );
};

export const Toggle = styled(_Toggle)`
  display: flex;
  flex-direction: column;
  width: ${dim(140)};
  align-items: center;
`;
