import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ImmersiveTextPanelProps } from '../../../types';
import { Image, Layer, Stage, Text, Group, Rect } from 'react-konva';
import useImage from 'use-image';
import { useTheme } from '@emotion/react';
import Box from '../../Box';
import { dim } from '../../../theme';
import { PanelCTA } from '../PanelCTA';
import { PanelCaption } from '../PanelCaption';
import Textarea from '../../inputs/Textarea';
import Button2 from '../../inputs/Button2';
import { down } from 'styled-breakpoints';

const StyledImmersiveTextPanel = styled(Box)`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: ${dim(56)} 0 ${({ theme }) => theme.space[4]};
  ${down('mobile')} {
    padding: ${dim(40)} 0 ${({ theme }) => theme.space[3]};
  }
`;

export const ImmersiveTextPanel: React.FC<ImmersiveTextPanelProps> = ({
  width,
  height,
  src,
  alt,
  cta,
  textArea,
  content: _content,
  onSubmit,
}) => {
  const [image] = useImage(src);
  const { colors } = useTheme();
  const [content, setContent] = useState<string>();
  const handleContentChange = useCallback<
    (ev: React.FormEvent<HTMLTextAreaElement>) => void
  >(
    (ev) => {
      setContent(ev.currentTarget.value);
    },
    [setContent],
  );
  const handleSubmit = useCallback<
    (ev: React.FormEvent<HTMLFormElement>) => void
  >(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      if (content) onSubmit(content);
    },
    [onSubmit, content],
  );
  useEffect(() => {
    setContent(_content);
  }, [_content, setContent]);
  return (
    <StyledImmersiveTextPanel
      width={dim(width)}
      minHeight={dim(height)}
      aria-label={alt}
    >
      <PanelCTA>{cta}</PanelCTA>
      <Stage width={width} height={height} className="canvas-stage">
        <Layer>
          <Image image={image} width={width} height={height} />
        </Layer>
        <Layer>
          <Group
            x={textArea.x * width}
            y={textArea.y * width}
            width={textArea.width * width}
            height={textArea.height * width}
            rotationDeg={textArea.angle}
            skewX={(textArea.skew / 180) * Math.PI}
          >
            <Rect
              x={-5}
              y={-5}
              width={textArea.width * width + 10}
              height={textArea.height * width + 10}
              stroke={colors.blues['50']}
              strokeWidth={4}
              fill="transparent"
            />
            <Text
              text={content}
              width={textArea.width * width}
              height={textArea.height * width}
              fontSize={textArea.fontSize * width}
            />
          </Group>
        </Layer>
      </Stage>
      <form onSubmit={handleSubmit}>
        <>
          <PanelCaption>Short Response</PanelCaption>
          <Textarea
            aria-label="answer text"
            onChange={handleContentChange}
            value={content}
          />
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Button2
              type="submit"
              disabled={!content}
              palette={'blue'}
              variant={'secondary'}
            >
              Submit
            </Button2>
          </Box>
        </>
      </form>
    </StyledImmersiveTextPanel>
  );
};

export default ImmersiveTextPanel;
