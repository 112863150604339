import { blues, white } from '../colors';
import {
  ThemeElementPalette,
  ThemeElementPaletteVariantState,
  ThemeElementStateProperty,
} from './types';

const blueDefaultPrimary: Record<ThemeElementStateProperty, string & {}> = {
  background: white,
  border: blues['60'],
  shadow: blues['60'],
  text: blues['60'],
};
const blueDisabledPrimary: Record<ThemeElementStateProperty, string & {}> = {
  ...blueDefaultPrimary,
  border: blues['30'],
  text: blues['30'],
};
const blueDownPrimary: Record<ThemeElementStateProperty, string & {}> = {
  ...blueDefaultPrimary,
  background: blues['30'],
  border: blues['80'],
  text: blues['80'],
};
const blueHoverPrimary: Record<ThemeElementStateProperty, string & {}> = {
  ...blueDefaultPrimary,
  border: blues['50'],
  shadow: blues['50'],
  text: blues['50'],
};
const blueVariantDefaultPrimary: ThemeElementPaletteVariantState = {
  default: { ...blueDefaultPrimary },
  disabled: { ...blueDisabledPrimary },
  down: { ...blueDownPrimary },
  hover: { ...blueHoverPrimary },
};

const blueDefaultSecondary: Record<ThemeElementStateProperty, string & {}> = {
  background: blues['60'],
  border: blues['60'],
  shadow: blues['60'],
  text: white,
};
const blueDisabledSecondary: Record<ThemeElementStateProperty, string & {}> = {
  ...blueDefaultSecondary,
  background: blues['20'],
  shadow: blues['20'],
  border: blues['20'],
};
const blueDownSecondary: Record<ThemeElementStateProperty, string & {}> = {
  ...blueDefaultSecondary,
  background: blues['80'],
  border: blues['80'],
  text: blues['30'],
};
const blueHoverSecondary: Record<ThemeElementStateProperty, string & {}> = {
  ...blueDefaultSecondary,
  background: blues['50'] + 'FF',
  border: blues['50'] + 'FF',
  shadow: blues['70'] + '33',
};
const blueVariantDefaultSecondary: ThemeElementPaletteVariantState = {
  default: { ...blueDefaultSecondary },
  disabled: { ...blueDisabledSecondary },
  down: { ...blueDownSecondary },
  hover: { ...blueHoverSecondary },
};

export const bluePalette: ThemeElementPalette = {
  button: {
    primary: { ...blueVariantDefaultPrimary },
    secondary: { ...blueVariantDefaultSecondary },
  },
  default: {
    primary: { ...blueVariantDefaultPrimary },
    secondary: { ...blueVariantDefaultSecondary },
  },
  input: {
    primary: { ...blueVariantDefaultPrimary },
    secondary: { ...blueVariantDefaultSecondary },
  },
};
