import { greens, white } from '../colors';
import {
  ThemeElementPalette,
  ThemeElementPaletteVariantState,
  ThemeElementStateProperty,
} from './types';

const greenDefaultPrimary: Record<ThemeElementStateProperty, string & {}> = {
  background: white,
  border: greens['40'],
  shadow: greens['40'],
  text: greens['40'],
};
const greenDisabledPrimary: Record<ThemeElementStateProperty, string & {}> = {
  ...greenDefaultPrimary,
  border: greens['20'],
  text: greens['20'],
};
const greenDownPrimary: Record<ThemeElementStateProperty, string & {}> = {
  ...greenDefaultPrimary,
  background: greens['30'],
  border: greens['50'],
  text: greens['50'],
};
const greenHoverPrimary: Record<ThemeElementStateProperty, string & {}> = {
  ...greenDefaultPrimary,
  shadow: greens['30'],
  border: greens['30'],
  text: greens['30'],
};
const greenVariantDefaultPrimary: ThemeElementPaletteVariantState = {
  default: { ...greenDefaultPrimary },
  disabled: { ...greenDisabledPrimary },
  down: { ...greenDownPrimary },
  hover: { ...greenHoverPrimary },
};

const greenDefaultSecondary: Record<ThemeElementStateProperty, string & {}> = {
  background: greens['40'],
  border: greens['40'],
  shadow: greens['40'],
  text: white,
};
const greenDisabledSecondary: Record<ThemeElementStateProperty, string & {}> = {
  ...greenDefaultSecondary,
  background: greens['20'],
  shadow: greens['20'],
  border: greens['20'],
};
const greenDownSecondary: Record<ThemeElementStateProperty, string & {}> = {
  ...greenDefaultSecondary,
  background: greens['50'],
  border: greens['50'],
  text: greens['30'],
};
const greenHoverSecondary: Record<ThemeElementStateProperty, string & {}> = {
  ...greenDefaultSecondary,
  background: greens['30'] + 'FF',
  border: greens['30'] + 'FF',
  shadow: greens['70'] + '33',
};
const greenVariantDefaultSecondary: ThemeElementPaletteVariantState = {
  default: { ...greenDefaultSecondary },
  disabled: { ...greenDisabledSecondary },
  down: { ...greenDownSecondary },
  hover: { ...greenHoverSecondary },
};

export const greenPalette: ThemeElementPalette = {
  button: {
    primary: { ...greenVariantDefaultPrimary },
    secondary: { ...greenVariantDefaultSecondary },
  },
  default: {
    primary: { ...greenVariantDefaultPrimary },
    secondary: { ...greenVariantDefaultSecondary },
  },
  input: {
    primary: { ...greenVariantDefaultPrimary },
    secondary: { ...greenVariantDefaultSecondary },
  },
};
