import React from 'react';
import styled from '@emotion/styled';
import {
  background,
  BackgroundProps,
  layout,
  LayoutProps,
} from 'styled-system';
import { dim } from '../../../theme';
import { Caption, Header5, Header7, Text } from '../../typography';
import { StoryIntroData } from '../../../types';
import Box from '../../Box';
import { down } from 'styled-breakpoints';
import {
  StyledVerticalComicCardArt,
  VerticalComicCardArt,
  VerticalComicCardLayerLower,
  VerticalComicCardLayerMid,
  VerticalComicCardLayerUpper,
} from '../VerticalComicCardArt';

const ComicCardThumbnail = styled(Box, { target: 'comic-card-thumbnail' })<
  BackgroundProps & LayoutProps
>`
  display: flex;
  flex-grow: 0;
  position: absolute;
  background: no-repeat center;
  background-size: cover;
  width: ${dim(192)};
  height: ${dim(283)};
  margin: 0 ${dim(17)};
  transition: transform 0.3s ease-in-out;
  transform-origin: top center;
  filter: drop-shadow(
    ${dim(16.5122)} ${dim(3.88523)} ${dim(11.6557)} rgba(0, 0, 0, 0.05)
  );
  ${down('mobile')} {
    width: ${dim(128)};
    height: ${dim(188)};
    margin: 0 ${dim(4)};
    filter: drop-shadow(
      ${dim(10.9545)} ${dim(2.57752)} ${dim(7.73256)} rgba(0, 0, 0, 0.05)
    );
  }
  ${background}
  ${layout};
`;

const ComicCardDetail = styled(Text, { target: 'comic-card-detail' })<{
  clear?: boolean;
}>`
  display: block;
  position: relative;
  width: ${dim(226)};
  min-height: ${dim(220)};
  margin-top: ${dim(269)};
  margin-bottom: 0;
  padding: ${dim(60)} ${({ theme }) => theme.space[3]} ${dim(24)};
  border-radius: ${({ theme, clear }) => (clear ? 0 : theme.radii.lg)};
  background-color: ${({ theme, clear }) =>
    clear ? 'none' : theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  box-shadow: ${({ clear }) =>
    clear ? 0 : `0 ${dim(6)} ${dim(12)} rgba(0, 0, 0, 0.08)`};
  box-sizing: border-box;
  ${down('mobile')} {
    width: ${dim(136)};
    min-height: ${dim(188)};
    margin-top: ${dim(184)};
    padding: ${dim(48)} ${dim(10)} ${({ theme }) => theme.space[3]};
    box-shadow: ${({ clear }) =>
      clear ? 0 : `0 ${dim(4)} ${dim(8)} rgba(0, 0, 0, 0.08)`};
  }
  transition: transform 0.3s ease-in-out;
`;

const ComicCardGenres = styled(Caption, { target: 'comic-card-genres' })``;

const ComicCardTitle = styled(Header5, { target: 'comic-card-title' })`
  margin-top: ${({ theme }) => theme.space[2]};
  min-height: ${dim(60)};
  ${down('mobile')} {
    margin-top: ${dim(6)};
    min-height: ${dim(47)};
  }
`;

const ComicCardCreators = styled(Header7, { target: 'comic-card-creators' })`
  margin-top: ${({ theme }) => theme.space[2]};
  min-height: ${dim(42)};
  ${down('mobile')} {
    margin-top: ${dim(6)};
    min-height: ${dim(28)};
  }
`;

const StyledComicCard = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;

  width: ${dim(226)};
  ${down('mobile')} {
    width: ${dim(136)};
  }

  & ${StyledVerticalComicCardArt} {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &:hover ${VerticalComicCardLayerLower} {
    transform: scale(1.04);
  }
  &:active ${VerticalComicCardLayerLower}:after {
    content: '';
    background-color: ${({ theme }) => theme.colors.blues['30']};
    opacity: 0.25;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: ${dim(10)};
  }
  &:hover ${VerticalComicCardLayerMid} {
    transform: scale(1.06);
  }
  &:hover ${VerticalComicCardLayerUpper} {
    transform: scale(1.08);
  }
  &:hover ${ComicCardThumbnail} {
    transform: scale(1.06);
  }
  &:hover ${ComicCardDetail} {
    transform: translateY(${dim(16)});
    ${down('mobile')} {
      transform: none;
    }
  }
  &:active ${ComicCardDetail} {
    background-color: ${({ theme }) => theme.colors.blues['20']};
    color: ${({ theme }) => theme.colors.white};
  }
  &:active ${ComicCardGenres} {
    color: ${({ theme }) => theme.colors.white};
  }
  &:active ${ComicCardTitle} {
    color: ${({ theme }) => theme.colors.white};
  }
  &:active ${ComicCardCreators} {
    color: ${({ theme }) => theme.colors.white};
    ${down('mobile')} {
      transform: none;
    }
  }
`;

type Optional<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>;

export const ComicCard: React.FC<
  Optional<
    Omit<StoryIntroData, 'prologue' | 'images' | 'countries' | 'characters'> & {
      lowerLayer?: React.ReactNode;
      midLayer?: React.ReactNode;
      upperLayer?: React.ReactNode;
      clear?: boolean;
      onClick: () => void;
    },
    'creators'
  >
> = ({
  country,
  genres,
  title,
  creators,
  lowerLayer,
  midLayer,
  upperLayer,
  clear = false,
  onClick,
}) => {
  return (
    <StyledComicCard onClick={onClick} aria-label={title} role="link">
      <ComicCardDetail clear={clear}>
        <ComicCardGenres>{country?.name}</ComicCardGenres>
        <ComicCardGenres>
          {genres.map((genre) => genre.name).join(', ')}
        </ComicCardGenres>
        <ComicCardTitle>{title}</ComicCardTitle>
        {!!creators && (
          <ComicCardCreators>
            {creators?.map(({ creator }) => creator.name).join(', ')}
          </ComicCardCreators>
        )}
      </ComicCardDetail>
      <VerticalComicCardArt
        lowerLayer={lowerLayer}
        midLayer={midLayer}
        upperLayer={upperLayer}
      />
    </StyledComicCard>
  );
};
