import { ReactNode } from 'react';
import styled from '@emotion/styled';
import Box from '../../Box';
import { Caption2 } from '../../typography';
import { dim } from '../../../theme';
import { down } from 'styled-breakpoints';

const RecentlyReadStoryTitle = styled(Caption2)`
  font-weight: normal;
  ${down('mobile')} {
    display: none;
  }
`;

export const _RecentlyReadStory: React.FC<{
  title: string;
  image: ReactNode;
  className?: string;
  onClick?: () => void;
}> = ({ title, image, onClick, className }) => {
  return (
    <Box className={className} onClick={onClick}>
      {image}
      <RecentlyReadStoryTitle variant="plain" mt="2">
        {title}
      </RecentlyReadStoryTitle>
    </Box>
  );
};

export const RecentlyReadStory = styled(_RecentlyReadStory)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 ${({ theme }) => theme.space[3]} 0 0;
  width: ${dim(100)};
  cursor: pointer;
`;
