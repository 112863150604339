import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import Box from '../../Box';
import Button1 from '../../inputs/Button1';
import { dim } from '../../../theme';
import { down, up } from 'styled-breakpoints';
import { StoryIntroData } from '../../../types';
import { StoryInfoContentMedium } from './medium';
import { StoryInfoContentSummary } from './summary';
import { StoryInfoContentAuthors } from './authors';
import { StoryInfoContentLocation } from './location';
import { StoryInfoContentCharacters } from './characters';

const StyledStoryInfo = styled(Box)`
  position: relative;
  box-sizing: border-box;
  width: ${dim(1016)};
  z-index: 4;
  ${down('mobile')} {
    display: none;
    z-index: auto;
  }
`;

const StyledStoryInfoMobile = styled(Box)`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  z-index: auto;
  ${up('mobile')} {
    display: none;
    z-index: auto;
  }
`;

const StoryInfoButtonBar = styled(Box)`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  top: ${dim(24)};
  left: 0;
  right: 0;
  ${down('mobile')} {
    display: none;
  }
`;

export type StoryInfoProps = Pick<
  StoryIntroData,
  'prologue' | 'creators' | 'country' | 'medium' | 'characters'
>;

export const StoryInfo: React.FC<{
  summary: ReactElement<typeof StoryInfoContentSummary>;
  location: ReactElement<typeof StoryInfoContentLocation>;
  characters: ReactElement<typeof StoryInfoContentCharacters>;
  authors: ReactElement<typeof StoryInfoContentAuthors>;
  medium: ReactElement<typeof StoryInfoContentMedium>;
}> = ({ summary, location, characters, authors, medium }) => {
  const [selected, setSelected] = React.useState<
    'summary' | 'location' | 'characters' | 'authors' | 'medium'
  >('summary');
  return (
    <StyledStoryInfo>
      <StoryInfoButtonBar>
        <Button1
          palette={'blue'}
          variant={selected === 'summary' ? 'primary' : 'secondary'}
          onClick={() => setSelected('summary')}
        >
          Story Summary
        </Button1>
        <Button1
          palette={'blue'}
          variant={selected === 'location' ? 'primary' : 'secondary'}
          onClick={() => setSelected('location')}
        >
          Story Location
        </Button1>
        <Button1
          palette={'blue'}
          variant={selected === 'characters' ? 'primary' : 'secondary'}
          onClick={() => setSelected('characters')}
        >
          Characters
        </Button1>
        <Button1
          palette={'blue'}
          variant={selected === 'authors' ? 'primary' : 'secondary'}
          onClick={() => setSelected('authors')}
        >
          About the Authors
        </Button1>
        <Button1
          palette={'blue'}
          variant={selected === 'medium' ? 'primary' : 'secondary'}
          onClick={() => setSelected('medium')}
        >
          History of Medium
        </Button1>
      </StoryInfoButtonBar>
      {selected === 'summary' && summary}
      {selected === 'location' && location}
      {selected === 'characters' && characters}
      {selected === 'authors' && authors}
      {selected === 'medium' && medium}
    </StyledStoryInfo>
  );
};

export const StoryInfoMobile: React.FC<{
  summary: ReactElement<typeof StoryInfoContentSummary>;
  location: ReactElement<typeof StoryInfoContentLocation>;
  characters: ReactElement<typeof StoryInfoContentCharacters>;
  authors: ReactElement<typeof StoryInfoContentAuthors>;
  medium: ReactElement<typeof StoryInfoContentMedium>;
}> = ({ summary, location, characters, authors, medium }) => {
  return (
    <StyledStoryInfoMobile>
      {summary}
      {location}
      {characters}
      {authors}
      {medium}
    </StyledStoryInfoMobile>
  );
};

export { StoryInfoContentMedium } from './medium';
export { StoryInfoContentSummary } from './summary';
export { StoryInfoContentAuthors } from './authors';
export { StoryInfoContentLocation } from './location';
export { StoryInfoContentCharacter } from './character';
export { StoryInfoContentCharacters } from './characters';
