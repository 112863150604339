import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Box from '../../Box';
import { Text } from '../../typography';
import Textarea from '../../inputs/Textarea';
import Button2 from '../../inputs/Button2';
import { ShortResponsePanelProps } from '../../../types';
import { dim } from '../../../theme';
import { down } from 'styled-breakpoints';
import { PanelCTA } from '../PanelCTA';
import { PanelCaption } from '../PanelCaption';

const StyledShortResponsePanel = styled(Box)`
  display: flex;
  position: relative;
  padding: ${dim(56)} 0 ${({ theme }) => theme.space[4]};
  ${down('mobile')} {
    padding: ${dim(40)} 0 ${({ theme }) => theme.space[3]};
  }
`;

export const ShortResponsePanel: React.FC<ShortResponsePanelProps> = ({
  width,
  height,
  cta,
  question,
  image,
  answer: _answer,
  onSubmit,
}) => {
  const [answer, setAnswer] = useState<string>();
  const handleAnswerChange = useCallback<
    (ev: React.FormEvent<HTMLTextAreaElement>) => void
  >(
    (ev) => {
      setAnswer(ev.currentTarget.value);
    },
    [setAnswer],
  );
  const handleSubmit = useCallback<
    (ev: React.FormEvent<HTMLFormElement>) => void
  >(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      if (answer) onSubmit(answer);
    },
    [onSubmit, answer],
  );
  useEffect(() => {
    setAnswer(_answer);
  }, [_answer, setAnswer]);

  return (
    <StyledShortResponsePanel width={dim(width)} minHeight={dim(height)}>
      <form onSubmit={handleSubmit}>
        <>
          <PanelCTA>{cta}</PanelCTA>
          {image}
          <PanelCaption>Short Response</PanelCaption>
          <Text mt="1" mb="0">
            {question}
          </Text>
          <Textarea
            aria-label="answer text"
            onChange={handleAnswerChange}
            value={answer}
          />
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Button2
              type="submit"
              disabled={!answer}
              palette={'blue'}
              variant={'secondary'}
            >
              Submit
            </Button2>
          </Box>
        </>
      </form>
    </StyledShortResponsePanel>
  );
};

export default ShortResponsePanel;
