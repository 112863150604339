import React, { ReactNode } from 'react';
import { isPortableText, PTBlocks } from '../../../types';
import { PortableText } from '@portabletext/react';
import { Maybe } from '../../../types/utils';
import { TextProps } from '../index';
import styled from '@emotion/styled';
import { down } from 'styled-breakpoints';
import { BaseTheme } from '../../../theme';
import { color, fontStyle, space, textAlign } from 'styled-system';
import { css } from '@emotion/react';

const textBase = ({ theme }: { theme: BaseTheme }) => css`
  font-family: ${theme.fonts['body']}, sans-serif;
  font-weight: ${theme.fontWeights['regular']};
  font-style: normal;
  padding: 0;
  margin: 0;
`;

export const Text = styled.p<TextProps>`
  ${textBase};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  ${down('mobile')} {
    font-size: ${({ theme }) => theme.fontSizes[1]};
  }
  line-height: ${({ theme }) => theme.lineHeights[4]};
  letter-spacing: ${({ theme }) => theme.letterSpacings[2]};
  color: ${({ theme }: { theme: BaseTheme }) =>
    theme.colors.grays['90']}; // TODO: see if this can be a themed gray

  ${fontStyle}
  ${color}
  ${textAlign}
  ${space}
`;

const components = {
  list: {
    // Ex. 1: customizing common list types
    bullet: ({ children }: { children?: ReactNode }) => <ul>{children}</ul>,
    number: ({ children }: { children?: ReactNode }) => <ol>{children}</ol>,

    // Ex. 2: rendering custom lists
    checkmarks: ({ children }: { children?: ReactNode }) => <ol>{children}</ol>,
  },
  listItem: {
    // Ex. 1: customizing common list types
    bullet: ({ children }: { children?: ReactNode }) => <li>{children}</li>,

    // Ex. 2: rendering custom list items
    checkmarks: ({ children }: { children?: ReactNode }) => <li>{children}</li>,
  },
};

export const RichText: React.FC<{ text?: Maybe<string | PTBlocks> }> = ({
  text,
}) => {
  return typeof text === 'string' ? (
    <Text>{text}</Text>
  ) : !!text && isPortableText(text) ? (
    <Text>
      <PortableText value={text} components={components} />
    </Text>
  ) : null;
};
