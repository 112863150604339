import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as LockedBadgeArt } from '../locked_badge.svg';
import { dim } from '../../../theme';

export const BADGE_THUMBNAIL_IMAGE_SIZE = { width: 105, height: 113 };

const StyledBadgeThumbnail = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${dim(BADGE_THUMBNAIL_IMAGE_SIZE.width)};
  height: ${dim(BADGE_THUMBNAIL_IMAGE_SIZE.height)};
  align-items: center;
  justify-content: center;
`;

export type BadgeThumbnailProps = {
  unlocked: boolean;
  children: React.ReactNode;
};

export const BadgeThumbnail: React.FC<BadgeThumbnailProps> = ({
  unlocked,
  children,
}) => {
  return (
    <StyledBadgeThumbnail>
      {unlocked && children}
      {!unlocked && (
        <LockedBadgeArt width="76" height="101" viewBox="0 0 76 101" />
      )}
    </StyledBadgeThumbnail>
  );
};
