import React from 'react';
import { CreatorData, CountryData, GenreData } from '../../../types';
import { Caption2 } from '../../typography';
import Box from '../../Box';
import Button2 from '../../inputs/Button2';
import { dim, ThemePalette } from '../../../theme';
import { down } from 'styled-breakpoints';
import styled from '@emotion/styled';

export type FeaturedArtInfoProps = {
  countries: CountryData[];
  genres: GenreData[];
  buttonPalette: ThemePalette;
  captionColor: string;
  onButtonClick: () => void;
  className?: string;
} & CreatorData;

export const FeaturedArtInfoTag = styled(Caption2)`
  font-size: ${dim(17)};
  font-weight: 500;
  line-height: ${dim(30)};

  ${down('mobile')} {
    font-size: ${dim(14)};
    font-weight: 500;
    line-height: ${dim(25)};
  }
`;

export const FeaturedArtInfoButton = styled(Button2)`
  ${down('mobile')} {
    span {
      font-size: ${dim(16)} !important;
    }
  }
`;

const _FeaturedArtInfo: React.FC<FeaturedArtInfoProps> = ({
  countries,
  genres,
  name,
  buttonPalette,
  captionColor,
  className,
  onButtonClick,
}) => {
  return (
    <Box className={className} px={[0, 3]}>
      <Box>
        <FeaturedArtInfoButton
          onClick={onButtonClick}
          palette={buttonPalette}
          size={'large'}
          variant="secondary"
        >
          Start Reading
        </FeaturedArtInfoButton>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="end">
        <FeaturedArtInfoTag bg={captionColor}>
          {countries.map((country) => country.name).join(', ')}
        </FeaturedArtInfoTag>
        <FeaturedArtInfoTag bg={captionColor}>
          {genres.map((genre) => genre.name).join(', ')}
        </FeaturedArtInfoTag>
        <FeaturedArtInfoTag bg={captionColor}>By: {name}</FeaturedArtInfoTag>
      </Box>
    </Box>
  );
};

export const FeaturedArtInfo = styled(_FeaturedArtInfo)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 1;
  top: ${dim(-32)};
`;
