import React from 'react';
import styled from '@emotion/styled';
import Box from '../../Box';
import { ImagePanelProps } from '../../../types';
import { dim } from '../../../theme';

export const StyledImagePanel = styled(Box)`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

export const ImagePanel: React.FC<ImagePanelProps> = ({
  width,
  height,
  image,
}) => {
  return (
    <StyledImagePanel width={dim(width)} minHeight={dim(height)}>
      <figure>{image}</figure>
    </StyledImagePanel>
  );
};

export default ImagePanel;
