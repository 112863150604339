import styled from '@emotion/styled';

export const Slider = styled.input<{
  variant?: string;
}>`
  -webkit-appearance: none;
  width: 100%;
  height: ${({ theme }) => theme.space[2]};
  border-color: ${({ theme }) => theme.colors.primary.regular};
  background-color: ${({ theme }) => theme.colors.primary.background};
  background-image: linear-gradient(
    ${({ theme }) => theme.colors.primary.highlight},
    ${({ theme }) => theme.colors.primary.highlight}
  );
  background-size: ${({ value, max }) =>
      value && max ? (Number(value) / Number(max)) * 100 : 0}%
    100%;
  background-repeat: no-repeat;
  border-radius: ${({ theme }) => theme.radii[1]};

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  &::-webkit-slider-thumb:hover {
    background: ${({ theme }) => theme.colors.primary.regular};
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: ${({ theme }) => theme.space[3]};
    width: ${({ theme }) => theme.space[2]};
    border-radius: 0;
    background: ${({ theme }) => theme.colors.primary.regular};
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 ${({ theme }) => theme.colors.primary.background};
    transition: background 0.3s ease-in-out;
  }
`;
Slider.defaultProps = {
  type: 'range',
};

export default Slider;
