import React from 'react';
import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import chromeClose from '@iconify/icons-codicon/chrome-close';
import Box from '../../Box';
import IconButton from '../../inputs/IconButton';
import { ProfileButton } from '../../page/PageHeader/ProfileButton/profile';
import { useTheme } from '@emotion/react';
import { dim } from '../../../theme';
import { SignupButton } from '../../page/PageHeader/ProfileButton';

const StyledMenuOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.white}e6;
  z-index: 20;
`;

const MenuOverlayHeader = styled(Box)`
  position: relative;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  top: 0;
  left: 0;
  right: 0;
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[2]};
  margin-bottom: ${dim(48)};
`;

export interface MenuOverlayProps {
  hasUser: boolean;
  onClose(): void;
  onProfileClick(): void;
  onSignupClick(): void;
}

export const MenuOverlay: React.FC<MenuOverlayProps> = ({
  children,
  hasUser,
  onClose,
  onProfileClick,
  onSignupClick,
}) => {
  const { colors } = useTheme();
  return (
    <StyledMenuOverlay>
      <MenuOverlayHeader>
        <IconButton onClick={onClose} aria-label="close menu">
          <Icon icon={chromeClose} color={colors.blues['60']} height="32" />
        </IconButton>
        {hasUser && (
          <ProfileButton onClick={onProfileClick} aria-label="open profile" />
        )}
        {!hasUser && (
          <SignupButton onClick={onSignupClick} aria-label="sign up" />
        )}
      </MenuOverlayHeader>
      {children}
    </StyledMenuOverlay>
  );
};

export default MenuOverlay;
