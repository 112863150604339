import Box from '../../Box';
import styled from '@emotion/styled';
import { dim } from '../../../theme';
import React, { useEffect } from 'react';

export interface MatchingQuestionItemProps {
  question: string;
  label: string;
  answer?: string;
  className?: string;
  onChange?: (value: string) => void;
}

const MatchingQuestionItemLabel = styled(Box)`
  flex: 1;
  margin-right: ${({ theme }) => theme.space[1]};
  padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[2]}
    ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
  border-radius: ${({ theme }) => theme.radii['md']};
  background-color: ${({ theme }) => theme.colors.grays['60']};
  font-family: ${({ theme }) => theme.fonts['body']};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${dim(14)};
`;

const MatchingQuestionItemInput = styled('input')`
  flex: 0;
  box-sizing: border-box;
  margin-left: ${({ theme }) => theme.space[1]};
  width: ${dim(46)};
  height: ${dim(46)};
  padding: 0;
  border-color: ${({ theme }) => theme.colors.blues['50']};
  border-radius: ${({ theme }) => theme.radii['md']};
  color: ${({ theme }) => theme.colors.blues['50']};
  font-size: ${dim(28)};
  font-family: ${({ theme }) => theme.fonts['body']};
  font-weight: 700;
  text-align: center;
  outline: none;
`;

const _MatchingQuestionItem: React.FC<MatchingQuestionItemProps> = ({
  question,
  label,
  answer,
  className,
  onChange,
}) => {
  const [value, setValue] = React.useState(answer || '');
  useEffect(() => {
    if (answer) {
      setValue((prev) => {
        return answer ?? prev;
      });
    }
  }, [answer, setValue]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _value = e.currentTarget.value.substring(0, 1);
    setValue(_value);
    onChange && onChange(_value);
  };
  return (
    <Box className={className}>
      <MatchingQuestionItemLabel>
        {label}. {question}
      </MatchingQuestionItemLabel>
      <MatchingQuestionItemInput
        value={value}
        maxLength={1}
        onChange={handleChange}
        aria-label={`Question ${label}`}
      />
    </Box>
  );
};

export const MatchingQuestionItem = styled(_MatchingQuestionItem)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin: ${({ theme }) => theme.space[2]} 0;
`;
