import styled from '@emotion/styled';
import { dim } from '../../../theme';

const _Checkbox: React.FC<React.ComponentPropsWithoutRef<'input'>> = (
  props,
) => {
  return <input type="checkbox" {...props} />;
};

export const Checkbox = styled(_Checkbox)`
  appearance: none;
  box-sizing: border-box;
  -webkit-appearance: none;
  font: inherit;
  color: currentColor;
  width: ${({ theme }) => theme.space[3]};
  height: ${({ theme }) => theme.space[3]};
  border: ${dim(2)} solid ${({ theme }) => theme.colors.black};
  border-radius: ${dim(2)};
  display: grid;
  place-content: center;

  &::before {
    content: '';
    width: ${({ theme }) => theme.space[2]};
    height: ${({ theme }) => theme.space[2]};
    transform: scale(0);
    box-shadow: inset ${({ theme }) => theme.space[3]}
      ${({ theme }) => theme.space[3]} ${({ theme }) => theme.colors.blues[50]};
    transform-origin: bottom left;
    clip-path: polygon(15% 44%, 0 65%, 40% 95%, 100% 26%, 80% 10%, 36% 62%);
  }

  &:checked {
    border: ${dim(2)} solid ${({ theme }) => theme.colors.blues[50]};
  }

  &:checked::before {
    transform: scale(1);
  }

  &:focus {
    border: ${dim(2)} solid ${({ theme }) => theme.colors.blues[50]};
  }
`;
