import styled from '@emotion/styled';
import { layout, LayoutProps, variant } from 'styled-system';
import { css } from '@emotion/react';

export const IconButton = styled.button<
  {
    variant?: string;
    color?: string;
  } & LayoutProps
>`
  display: flex;
  border-style: none;
  padding: 0;
  outline-style: none;
  color: ${({ color, theme, disabled }) =>
    color ??
    (disabled
      ? theme.colors.primary.disabled
      : theme.colors.primary.highlight)};
  background-color: transparent;
  ${({ theme }) =>
    variant({
      variants: {
        regular: css`
          color: ${theme.colors.primary.highlight};
          background-color: transparent;
        `,
        disabled: css`
          color: ${theme.colors.primary.disabled};
          background-color: transparent;
        `,
      },
    })}
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${layout}
`;

IconButton.defaultProps = {
  variant: 'regular',
};

export default IconButton;
