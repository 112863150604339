import React from 'react';
import styled from '@emotion/styled';
import { space, variant } from 'styled-system';
import { css } from '@emotion/react';
import {
  BaseTheme,
  getSizedStyle,
  makeSizesString,
  ThemeInputSize,
  ThemeElementState,
  ThemePalette,
  ThemeVariant,
} from '../../../theme';
import { ButtonText2 } from '../../typography';
import GenericButton from '../GenericButton';
import { ThemedButtonProps } from '../../../types';

const getVariantStyles = (
  theme: BaseTheme,
  palette: ThemePalette,
  variant: ThemeVariant,
  state?: ThemeElementState,
  size?: ThemeInputSize,
) => {
  const defaultShadow =
    variant === 'primary'
      ? css`
          box-shadow: ${makeSizesString([-2, 2, 0])}
              ${theme.colors.palettes[palette].button[variant][
                state || 'default'
              ].shadow},
            ${makeSizesString([0, 2, 8])} rgba(0, 0, 0, 0.14),
            ${makeSizesString([0, 2, 10])} rgba(0, 0, 0, 0.16);
        `
      : css`
          box-shadow: ${makeSizesString([0, 2, 8])} rgba(0, 0, 0, 0.14),
            ${makeSizesString([0, 2, 10])} rgba(0, 0, 0, 0.16);
        `;

  const hoverShadow =
    variant === 'primary'
      ? css`
          box-shadow: ${makeSizesString([-2, 4, 0])}
              ${theme.colors.palettes[palette].button[variant][state || 'hover']
                .shadow},
            ${makeSizesString([0, 6, 8])} rgba(0, 0, 0, 0.08),
            ${makeSizesString([0, 4, 10])} rgba(0, 0, 0, 0.1);
        `
      : css`
          box-shadow: ${makeSizesString([0, 6, 8])} rgba(0, 0, 0, 0.08),
            ${makeSizesString([0, 4, 10])} rgba(0, 0, 0, 0.1);
        `;
  return css`
    padding: ${getSizedStyle({ small: [4, 10], large: [10, 16] }, size)};
    height: ${getSizedStyle({ small: 29, large: 44 }, size)};
    border-radius: ${getSizedStyle({ small: 65, large: 65 }, size)};
    border-width: ${getSizedStyle({ small: 2, large: 4 }, size)};
    border-style: solid;

    margin: ${makeSizesString([2, 0, 2, 2])};
    color: ${theme.colors.palettes[palette].button[variant][state || 'default']
      .text};
    background-color: ${theme.colors.palettes[palette].button[variant][
      state || 'default'
    ].background};
    ${defaultShadow};
    border-color: ${theme.colors.palettes[palette].button[variant][
      state || 'default'
    ].border};

    &:hover,
    &:active.hover,
    &.hover {
      margin: ${makeSizesString([0, 0, 4, 2])};
      color: ${theme.colors.palettes[palette].button[variant][state || 'hover']
        .text};
      background-color: ${theme.colors.palettes[palette].button[variant][
        state || 'hover'
      ].background};
      ${hoverShadow};
      border-color: ${theme.colors.palettes[palette].button[variant][
        state || 'hover'
      ].border};
    }

    &:active,
    &.down {
      margin: ${makeSizesString([4, 2, 0, 0])};
      color: ${theme.colors.palettes[palette].button[variant][state || 'down']
        .text};
      background-color: ${theme.colors.palettes[palette].button[variant][
        state || 'down'
      ].background};
      box-shadow: none;
      border-color: ${theme.colors.palettes[palette].button[variant][
        state || 'down'
      ].border};
    }

    &:disabled,
    &.disabled {
      margin: ${makeSizesString([4, 2, 0, 0])};
      color: ${theme.colors.palettes[palette].button[variant][
        state || 'disabled'
      ].text};
      background-color: ${theme.colors.palettes[palette].button[variant][
        state || 'disabled'
      ].background};
      box-shadow: none;
      border-color: ${theme.colors.palettes[palette].button[variant][
        state || 'disabled'
      ].border};
    }
  `;
};

export const StyledButton2 = styled.button<{
  variant?: ThemeVariant;
  palette: ThemePalette;
  state?: ThemeElementState;
  size?: ThemeInputSize;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  font-family: '${({ theme }) => theme.fonts['body']}', serif;
  outline: none;
  ${({ theme, palette, state, size }) => {
    return variant({
      variants: {
        primary: getVariantStyles(theme, palette, 'primary', state, size),
        secondary: getVariantStyles(theme, palette, 'secondary', state, size),
      },
    });
  }}
  ${space}
`;

export const Button2: React.FC<ThemedButtonProps> = (props) => {
  const { children, ...genericProps } = props;
  const { iconLeft, iconRight, ...styleProps } = genericProps;
  return (
    <GenericButton
      {...genericProps}
      StyledButton={
        <StyledButton2
          className={props.state}
          {...{ ...styleProps, variant: styleProps.variant || 'primary' }}
        />
      }
      StyledText={<ButtonText2 size={props.size}></ButtonText2>}
    >
      {children}
    </GenericButton>
  );
};

export default Button2;
