import { ReactComponent as GearComponent } from './gear.svg';
import styled from '@emotion/styled';
import Box from '../../../components/Box';
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from 'styled-system';
import { useTheme } from '@emotion/react';
import { BaseTheme } from '../../index';

const StyledGearComponent = styled(Box)<SpaceProps & LayoutProps & ColorProps>`
  display: inline-flex;
  svg {
    path {
      fill: ${({ color }) => color} !important;
    }
  }
  ${space}
  ${layout}
  ${color}
`;

export const Gear: React.FC<
  {
    width?: number | string;
    height?: number | string;
    label?: string;
    onClick?: () => void;
  } & SpaceProps &
    LayoutProps &
    ColorProps
> = (props) => {
  const { width = 24, height = 25, color, label, onClick, ...rest } = props;
  const {
    colors: { blues },
  } = useTheme() as BaseTheme;
  return (
    <StyledGearComponent
      color={color || blues['60]']}
      onClick={onClick}
      {...rest}
    >
      <GearComponent
        width={width}
        height={height}
        viewBox="0 0 24 25"
        aria-label={label}
      />
    </StyledGearComponent>
  );
};
