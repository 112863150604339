import React, { useCallback, useEffect, useState } from 'react';
import DrawingArea, { DRAWING_AREA_SIZE } from './DrawingArea';
import styled from '@emotion/styled';
import { Text } from '../../typography';
import Button2 from '../../inputs/Button2';
import { dim } from '../../../theme';
import Box from '../../Box';
import { DrawingAreaLine, DrawingPanelProps } from '../../../types';
import { PanelCTA } from '../PanelCTA';
import { down } from 'styled-breakpoints';

const StyledDrawingPanel = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${dim(56)} 0 ${({ theme }) => theme.space[4]};
  ${down('mobile')} {
    padding: ${dim(40)} 0 ${({ theme }) => theme.space[3]};
  }
`;

export const DrawingPanel: React.FC<DrawingPanelProps> = ({
  src,
  width,
  height,
  alt,
  cta,
  question,
  lines: _lines,
  onDraw,
  onSubmit,
}) => {
  const [isWarning, setIsWarning] = useState(false);
  const [lines, setLines] = useState<DrawingAreaLine[]>(_lines || []);

  useEffect(() => {
    setLines(_lines || []);
  }, [_lines]);

  const handleDraw = useCallback(
    (lines: DrawingAreaLine[]) => {
      setIsWarning(false);
      onDraw(lines);
      setLines(lines);
    },
    [onDraw],
  );
  const handleWarn = useCallback(() => {
    setIsWarning(true);
  }, [setIsWarning]);
  const handleSubmit = useCallback(() => {
    onSubmit(lines);
  }, [lines, onSubmit]);
  return (
    <StyledDrawingPanel
      width={dim(width || DRAWING_AREA_SIZE.width)}
      minHeight={dim(height || DRAWING_AREA_SIZE.height)}
    >
      <PanelCTA>{cta}</PanelCTA>
      <DrawingArea
        width={width}
        height={height}
        src={src}
        alt={alt}
        lines={lines}
        onWarn={handleWarn}
        onDraw={handleDraw}
      />
      <Box display="flex" flexDirection="row" justifyContent="flex-end" mt="3">
        <Button2
          type="button"
          disabled={lines?.length === 0 || isWarning}
          onClick={handleSubmit}
          palette={'blue'}
          variant={'secondary'}
        >
          Done
        </Button2>
      </Box>
      <Text mt="3" mb="0">
        {isWarning
          ? 'You are out of "ink"! Try hitting the "undo" button to clear away lines you do not need.'
          : question}
      </Text>
    </StyledDrawingPanel>
  );
};

export default DrawingPanel;
