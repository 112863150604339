import styled from '@emotion/styled';
import { dim } from '../../../theme';

export const StyledProfileSettings = styled.div`
  display: flex;
  flex-direction: column;
  width: ${dim(343)};
`;

export const ProfileDataSettingsReadingExperience = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
`;
