import styled from '@emotion/styled';
import { dim, makeSizesString } from '../../../theme';
import { margin, MarginProps, textAlign, TextAlignProps } from 'styled-system';
import { down } from 'styled-breakpoints';
import Box from '../../Box';
import { Header4, Text } from '../../typography';
import { BadgeThumbnail } from '../../badge';

export type BadgeDisplayProps = {
  title: string;
  storyTitle: string;
  image: JSX.Element;
  className?: string;
};

export const BadgeDisplayMedia = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const BadgeDisplayContentContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: skewX(-0.286051442rad);
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]}
    ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
  min-width: ${dim(260)};
  min-height: ${dim(60)};
  width: ${dim(260)};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${makeSizesString([-2, 1, 0])}
    ${({ theme }) =>
      theme.colors.palettes['blue'].button['primary']['default'].shadow};
  border: ${dim(2)} solid
    ${({ theme }) =>
      theme.colors.palettes['blue'].button['primary']['default'].border};

  ${down('mobile')} {
    width: 100%;
    min-width: unset;
  }
`;

export const BadgeDisplayContent = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: skewX(0.286051442rad);
  width: 100%;
`;

export const BadgeDisplayTitle = styled(Header4)`
  margin: 0 0 ${({ theme }) => theme.space[1]};
  font-size: ${dim(24)};
  font-weight: 700;
  text-align: center;
  ${down('mobile')} {
    font-size: ${dim(20)};
  }
`;

export const BadgeDisplayStoryTitle = styled(Text)`
  margin: 0;
  font-size: ${dim(10)};
  font-weight: ${({ theme }) => theme.fontWeights['regular']};
  text-align: center;
  ${down('mobile')} {
    font-size: ${dim(14)};
  }
`;

export const BadgeDisplayText = styled(Text)<TextAlignProps & MarginProps>`
  font-size: ${dim(14)};
  font-weight: ${({ theme }) => theme.fontWeights['regular']};
  margin: ${({ theme }) => theme.space[3]} 0 ${({ theme }) => theme.space[2]};
  ${margin}
  ${textAlign}
`;

const _BadgeDisplay = ({
  title,
  storyTitle,
  image,
  className,
}: BadgeDisplayProps) => {
  return (
    <Box className={className}>
      <BadgeDisplayMedia>
        <BadgeThumbnail unlocked>{image}</BadgeThumbnail>
        <Box display="flex" flexDirection="column" justifyContent="flex-start">
          <BadgeDisplayText textAlign="left" ml="3">
            You Unlocked a Badge!
          </BadgeDisplayText>
          <BadgeDisplayContentContainer>
            <BadgeDisplayContent>
              <BadgeDisplayTitle>{title}</BadgeDisplayTitle>
              <BadgeDisplayStoryTitle>
                Read all of "{storyTitle}"
              </BadgeDisplayStoryTitle>
            </BadgeDisplayContent>
          </BadgeDisplayContentContainer>
        </Box>
      </BadgeDisplayMedia>
      <BadgeDisplayText textAlign="center">
        You can find this badge in your collection in your profile!
      </BadgeDisplayText>
    </Box>
  );
};

export const BadgeDisplay = styled(_BadgeDisplay)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 ${({ theme }) => theme.space[3]};
  margin: 0 auto;
  max-width: ${dim(444)};
  ${down('mobile')} {
    max-width: 100%;
  }}
`;
