import { Maybe } from '@astc/frontend/graphql-types';

export const themeVariants = ['primary', 'secondary'] as const;
export type ThemeVariant = typeof themeVariants[number];
export const themeElements = ['default', 'button', 'input'] as const;
export type ThemeElement = typeof themeElements[number];
export const themeElementStates = [
  'default',
  'hover',
  'down',
  'disabled',
] as const;
export type ThemeElementState = typeof themeElementStates[number];
export const themeElementStateProperties = [
  'background',
  'border',
  'shadow',
  'text',
] as const;
export type ThemeElementStateProperty =
  typeof themeElementStateProperties[number];
export type ThemeElementPaletteVariantState = Record<
  ThemeElementState,
  Record<ThemeElementStateProperty, string & {}>
>;
export type ThemeElementPaletteVariant = Record<
  ThemeVariant,
  ThemeElementPaletteVariantState
>;
export type ThemeElementPalette = Record<
  ThemeElement,
  ThemeElementPaletteVariant
>;
export const themePalettes = ['blue', 'gray', 'green', 'red'] as const;
export type ThemePalette = typeof themePalettes[number];
const readOnlyincludes = <T extends U, U>(
  coll: ReadonlyArray<T>,
  el: U,
): el is T => coll.includes(el as T);
export const isThemePalette = (
  palette: Maybe<string> | undefined,
): palette is ThemePalette =>
  !!palette && readOnlyincludes(themePalettes, palette);
