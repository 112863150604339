import React from 'react';
import styled from '@emotion/styled';
import { Header3 } from '../../typography';

const StyledMenuItem = styled(Header3)`
  color: ${({ theme }) => theme.colors.blues['60']};
  margin: 0 ${({ theme }) => theme.fontSizes[1]}
    ${({ theme }) => theme.space[3]};
  width: 100%;
  & a,
  a:visited,
  a:hover,
  a:active,
  a:focus {
    color: ${({ theme }) => theme.colors.blues['60']};
    text-decoration: none;
  }
`;

export const MenuItem: React.FC<{}> = ({ children }) => {
  return <StyledMenuItem>{children}</StyledMenuItem>;
};

export default MenuItem;
