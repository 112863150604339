import Box from '../../Box';
import styled from '@emotion/styled';
import React, { ReactElement } from 'react';
import { dim } from '../../../theme';
import { Text } from '../../typography';
import { down } from 'styled-breakpoints';

export interface DetailDropDownEntryProps {
  onClick(ev?: React.MouseEvent<HTMLParagraphElement>): void;
  label: string;
  className?: string;
}

export const _DetailDropDownEntry: React.FC<DetailDropDownEntryProps> = ({
  onClick,
  label,
  className,
}) => {
  return (
    <Text onClick={onClick} aria-label={label} className={className}>
      {label}
    </Text>
  );
};

export const DetailDropDownEntry = styled(_DetailDropDownEntry)`
  display: flex;
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[3]};
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.blues[60]};
  }
  &:first-of-type {
    border-radius: ${dim(12)} ${dim(12)} 0 0;
  }
  &:last-of-type {
    border-radius: 0 0 ${dim(12)} ${dim(12)};
  }
`;

export interface DetailDropDownProps {
  inset: number;
  children:
    | ReactElement<typeof DetailDropDownEntry>
    | ReactElement<typeof DetailDropDownEntry>[];
  className?: string;
}

const DetailDropDownContainer = styled(Box)<{ inset: number }>`
  position: absolute;
  top: ${dim(4)};
  right: ${({ inset }) => dim(-173 + inset)};
  min-width: ${dim(173)};
  filter: drop-shadow(0 ${dim(4)} ${dim(4)} rgba(0, 0, 0, 0.25));
  border-radius: ${dim(12)};
  ${down('mobile')} {
    right: 0;
  }
`;

const _DetailDropDown: React.FC<DetailDropDownProps> = ({
  className,
  inset,
  children,
}) => {
  return (
    <Box className={className}>
      <DetailDropDownContainer inset={inset}>
        {children}
      </DetailDropDownContainer>
    </Box>
  );
};

export const DetailDropDown = styled(_DetailDropDown)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;
