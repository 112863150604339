import { ReactElement, ReactNode } from 'react';

export const isReactElementWithKey = (
  comp: ReactNode,
): comp is ReactElement => {
  if (comp && typeof comp === 'object') {
    return comp.hasOwnProperty('key');
  }
  return false;
};

interface PlainObject {
  hasOwnProperty<K extends string>(key: K): this is Record<K, unknown>;

  hasOwn<K extends string>(key: K): this is Record<K, unknown>;
}

export function isPlainObject(value: unknown): value is PlainObject {
  return !!value && typeof value === 'object' && !Array.isArray(value);
}

export type Maybe<T> = T | null;
