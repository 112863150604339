import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Header4, Text } from '../typography';
import { Button2, Textarea } from '../inputs';
import Box from '../Box';
import { StoryRoundedBox } from '../RoundedBox';
import { up } from 'styled-breakpoints';

const StyledShareFeedback = styled(StoryRoundedBox)`
  display: flex;
  flex-direction: column;

  ${up('mobile')} {
    width: 100%;
  }
`;

export interface ShareFeedbackProps {
  onSubmit(message: string): void;
}

export const ShareFeedback: React.FC<ShareFeedbackProps> = ({ onSubmit }) => {
  const [message, setMessage] = useState<string>('');
  const handleMessageChange = (ev: React.FormEvent<HTMLTextAreaElement>) => {
    setMessage(ev.currentTarget.value);
  };
  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (message) onSubmit(message);
  };
  return (
    <StyledShareFeedback>
      <form onSubmit={handleSubmit} role="form">
        <Header4>Share your feedback!</Header4>
        <Text>Lorem ipsum dolar sit amet sin alem consicor.</Text>
        <Textarea
          aria-label="feedback text"
          onChange={handleMessageChange}
          value={message}
        />
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Button2
            type="submit"
            disabled={!message}
            palette="blue"
            variant={'secondary'}
            style={{ alignSelf: 'flex-end' }}
          >
            Submit
          </Button2>
        </Box>
      </form>
    </StyledShareFeedback>
  );
};

export default ShareFeedback;
