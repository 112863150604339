import React from 'react';
import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import playFill from '@iconify/icons-bi/play-fill';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
import { dim } from '../../../theme';
import { Caption2, Header6 } from '../../typography';
import { StoryIntroData } from '../../../types';
import { useTheme } from '@emotion/react';
import IconButton from '../../inputs/IconButton';
import Box from '../../Box';

export type ReadingListStoryProps = Omit<
  StoryIntroData,
  'genres' | 'country' | 'creators' | 'prologue' | 'images' | 'characters'
> & {
  isRead: boolean;
  tags: string[];
  image: React.ReactNode;
  onClick(): void;
};

const StyledReadingListStory = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.space[4]};
  cursor: pointer;
`;

const READING_LIST_STORY_IMAGE = {
  width: 100,
  height: 100,
};

const READING_LIST_STORY_CHECK_SIZE = {
  width: 34,
  height: 34,
};

const ReadingListStoryImage = styled.div`
  position: relative;
  display: flex;
  flex-grow: 0;
  width: ${dim(READING_LIST_STORY_IMAGE.width)};
  height: ${dim(READING_LIST_STORY_IMAGE.height)};
  justify-content: center;
  align-items: center;
`;

const ReadingListStoryFade = styled(Box)`
  position: absolute;
  display: flex;
  flex-grow: 0;
  width: ${dim(READING_LIST_STORY_IMAGE.width)};
  height: ${dim(READING_LIST_STORY_IMAGE.height)};
  background-color: ${({ theme }) => theme.colors.black}73;
  justify-content: center;
  align-items: center;
  font-size: ${dim(28)};
  z-index: 10;
`;

const ReadingListStoryCheck = styled(Icon)`
  position: absolute;
  display: flex;
  flex-grow: 0;
  width: ${dim(READING_LIST_STORY_CHECK_SIZE.width)};
  height: ${dim(READING_LIST_STORY_CHECK_SIZE.height)};
  color: ${({ theme }) => theme.colors['list-yellow']};
  justify-content: center;
  align-items: center;
  font-size: ${dim(28)};
  z-index: 10;
`;

const ReadingListStoryDetail = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: ${({ theme }) => theme.space[3]};
`;

const ReadingListStoryTags = styled(Caption2)`
  display: inline;
  background: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.colors.grays['50']};
  font-weight: ${({ theme }) => theme.fontWeights['medium']};
`;

export const ReadingListStory: React.FC<ReadingListStoryProps> = ({
  isRead,
  tags,
  title,
  image,
  onClick,
}) => {
  const { colors } = useTheme();

  return (
    <StyledReadingListStory onClick={onClick}>
      <ReadingListStoryImage
        role="status"
        aria-label={`the user has ${isRead ? '' : 'not '}read this story`}
      >
        {image}
        {isRead && (
          <ReadingListStoryFade>
            <ReadingListStoryCheck icon={checkmarkFill} height={28} />
          </ReadingListStoryFade>
        )}
      </ReadingListStoryImage>
      <ReadingListStoryDetail>
        <ReadingListStoryTags as="p">{tags.join(', ')}</ReadingListStoryTags>
        <Header6>{title}</Header6>
      </ReadingListStoryDetail>
      <IconButton
        onClick={(event) => {
          //TODO: make sure this is the correct action for the button
          onClick();
          event.stopPropagation();
        }}
      >
        <Icon icon={playFill} width={32} color={colors.blues['60']} />
      </IconButton>
    </StyledReadingListStory>
  );
};

export default ReadingListStory;
