import styled from '@emotion/styled';
import { dim } from '../../../theme';

export const SignInStepBoundary = styled.div`
  display: flex;
  flex-direction: column;
  width: ${dim(345)};
`;

export const SignInStepForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: ${dim(32)} 0 ${({ theme }) => theme.space[3]};
`;
