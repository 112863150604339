import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Box from '../../Box';
import { Text } from '../../typography';
import Textarea from '../../inputs/Textarea';
import Button2 from '../../inputs/Button2';
import { TimelinePanelProps } from '../../../types';
import { Slider } from '../../inputs';
import { dim } from '../../../theme';
import { PanelCTA } from '../PanelCTA';
import { down } from 'styled-breakpoints';

const StyledTimelinePanel = styled(Box)`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: ${dim(56)} 0 ${({ theme }) => theme.space[4]};
  ${down('mobile')} {
    padding: ${dim(40)} 0 ${({ theme }) => theme.space[3]};
  }
`;

export const TimelinePanel: React.FC<TimelinePanelProps> = ({
  width,
  height,
  cta,
  question,
  answer: _answer,
  image,
  onSubmit,
  onTimelineChange,
}) => {
  const [timelineValue, setTimelineValue] = useState(0);

  const [answer, setAnswer] = useState<string>(_answer || '');

  const handleTimelineChange = useCallback(
    (ev) => {
      const value = ev.target.value;
      setTimelineValue(value);
      onTimelineChange(Number(value));
    },
    [onTimelineChange],
  );

  const handleAnswerChange = useCallback<
    (ev: React.FormEvent<HTMLTextAreaElement>) => void
  >(
    (ev) => {
      setAnswer(ev.currentTarget.value);
    },
    [setAnswer],
  );
  const handleSubmit = useCallback<
    (ev: React.FormEvent<HTMLFormElement>) => void
  >(
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      if (answer) onSubmit(answer);
    },
    [onSubmit, answer],
  );

  useEffect(() => {
    setAnswer(_answer || '');
  }, [_answer]);

  return (
    <StyledTimelinePanel width={dim(width)} minHeight={dim(height)}>
      <form onSubmit={handleSubmit}>
        <PanelCTA>{cta}</PanelCTA>
        {image}
        <Slider
          value={timelineValue}
          onChange={handleTimelineChange}
          aria-label="Slider to control the visual timeline"
        />
        <Text mt={4}>{question}</Text>
        <Textarea
          aria-label="answer text"
          onChange={handleAnswerChange}
          value={answer}
        />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          mt="3"
        >
          <Button2
            type="submit"
            disabled={!answer}
            palette={'blue'}
            variant={'secondary'}
          >
            Done
          </Button2>
        </Box>
      </form>
    </StyledTimelinePanel>
  );
};

export default TimelinePanel;
