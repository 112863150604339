import React from 'react';
import { PTBlock } from '../../../types';
import { RoundedBox } from '../../RoundedBox';
import { Header4, Text } from '../../typography';
import { RichText } from '../../typography/RichText';
import styled from '@emotion/styled';
import { dim } from '../../../theme';
import { down } from 'styled-breakpoints';
import { Maybe } from '../../../types/utils';

const _StoryInfoContentSummary: React.FC<{
  text: string | Maybe<PTBlock>[] | null | undefined;
  className?: string;
}> = ({ text, className }) => (
  <RoundedBox className={className}>
    <Header4 mb="3" fontWeight="500" fontSize={[dim(36), dim(36)]}>
      Story Summary
    </Header4>
    <Text>
      <RichText text={text} />
    </Text>
  </RoundedBox>
);

export const StoryInfoContentSummary = styled(_StoryInfoContentSummary)`
  margin-top: ${dim(43)};
  padding: ${dim(37)} ${dim(24)} ${({ theme }) => theme.space[4]};
  ${down('mobile')} {
    padding-bottom: ${({ theme }) => theme.space[3]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
    border-radius: 0;
    margin-bottom: ${({ theme }) => theme.space[3]};
  }
  p > p {
    margin-bottom: ${dim(12)};
  }
`;
