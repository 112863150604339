import styled from '@emotion/styled';
import { Caption2, Header6 } from '../../typography';
import React from 'react';
import Box from '../../Box';
import { BaseTheme, dim, pluralize } from '../../../theme';
import { useTheme } from '@emotion/react';
import { Checkbox } from '../../inputs/Checkbox';
import { ReadingListEntry } from '../../../types';

const ReadingListEntryInputControl = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ReadingListEntryInputCheckbox = styled(Checkbox)`
  margin-top: ${dim(24)};
  margin-left: ${dim(6)};
  margin-right: ${({ theme }) => theme.space[4]};
`;

const ReadingListEntryInputLabel = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

type ReadingListEntryInputProps = ReadingListEntry & {
  onSelect(value: boolean): void;
  className?: string;
};

export const _ReadingListEntryInput: React.FC<ReadingListEntryInputProps> = ({
  id,
  name,
  length,
  selected,
  onSelect,
  className,
}) => {
  const {
    colors: { grays },
  } = useTheme() as BaseTheme;
  const [checked, setChecked] = React.useState(selected);
  const handleSelect = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = ev.target.checked;
    setChecked(isChecked);
    onSelect(isChecked);
  };
  return (
    <div className={className}>
      <ReadingListEntryInputControl>
        <ReadingListEntryInputCheckbox
          type={'checkbox'}
          onChange={handleSelect}
          checked={checked}
        />
      </ReadingListEntryInputControl>
      <ReadingListEntryInputLabel>
        <Caption2 variant="plain" color={grays['50']}>
          {length} {pluralize(length, 'comic')}
        </Caption2>
        <Header6 key={id}>{name}</Header6>
      </ReadingListEntryInputLabel>
    </div>
  );
};

export const ReadingListEntryInput = styled(_ReadingListEntryInput)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: ${dim(24)};
`;
