import styled from '@emotion/styled';
import { variant } from 'styled-system';
import { css } from '@emotion/react';
import {
  BaseTheme,
  dim,
  getSizedStyle,
  makeSizesString,
  ThemeInputSize,
} from '../../../theme';
import React from 'react';
import GenericButton from '../GenericButton';
import { ButtonText2 } from '../../typography';
import { ThemedButtonProps } from '../../../types';
import { ThemeElementState, ThemePalette, ThemeVariant } from '../../../theme';

const getVariantStyles = (
  theme: BaseTheme,
  palette: ThemePalette,
  variant: ThemeVariant,
  state?: ThemeElementState,
  size?: ThemeInputSize,
) => {
  return css`
    padding: ${getSizedStyle({ small: [6, 12], large: [8, 14] }, size)};
    height: ${getSizedStyle({ small: 28, large: 35 }, size)};

    margin: ${makeSizesString([3, 5, 1, 2])};
    color: ${theme.colors.palettes[palette].button[variant][state || 'default']
      .text};
    background-color: ${theme.colors.palettes[palette].button[variant][
      state || 'default'
    ].background};
    box-shadow: ${makeSizesString([-2, 1, 0])}
      ${theme.colors.palettes[palette].button[variant][state || 'default']
        .shadow};
    border: ${dim(2)} solid
      ${theme.colors.palettes[palette].button[variant][state || 'default']
        .border};

    &:hover,
    &:active.hover,
    &.hover {
      margin: ${makeSizesString([0, 0, 4, 5])};
      color: ${theme.colors.palettes[palette].button[variant][state || 'hover']
        .text};
      background-color: ${theme.colors.palettes[palette].button[variant][
        state || 'hover'
      ].background};
      box-shadow: ${makeSizesString([-4, 4, 0])}
        ${theme.colors.palettes[palette].button[variant][state || 'hover']
          .shadow};
      border: ${dim(3)} solid
        ${theme.colors.palettes[palette].button[variant][state || 'hover']
          .border};
    }

    &:active,
    &.down {
      margin: ${makeSizesString([4, 7, 0, 0])};
      color: ${theme.colors.palettes[palette].button[variant][state || 'down']
        .text};
      background-color: ${theme.colors.palettes[palette].button[variant][
        state || 'down'
      ].background};
      box-shadow: none;
      border: ${dim(2)} solid
        ${theme.colors.palettes[palette].button[variant][state || 'down']
          .border};
    }

    &:disabled,
    &.disabled {
      margin: ${makeSizesString([4, 7, 0, 0])};
      color: ${theme.colors.palettes[palette].button[variant][
        state || 'disabled'
      ].text};
      background-color: ${theme.colors.palettes[palette].button[variant][
        state || 'disabled'
      ].background};
      box-shadow: none;
      border: ${dim(2)} solid
        ${theme.colors.palettes[palette].button[variant][state || 'disabled']
          .border};
    }
  `;
};

export const StyledButton1 = styled.button<{
  variant?: ThemeVariant;
  disabled?: boolean;
  palette: ThemePalette;
  state?: ThemeElementState;
  size?: ThemeInputSize;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: skewX(-0.286051442rad);
  width: auto;

  border-width: ${dim(2)};
  border-style: solid;
  font-family: '${({ theme }) => theme.fonts['body']}', serif;
  outline: none;
  ${({ theme, palette, state, size }) => {
    return variant({
      variants: {
        primary: getVariantStyles(theme, palette, 'primary', state, size),
        secondary: getVariantStyles(theme, palette, 'secondary', state, size),
      },
    });
  }}
`;

export const Button1: React.FC<ThemedButtonProps> = (props) => {
  const { children, ...genericProps } = props;
  const { iconLeft, iconRight, ...styleProps } = genericProps;
  return (
    <GenericButton
      {...genericProps}
      StyledButton={
        <StyledButton1
          className={props.state}
          {...{ ...styleProps, variant: styleProps.variant || 'primary' }}
        ></StyledButton1>
      }
      StyledText={
        <ButtonText2
          sizeOverride={props.size === 'small' ? dim(12) : undefined}
        ></ButtonText2>
      }
      isSkewed={true}
    >
      {children}
    </GenericButton>
  );
};

export default Button1;
