import styled from '@emotion/styled';
import Box from '../../Box';
import { BaseTheme, dim } from '../../../theme';
import { Header5 } from '../../typography';
import React, { ReactElement } from 'react';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';
import { css } from '@emotion/react';
import { down } from 'styled-breakpoints';

export type IndexHeaderLinkProps = {
  className?: string;
  active?: boolean;
  children?: React.ReactNode;
};

export const _IndexHeaderLink: React.FC<IndexHeaderLinkProps> = ({
  className,
  children,
}) => {
  return <Header5 className={className}>{children}</Header5>;
};

export const IndexHeaderLink = styled(_IndexHeaderLink)<
  LayoutProps & SpaceProps
>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 ${dim(21)} 0;
  margin: 0 ${dim(48)} 0 0;
  & a {
    color: ${({ theme }) => (theme as BaseTheme).colors.black}6C;
    white-space: nowrap;
    text-decoration: none;
  }
  ${down('mobile')} {
    margin: 0 ${dim(12)} 0 0;
  }
  ${({ active, theme }) => {
    if (active) {
      return css`
        color: ${(theme as BaseTheme).colors.blues[60]};
        padding: 0 0 ${(theme as BaseTheme).space[3]} 0;
        border-bottom: ${dim(5)} solid ${(theme as BaseTheme).colors.blues[60]};

        & a {
          color: ${(theme as BaseTheme).colors.blues[60]};
          text-decoration: none;
        }
      `;
    } else {
      return css`
        &:hover {
          padding: 0 0 ${(theme as BaseTheme).space[3]} 0;
          border-bottom: ${dim(5)} solid
            ${(theme as BaseTheme).colors.blues[60]};
        }
        &:hover a {
          color: ${(theme as BaseTheme).colors.blues[60]};
          text-decoration: none;
        }
      `;
    }
  }}
  ${layout}
  ${space}
`;

export type IndexHeaderProps = {
  className?: string;
  children?:
    | ReactElement<typeof IndexHeaderLink>
    | ReactElement<typeof IndexHeaderLink>[];
};

export const _IndexHeader: React.FC<IndexHeaderProps> = ({
  className,
  children,
}) => {
  return <Box className={className}>{children}</Box>;
};

export const IndexHeader = styled(_IndexHeader)<LayoutProps & SpaceProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  ${layout}
  ${space}
`;
