import React from 'react';
import { ReactComponent as BookComponent } from './book.svg';
import styled from '@emotion/styled';
import {
  background,
  BackgroundProps,
  layout,
  LayoutProps,
} from 'styled-system';
import { dim } from '../../../theme';
import Box from '../../Box';
import { ReactComponent as UpperFrame } from './upper-frame.svg';
import { ReactComponent as LowerFrame } from './lower-frame.svg';
import { Maybe } from '../../../types/utils';

export const READING_LISTS_ITEM_THUMBNAIL_SIZE = {
  width: 109,
  height: 163,
};

const StyledReadingListsItemThumbnail = styled.div<
  BackgroundProps & LayoutProps
>`
  display: flex;
  flex-grow: 0;
  position: relative;
  background: no-repeat center;
  background-size: cover;
  width: ${dim(135)};
  height: ${dim(184)};
  ${background}
  ${layout};
`;

const ReadingListsItemUpperFrame = styled(Box)<{ frameColor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 118px;
  height: 160px;
  & svg {
    position: absolute;
    g {
      path {
        stroke: ${({ frameColor }) => frameColor} !important;
      }
    }
  }
`;

const ReadingListsItemImageFrame = styled(Box)`
  position: absolute;
  top: 12px;
  left: 20px;
  width: ${dim(READING_LISTS_ITEM_THUMBNAIL_SIZE.width)};
  height: ${dim(READING_LISTS_ITEM_THUMBNAIL_SIZE.height)};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const ReadingListsItemLowerFrame = styled(Box)<{ frameColor: string }>`
  position: absolute;
  top: 5px;
  left: 3px;
  width: 117px;
  height: 158px;

  & svg {
    position: absolute;
    g {
      path {
        stroke: ${({ frameColor }) => frameColor} !important;
      }
    }
  }
`;

export const ReadingListsItemRead = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${dim(4)} ${dim(12)};

  position: absolute;
  width: ${dim(44)};
  height: ${dim(44)};
  left: ${dim(102)};
  top: ${dim(140)};

  background: ${({ theme }) => theme.colors.white};
  border: ${dim(2)} solid ${({ theme }) => theme.colors.black};

  font-family: ${({ theme }) => theme.fonts['body']};
  font-weight: ${({ theme }) => theme.fontWeights['bold']};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  line-height: 0.885;

  /* Comic Shadow Black */
  box-shadow: ${dim(-1)} ${dim(2)} 0 ${({ theme }) => theme.colors.black};
  border-radius: ${dim(5)};
`;

const BlankReadingListsItemThumbnail = styled.div`
  display: flex;
  position: relative;
  background-image: linear-gradient(
    0deg,
    ${({ theme }) => theme.colors.grays[10]} 0%,
    ${({ theme }) => theme.colors.grays[20]} 100%
  );
  min-width: 100%;
  min-height: 100%;
`;

export const ReadingListsItemThumbnail: React.FC<{
  total: number;
  frameColor: string;
  readingListImage?: Maybe<JSX.Element>;
}> = ({ total, frameColor, readingListImage }) => {
  return (
    <StyledReadingListsItemThumbnail>
      <ReadingListsItemLowerFrame frameColor={frameColor}>
        <LowerFrame width={129} height={169} viewBox="0 0 129 169" />
      </ReadingListsItemLowerFrame>
      <ReadingListsItemImageFrame>
        {readingListImage ?? <BlankReadingListsItemThumbnail />}
      </ReadingListsItemImageFrame>
      <ReadingListsItemUpperFrame frameColor={frameColor}>
        <UpperFrame width={132} height={174} viewBox="0 0 132 174" />
      </ReadingListsItemUpperFrame>
      <ReadingListsItemRead>
        <Box mb={dim(2)}>{total}</Box>
        <BookComponent />
      </ReadingListsItemRead>
    </StyledReadingListsItemThumbnail>
  );
};
