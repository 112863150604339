import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import caretDown from '@iconify/icons-radix-icons/caret-down';
import { Caption2, RichText } from '../../typography';
import { dim } from '../../../theme';
import { Maybe } from '../../../types/utils';
import { PTBlock } from '../../../types';
import IconButton from '../../inputs/IconButton';

const StyledHelpItem = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: ${({ theme }) => theme.space[3]};
  border-top: none;
  border-bottom: ${dim(1)} solid ${({ theme }) => theme.colors.grays['50']};
  width: 100%;
  cursor: pointer;
  &:first-of-type {
    border-top: ${dim(1)} solid ${({ theme }) => theme.colors.grays['50']};
  }
`;

const HelpItemHeader = styled.dt`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HelpItemBody = styled.dd`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 0 ${({ theme }) => theme.space[2]};

  & p {
    margin-top: ${({ theme }) => theme.space[3]};
  }
  & p:first-child {
    margin-top: ${({ theme }) => theme.space[2]};
  }
`;

export interface HelpItemProps {
  header: Maybe<string>;
  body: string | Maybe<PTBlock>[] | null | undefined;
  isExpanded?: boolean;
  onToggleExpand(): void;
}

export const HelpItem: React.FC<HelpItemProps> = ({
  header,
  body,
  isExpanded = false,
  onToggleExpand,
}) => {
  const [expanded, setExpanded] = useState(isExpanded);

  const handleToggleExpand = useCallback(() => {
    setExpanded((prevState = isExpanded) => !prevState);
    onToggleExpand();
  }, [isExpanded, onToggleExpand]);

  return (
    <StyledHelpItem
      onClick={handleToggleExpand}
      aria-label="Toggle help text display"
    >
      <HelpItemHeader>
        <Caption2 variant="plain" color="blues.60">
          {header}
        </Caption2>
        <IconButton color="blues.60">
          <Icon icon={caretDown} width="20" />
        </IconButton>
      </HelpItemHeader>
      {expanded ? (
        <HelpItemBody>
          <RichText text={body} />
        </HelpItemBody>
      ) : null}
    </StyledHelpItem>
  );
};

export default HelpItem;
