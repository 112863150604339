import React from 'react';
import { MultipleChoiceAnswerData } from './index';
import { Maybe } from './utils';

export type PanelProps = Required<{
  width: number;
  height: number;
  image: React.ReactNode;
}>;

export type InteractivePanelProps = PanelProps & {
  cta: string;
};

export interface AnimationAreaProps {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  autoplay: boolean;
  onReady(): void;
}

export type AnimationPanelProps = Omit<
  PanelProps,
  'image' | 'width' | 'height'
> &
  AnimationAreaProps;

export type ComparisonPanelProps = Omit<InteractivePanelProps, 'image'> & {
  question: string;
  answer?: string;
  value?: number;
  src: string;
  alt: string;
  src2: string;
  alt2: string;
  onSubmit(answer: string): void;
  onComparisonChange(value: number): void;
};

export type DrawingAreaTool = 'pen' | 'eraser';

export interface DrawingAreaLine {
  points: number[];
  tool: DrawingAreaTool;
  key: number;
}

export interface DrawingAreaProps {
  src: string;
  alt: string;
  lines?: Maybe<DrawingAreaLine[]>;
  width?: number;
  height?: number;

  onDraw(lines: DrawingAreaLine[]): void;
  onWarn(): void;
}

export type DrawingPanelProps = DrawingAreaProps &
  Omit<InteractivePanelProps, 'width' | 'height' | 'image'> & {
    question: string;
    lines?: Maybe<DrawingAreaLine[]>;
    onSubmit(lines: DrawingAreaLine[]): void;
  };

export interface HotspotRegion {
  lines: DrawingAreaLine[];
}

export interface HotspotData {
  region: HotspotRegion;
  header: string;
  body: string;
  active: boolean;
  key: number;
}

export type HotspotPanelProps = Omit<InteractivePanelProps, 'image'> & {
  src: string;
  alt: string;
  hotspots: Record<number, HotspotData>;
  onHotspotToggled(key: number): void;
};

export interface ImmersiveTextArea {
  x: number;
  y: number;
  width: number;
  height: number;
  angle: number;
  skew: number;
  fontSize: number;
}

export type ImmersiveTextPanelProps = Omit<InteractivePanelProps, 'image'> & {
  src: string;
  alt: string;
  textArea: ImmersiveTextArea;
  content: string;
  onSubmit(content: string): void;
};

export type ImagePanelProps = PanelProps & {};

export type MatchingQuestion = Maybe<{
  text?: Maybe<string>;
  label?: Maybe<string>;
}>;

export type MatchingQuestionRequired = {
  text: string;
  label: string;
};

export const isMatchingQuestionRequired = (
  q: unknown,
): q is MatchingQuestionRequired => {
  if (typeof q !== 'object' || q === null) return false;
  const question = q as MatchingQuestionRequired;
  return !!question.text && !!question.label;
};

export type MatchingAnswer = Maybe<{
  text?: Maybe<string>;
  label?: Maybe<string>;
  question?: Maybe<string>;
}>;

export type MatchingAnswerRequired = {
  text: string;
  label: string;
  question: string;
};

export const isMatchingAnswerRequired = (
  a: unknown,
): a is MatchingAnswerRequired => {
  if (typeof a !== 'object' || a === null) return false;
  const answer = a as MatchingAnswerRequired;
  return !!answer.text && !!answer.label && !!answer.question;
};

export type MatchingPanelProps = InteractivePanelProps & {
  caption: string;
  instructions: string;
  questions: MatchingQuestion[];
  answers: MatchingAnswer[];
  response?: Record<string, string | undefined>;
  onAnswerChange(question: string, answer: string): void;
  onSubmit(): void;
};

export type MultipleChoicePanelProps = Omit<InteractivePanelProps, 'cta'> & {
  question: string;
  caption: string;
  answers: MultipleChoiceAnswerData[];
  onAnswerClick(value: string): void;
};

export type ShortResponsePanelProps = InteractivePanelProps & {
  question: string;
  answer?: string;
  onSubmit(answer: string): void;
};

export type TimelinePanelProps = InteractivePanelProps & {
  question: string;
  answer?: string;
  value?: number;
  onSubmit(answer: string): void;
  onTimelineChange(value: number): void;
};
