import styled from '@emotion/styled';
import { down } from 'styled-breakpoints';
import { BaseTheme, dim } from '../../theme';
import { css } from '@emotion/react';
import Box from '../Box';
import { color, ColorProps } from 'styled-system';

export const roundedDesktopContainer = (theme: BaseTheme) => {
  return css`
    padding: ${theme.space[4]} ${theme.space[4]} 0;
    margin-top: ${theme.space[3]};
    background: ${theme.colors.white};
    box-shadow: 0 ${dim(4)} ${dim(4)} rgba(0, 0, 0, 0.25);
    border-radius: ${dim(8)};
  `;
};
export const RoundedBox = styled(Box)<ColorProps>`
  display: flex;
  flex-direction: column;
  ${({ theme }) => roundedDesktopContainer(theme)};

  ${down('mobile')} {
    margin: 0;
    background: transparent;
    box-shadow: none;
    padding: ${({ theme }) => theme.space[3]} 0 0;
    border-radius: 0;
    border-bottom-width: ${dim(1)};
    border-bottom-style: solid;
    width: auto;
    border-bottom-color: ${({ theme }) => theme.colors.grays[20]};
  }
  ${color}
`;

export const StoryRoundedBox = styled(RoundedBox)<ColorProps>`
  padding: ${({ theme }) => theme.space[4]} ${dim(60)};

  ${down('mobile')} {
    margin: ${({ theme }) => theme.space[3]};
    padding: 0 0 ${({ theme }) => theme.space[4]} 0;
    width: calc(100vw - 2rem);
  }
  ${color}
`;
