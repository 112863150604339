import { ReactNode } from 'react';
import styled from '@emotion/styled';
import Box from '../../Box';
import { Caption2, Header5 } from '../../typography';
import { dim } from '../../../theme';

const RecommendedStoryTitle = styled(Header5)``;
const RecommendedStoryTags = styled(Caption2)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.grays[60]};
`;
const RecommendedStoryAuthors = styled(Caption2)`
  font-weight: 400;
`;

export const _RecommendedStory: React.FC<{
  title: string;
  tags: string;
  authors: string;
  image: ReactNode;
  url?: string;
  className?: string;
}> = ({ title, tags, authors, image, url, className }) => {
  const story = (
    <Box className={className}>
      {image}
      <RecommendedStoryTags variant="plain" mb="1" mt="3">
        {tags}
      </RecommendedStoryTags>
      <RecommendedStoryTitle mb="2">{title}</RecommendedStoryTitle>
      <RecommendedStoryAuthors variant="plain">
        {authors}
      </RecommendedStoryAuthors>
    </Box>
  );

  return url ? (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      style={{ display: 'block', textDecoration: 'none', color: 'inherit' }}
    >
      {story}
    </a>
  ) : (
    story
  );
};

export const RecommendedStory = styled(_RecommendedStory)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 ${({ theme }) => theme.space[3]} 0 0;
  width: ${dim(160)};
  cursor: pointer;
`;
