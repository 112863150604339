import { Maybe } from './utils';
import { SanityImageAsset } from '@astc/frontend/graphql-types';

export interface ImageElementData {
  image: JSX.Element;
}

export interface ImageSourceData {
  src: string;
  alt?: string;
}

export type ImageData = ImageSourceData;

export const isImageSourceData = (
  imageData?: ImageData,
): imageData is ImageSourceData => {
  if (!imageData) return false;
  return !!(imageData as ImageSourceData).src;
};

export interface CountryData {
  images?: {
    flag?: ImageData;
  };
  name: string;
}

export type PTSpan = {
  _type?: Maybe<string>;
  _key?: Maybe<string>;
  marks?: Maybe<any[]>;
  text?: Maybe<string>;
};

export type PTBlock = {
  _key?: Maybe<string>;
  children?: Maybe<Maybe<PTSpan>[]>;
  _type?: Maybe<string>;
  style?: Maybe<string>;
};

export type NonNullPTBlock = {
  _key?: string;
  children?: Maybe<PTSpan>[];
  _type: string;
  style?: string;
};

export type PTBlocks = Maybe<PTBlock>[];

export const isPortableText = (
  bio: string | PTBlocks,
): bio is NonNullable<NonNullPTBlock>[] => {
  return (
    typeof bio !== 'string' &&
    !!Array.isArray(bio) &&
    bio.reduce<boolean>((arr, block) => {
      return (
        arr &&
        typeof block === 'object' &&
        !!block?.hasOwnProperty('_type') &&
        !!block?.hasOwnProperty('_key')
      );
    }, true)
  );
};

export interface CreatorData {
  bio?: string | PTBlocks;
  country?: CountryData;
  image?: Maybe<SanityImageAsset>;
  locations?: string[];
  location?: string;
  name: string;
}

export interface NavLocData {
  title: string;
  url?: string;
}

export interface MultipleChoiceAnswerData {
  id: string;
  label: string;
  value: string;
  selected: boolean;
}

export enum ShareTarget {
  FACEBOOK = 'FACEBOOK',
  TUMBLR = 'TUMBLR',
  REDDIT = 'REDDIT',
  TWITTER = 'TWITTER',
  URL = 'URL',
}

export enum UserRole {
  STUDENT = 'STUDENT',
  EDUCATOR = 'EDUCATOR',
  NEITHER = 'NEITHER',
  ADMIN = 'ADMIN',
}

export enum ReadingExperience {
  INTERACTIVE = 'INTERACTIVE',
  READ_ONLY = 'READ_ONLY',
}
export { BadgeReaderData } from './badge';
export * from './badge';
export * from './panel';
export * from './profile';
export * from './readingList';
export * from './story';
export * from './inputs';
