import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as ProfileButtonBackground } from './profile_button.svg';
import Box from '../../../Box';
import { dim } from '../../../../theme';

const StyledProfileButton = styled(Box)`
  position: relative;
  display: block;
`;

const ProfileButtonLabel = styled.span`
  white-space: nowrap;
  position: absolute;
  top: ${dim(21)};
  left: ${dim(45)};
  font-family: ${({ theme }) => theme.fonts['body']};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  color: ${({ theme }) => theme.colors.blues['60']};
`;

export const ProfileButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <StyledProfileButton onClick={onClick} aria-label="open my profile">
      <ProfileButtonBackground width="136" height="50" viewBox="0 0 136 50" />
      <ProfileButtonLabel>My Profile</ProfileButtonLabel>
    </StyledProfileButton>
  );
};
