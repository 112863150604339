import React from 'react';
import styled from '@emotion/styled';
import { Caption2, Header4, Header5 } from '../../typography';
import { dim, pluralize } from '../../../theme';
import { down, up } from 'styled-breakpoints';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

export interface CounterHeaderProps {
  title: string;
  amount: number;
  unit: string;
  unitPlural?: string;
  className?: string;
}

export const CounterHeaderTitle = styled(Header5)`
  display: flex;
  align-items: flex-end;
  font-family: ${({ theme }) => theme.fonts['header']};
  color: ${({ theme }) => theme.colors.blues[60]};
  font-weight: normal;
  ${down('mobile')} {
    display: none;
  }
`;

export const CounterHeaderTitleMobile = styled(Header4)`
  color: ${({ theme }) => theme.colors.blues[60]};
  ${up('mobile')} {
    display: none;
  }
`;

export const CounterHeaderNumeric = styled(Caption2)`
  color: ${({ theme }) => theme.colors.blues[60]};
  background-color: transparent;
  box-shadow: none;
  margin-left: ${dim(20)};
  margin-bottom: ${dim(4)};
  ${down('mobile')} {
    margin-left: ${({ theme }) => theme.space[1]};
    margin-bottom: ${dim(2)};
    padding-bottom: 0;
  }
`;

export const _CounterHeader: React.FC<CounterHeaderProps> = ({
  title,
  amount,
  unit,
  unitPlural,
  className,
}) => {
  return (
    <>
      <CounterHeaderTitle className={className}>
        {title}
        <CounterHeaderNumeric>
          {amount} {pluralize(amount, unit, unitPlural)}
        </CounterHeaderNumeric>
      </CounterHeaderTitle>
      <CounterHeaderTitleMobile className={className}>
        {title}
        <CounterHeaderNumeric>
          {amount} {pluralize(amount, unit, unitPlural)}
        </CounterHeaderNumeric>
      </CounterHeaderTitleMobile>
    </>
  );
};

export const CounterHeader = styled(_CounterHeader)<LayoutProps & SpaceProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0;
  color: ${({ theme }) => theme.colors.blues['60']};
  ${layout};
  ${space};
`;
