import React, { ReactElement } from 'react';
import { RoundedBox } from '../../RoundedBox';
import { Header4 } from '../../typography';
import styled from '@emotion/styled';
import { dim } from '../../../theme';
import { down } from 'styled-breakpoints';
import Box from '../../Box';
import { StoryInfoContentCharacter } from './character';

const StoryInfoContentCharactersBody = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: ${({ theme }) => theme.space[2]};
`;

const _StoryInfoContentCharacters: React.FC<{
  name: string;
  children: ReactElement<typeof StoryInfoContentCharacter>[];
  className?: string;
}> = ({ name = 'Characters', children, className }) => (
  <RoundedBox className={className}>
    <Header4 fontWeight="500" fontSize={[dim(36), dim(36)]}>
      {name}
    </Header4>
    <StoryInfoContentCharactersBody>{children}</StoryInfoContentCharactersBody>
  </RoundedBox>
);

export const StoryInfoContentCharacters = styled(_StoryInfoContentCharacters)`
  margin-top: ${dim(43)};
  padding: ${dim(37)} ${dim(24)} ${({ theme }) => theme.space[4]};

  ${down('mobile')} {
    padding-bottom: ${({ theme }) => theme.space[3]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
    border-radius: 0;
    margin-bottom: ${({ theme }) => theme.space[3]};
  }
`;
