import React from 'react';
import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import facebookFill from '@iconify/icons-akar-icons/facebook-fill';
import tumblrFill from '@iconify/icons-akar-icons/tumblr-fill';
import twitterFill from '@iconify/icons-akar-icons/twitter-fill';
import redditFill from '@iconify/icons-akar-icons/reddit-fill';
import bxsShareAlt from '@iconify/icons-bx/bxs-share-alt';
import IconButton from '../inputs/IconButton';
import { ShareTarget } from '../../types';
import { useTheme } from '@emotion/react';
import { dim } from '../../theme';
import { down } from 'styled-breakpoints';

const StyledSocialMediaBar = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: ${dim(223)};
  padding: 0;
  ${down('mobile')} {
    width: 100%;
  }
`;

const SocialMediaContainer = styled.div<{ withLayout: boolean }>`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin: ${({ theme, withLayout }) => (withLayout ? theme.space[3] : 'auto')}
    auto;
  padding: ${({ theme, withLayout }) => (withLayout ? theme.space[4] : 0)} 0;
  border-top: ${({ theme, withLayout }) =>
    withLayout ? `1px solid ${theme.colors.grays[20]}` : 'none'};
  ${down('mobile')} {
    width: ${({ withLayout }) =>
      withLayout ? `calc(100vw - ${dim(32)})` : '100%'};
  }
`;

export interface SocialMediaBarProps {
  withLayout?: boolean;
  onShare(target: ShareTarget): () => void;
}

export const SocialMediaBar: React.FC<SocialMediaBarProps> = ({
  withLayout = true,
  onShare,
}) => {
  const { colors } = useTheme();
  return (
    <StyledSocialMediaBar>
      <SocialMediaContainer withLayout={withLayout}>
        <IconButton
          onClick={onShare(ShareTarget.FACEBOOK)}
          aria-label="share on facebook"
        >
          <Icon icon={facebookFill} height={24} color={colors.blues['60']} />
        </IconButton>
        <IconButton
          onClick={onShare(ShareTarget.TUMBLR)}
          aria-label="share on tumblr"
        >
          <Icon icon={tumblrFill} height={24} color={colors.blues['60']} />
        </IconButton>
        <IconButton
          onClick={onShare(ShareTarget.TWITTER)}
          aria-label="share on twitter"
        >
          <Icon icon={twitterFill} height={24} color={colors.blues['60']} />
        </IconButton>
        <IconButton
          onClick={onShare(ShareTarget.REDDIT)}
          aria-label="share on reddit"
        >
          <Icon icon={redditFill} height={24} color={colors.blues['60']} />
        </IconButton>
        <IconButton
          onClick={onShare(ShareTarget.URL)}
          aria-label="share via url"
        >
          <Icon icon={bxsShareAlt} height={24} color={colors.blues['60']} />
        </IconButton>
      </SocialMediaContainer>
    </StyledSocialMediaBar>
  );
};

export default SocialMediaBar;
