import styled from '@emotion/styled';
import { dim } from '../../../theme';
import { Header4, Header5, Text } from '../../typography';
import { down, up } from 'styled-breakpoints';
import Box from '../../Box';
import { roundedDesktopContainer } from '../../RoundedBox';
import { Button1 } from '../../inputs';
import React from 'react';

export const ProfileViewHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.space[3]};
  ${down('mobile')} {
    margin-top: ${({ theme }) => theme.space[4]};
  }
`;

export const ProfileViewInstructions = styled(Text)`
  font-size: ${dim(16)};
  font-weight: 400;
  line-height: ${dim(24)};
  color: ${({ theme }) => theme.colors.grays['80']};
  padding: ${({ theme }) => theme.space[2]} 0 0;
  ${down('mobile')} {
    padding: ${({ theme }) => theme.space[1]} 0 ${({ theme }) => theme.space[3]};
  }
`;

export const ProfileViewBadgesHeader = ProfileViewHeader;
export const ProfileViewReadingListsHeader = ProfileViewHeader;
export const ProfileViewCreateAssignmentHeader = ProfileViewHeader;

export const ProfileViewTitle = styled(Header4)`
  color: ${({ theme }) => theme.colors.blues[60]};
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  margin: 0;
`;

export const ProfileViewHeaderTitle = styled(Header5)`
  font-family: ${({ theme }) => theme.fonts['header']};
  color: ${({ theme }) => theme.colors.blues[60]};
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  margin: 0;
  ${down('mobile')} {
    font-size: ${dim(27)};
    font-weight: 700;
  } ;
`;

export const ProfileViewHeaderDesktop = styled(ProfileViewHeaderTitle)`
  ${down('mobile')} {
    display: none;
  } ;
`;
export const ProfileViewHeaderMobile = styled(ProfileViewHeaderTitle)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  margin: 0;
  color: ${({ theme }) => theme.colors.blues['60']};
  ${up('mobile')} {
    display: none;
  } ;
`;
export const ProfileViewBadgesHeaderTitle = ProfileViewHeaderTitle;
export const ProfileViewReadingListsHeaderTitle = ProfileViewHeaderTitle;

export const ProfileViewAccount = styled(Box)`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[4]};
  margin: ${({ theme }) => theme.space[3]} 0 ${({ theme }) => theme.space[2]};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  ${down('mobile')} {
    display: none;
  }
`;

export const ProfileViewAccountMobile = styled(Text)`
  display: none;
  ${down('mobile')} {
    margin-top: ${dim(10)};
    display: flex;
  }
`;

export const ProfileViewBadges = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  ${({ theme }) => roundedDesktopContainer(theme)};
  padding-bottom: ${({ theme }) => theme.space[2]};
  margin-bottom: ${({ theme }) => theme.space[2]};
  overflow-x: auto;
  ${down('mobile')} {
    margin: 0;
    padding-bottom: ${({ theme }) => theme.space[3]};
    background: transparent;
    box-shadow: none;
    padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[3]};
  }
`;

export const ProfileViewReadingLists = styled.ul`
  display: flex;
  flex-direction: column;
  ${({ theme }) => roundedDesktopContainer(theme)};
  ${down('mobile')} {
    margin: 0;
    background: transparent;
    box-shadow: none;
    padding: ${({ theme }) => theme.space[3]} 0 0;
  }
`;

export const ProfileViewActions = styled.ul`
  display: none;
  ${down('mobile')} {
    flex-direction: column;
    margin-top: ${({ theme }) => theme.space[3]} auto;
    display: flex;
    width: ${dim(91)};
    margin-left: auto;
    margin-right: auto;
  }
`;

const _ProfileViewCreateAssignment: React.FC<{
  onCreateAssignment: () => void;
  iconLeft: JSX.Element;
  className?: string;
}> = ({ onCreateAssignment, iconLeft, className }) => {
  return (
    <Box className={className}>
      <Button1
        palette="blue"
        variant="secondary"
        iconLeft={iconLeft}
        onClick={onCreateAssignment}
      >
        Create Assignment
      </Button1>
    </Box>
  );
};

export const ProfileViewCreateAssignment = styled(_ProfileViewCreateAssignment)`
  ${down('mobile')} {
    width: ${dim(179)};
    padding: ${dim(24)} 0;
    margin: 0 auto;
  }
`;
