import { reds, white } from '../colors';
import {
  ThemeElementPalette,
  ThemeElementPaletteVariantState,
  ThemeElementStateProperty,
} from './types';

const redDefaultPrimary: Record<ThemeElementStateProperty, string & {}> = {
  background: white,
  border: reds['60'],
  shadow: reds['60'],
  text: reds['60'],
};
const redDisabledPrimary: Record<ThemeElementStateProperty, string & {}> = {
  ...redDefaultPrimary,
  border: reds['30'],
  text: reds['30'],
};
const redDownPrimary: Record<ThemeElementStateProperty, string & {}> = {
  ...redDefaultPrimary,
  background: reds['40'],
  border: reds['70'],
  text: reds['70'],
};
const redHoverPrimary: Record<ThemeElementStateProperty, string & {}> = {
  ...redDefaultPrimary,
  border: reds['40'],
  shadow: reds['40'],
  text: reds['40'],
};
const redVariantDefaultPrimary: ThemeElementPaletteVariantState = {
  default: { ...redDefaultPrimary },
  disabled: { ...redDisabledPrimary },
  down: { ...redDownPrimary },
  hover: { ...redHoverPrimary },
};

const redDefaultSecondary: Record<ThemeElementStateProperty, string & {}> = {
  background: reds['60'],
  border: reds['60'],
  shadow: reds['60'],
  text: white,
};
const redDisabledSecondary: Record<ThemeElementStateProperty, string & {}> = {
  ...redDefaultSecondary,
  background: reds['20'],
  shadow: reds['20'],
  border: reds['20'],
};
const redDownSecondary: Record<ThemeElementStateProperty, string & {}> = {
  ...redDefaultSecondary,
  background: reds['70'],
  border: reds['70'],
  text: reds['30'],
};
const redHoverSecondary: Record<ThemeElementStateProperty, string & {}> = {
  ...redDefaultSecondary,
  background: reds['50'] + 'FF',
  border: reds['50'] + 'FF',
  shadow: reds['70'] + '33',
};
const redVariantDefaultSecondary: ThemeElementPaletteVariantState = {
  default: { ...redDefaultSecondary },
  disabled: { ...redDisabledSecondary },
  down: { ...redDownSecondary },
  hover: { ...redHoverSecondary },
};

export const redPalette: ThemeElementPalette = {
  button: {
    primary: { ...redVariantDefaultPrimary },
    secondary: { ...redVariantDefaultSecondary },
  },
  default: {
    primary: { ...redVariantDefaultPrimary },
    secondary: { ...redVariantDefaultSecondary },
  },
  input: {
    primary: { ...redVariantDefaultPrimary },
    secondary: { ...redVariantDefaultSecondary },
  },
};
