import styled from '@emotion/styled';
import { dim } from '../../../theme';

export const Textarea = styled.textarea`
  box-sizing: border-box;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.blues['50']};
  border-radius: ${({ theme }) => theme.radii['sm']};
  margin: ${({ theme }) => theme.space[3]} 0;
  padding: ${({ theme }) => theme.space[3]};
  min-height: ${({ theme }) => theme.space[6]};
  font-family: '${({ theme }) => theme.fonts['body']}', sans-serif;
  font-size: ${dim(16)};
  width: 100%;
`;

export default Textarea;
