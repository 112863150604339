import { Image, Layer, Line, Stage } from 'react-konva';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Konva from 'konva';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import useImage from 'use-image';
import simplify from 'simplify-js';
import drawImage20Regular from '@iconify/icons-fluent/draw-image-20-regular';
import eraser20Regular from '@iconify/icons-fluent/eraser-20-regular';
import arrowUndo20Regular from '@iconify/icons-fluent/arrow-undo-20-regular';
import { dim } from '../../../theme';
import {
  DrawingAreaLine,
  DrawingAreaProps,
  DrawingAreaTool,
} from '../../../types';
import IconButton from '../../inputs/IconButton';
import Box from '../../Box';
import KonvaEventObject = Konva.KonvaEventObject;
import { Icon } from '@iconify/react';

if (process.env.NODE_ENV === 'test') {
  // @ts-ignore
  Konva.isBrowser = false;
}

export const DRAWING_AREA_SIZE = { width: 320, height: 320 };
export const MAX_POINTS = 150;
export const WARNING_LEVEL = 0.8;

const StyledDrawingArea = styled.figure<Partial<typeof DRAWING_AREA_SIZE>>`
  display: flex;
  flex-direction: column;
  margin: 0;
  width: ${({ width }) => dim(width || DRAWING_AREA_SIZE.width)};
  background-color: ${({ theme }) => theme.colors.white};
`;

const StyledA11yDrawingArea: React.FC<
  Partial<typeof DRAWING_AREA_SIZE> & { 'aria-label': string }
> = (props) => <StyledDrawingArea {...props} />;

const DrawingArea: React.FC<DrawingAreaProps> = ({
  width,
  height,
  src,
  alt,
  lines: _lines,
  onWarn,
  onDraw,
}) => {
  const [lines, setLines] = useState<DrawingAreaLine[]>(_lines || []);
  // TODO: handle eraser with setTool, if needed
  const [tool, setTool] = useState<DrawingAreaTool>('pen');
  const isDrawing = useRef(false);
  const { colors } = useTheme();
  const [image] = useImage(src);

  useEffect(() => {
    setLines(_lines || []);
  }, [_lines]);

  const handleMouseDown = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      isDrawing.current = true;
      const stage = e.target.getStage();
      const pos = stage?.getPointerPosition();
      if (!pos) return;

      const key = Date.now();
      setLines([...lines, { points: [pos.x, pos.y], tool, key }]);
    },
    [lines, tool],
  );
  const handleTouchStart = useCallback(
    (e: KonvaEventObject<TouchEvent>) => {
      isDrawing.current = true;
      const stage = e.target.getStage();
      const pos = stage?.getPointerPosition();
      if (!pos) return;

      const key = Date.now();
      setLines([...lines, { points: [pos.x, pos.y], tool, key }]);
    },
    [lines, tool],
  );

  const handleMouseMove = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      if (!isDrawing.current) {
        return;
      }
      const stage = e.target.getStage();
      const point = stage?.getPointerPosition();
      const lastLine: DrawingAreaLine = lines[lines.length - 1];

      if (!(lastLine && point)) {
        return;
      }

      lastLine.points = lastLine.points.concat([point.x, point.y]);
      lines.splice(lines.length - 1, 1, lastLine);
      setLines(lines.concat());
    },
    [lines],
  );

  const handleTouchMove = useCallback(
    (e: KonvaEventObject<TouchEvent>) => {
      if (!isDrawing.current) {
        return;
      }
      const stage = e.target.getStage();
      const point = stage?.getPointerPosition();
      const lastLine: DrawingAreaLine = lines[lines.length - 1];

      if (!(lastLine && point)) {
        return;
      }

      lastLine.points = lastLine.points.concat([point.x, point.y]);
      lines.splice(lines.length - 1, 1, lastLine);
      setLines(lines.concat());
    },
    [lines],
  );

  const handlePointerStop = useCallback(() => {
    isDrawing.current = false;
    console.info(lines.reduce((acc, line) => acc + line.points.length, 0));
    const simplifiedLines = lines.map((line) => {
      const convertedPoints = line.points.reduce((acc, point, index) => {
        if (index % 2 === 1) {
          acc.push({ x: line.points[index - 1], y: point });
          return acc;
        }
        return acc;
      }, [] as { x: number; y: number }[]);
      const simplifiedPoints = simplify(convertedPoints, 5, true);
      const points = simplifiedPoints.reduce((acc, point) => {
        acc.push(point.x, point.y);
        return acc;
      }, [] as number[]);
      return { ...line, points };
    });
    const totalPoints = simplifiedLines.reduce(
      (acc, line) => acc + line.points.length,
      0,
    );
    console.info(
      simplifiedLines.reduce((acc, line) => acc + line.points.length, 0),
    );

    if (totalPoints > MAX_POINTS) {
      setLines(simplifiedLines.slice(0, simplifiedLines.length - 1));
      onWarn();
    } else {
      setLines(() => {
        onDraw(simplifiedLines);
        return simplifiedLines;
      });
    }
  }, [lines, onDraw, onWarn]);

  const handleUndo = useCallback(() => {
    setLines((prevState) => {
      const newState = prevState.slice(0, prevState.length - 1);
      onDraw(newState);
      return newState;
    });
  }, [setLines, onDraw]);

  return (
    <StyledA11yDrawingArea width={width} height={height} aria-label={alt}>
      <Stage
        width={width || DRAWING_AREA_SIZE.width}
        height={height || DRAWING_AREA_SIZE.height}
        onMouseDown={handleMouseDown}
        onMousemove={handleMouseMove}
        onMouseup={handlePointerStop}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handlePointerStop}
        className="canvas-stage"
      >
        <Layer>
          <Image image={image} />
        </Layer>
        <Layer>
          {lines.map((line: DrawingAreaLine) => (
            <Line
              key={line.key}
              points={line.points}
              stroke={colors.primary.regular}
              strokeWidth={4}
              tension={0.5}
              lineCap="round"
              globalCompositeOperation={
                line.tool === 'eraser' ? 'destination-out' : 'source-over'
              }
            />
          ))}
        </Layer>
      </Stage>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        width={dim(width || DRAWING_AREA_SIZE.width)}
      >
        <Box
          height={dim(4)}
          marginTop={dim(2)}
          marginBottom={dim(8)}
          width={dim(
            (width || DRAWING_AREA_SIZE.width) *
              (1 -
                lines.reduce((acc, line) => acc + line.points.length, 0) /
                  MAX_POINTS),
          )}
          backgroundColor={
            isDrawing.current
              ? 'gray'
              : lines.reduce((acc, line) => acc + line.points.length, 0) /
                  MAX_POINTS >
                WARNING_LEVEL
              ? 'red'
              : 'green'
          }
        >
          &nbsp;
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        width={dim(136)}
        justifyContent="space-between"
      >
        <IconButton onClick={() => setTool('pen')}>
          <Box
            backgroundColor={
              tool === 'pen' ? colors.grays['50'] : colors.blues['60']
            }
            width={dim(40)}
            height={dim(40)}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius={dim(8)}
          >
            <Icon icon={drawImage20Regular} color={colors.white} width={28} />
          </Box>
        </IconButton>
        <IconButton onClick={() => setTool('eraser')}>
          <Box
            backgroundColor={
              tool === 'eraser' ? colors.grays['50'] : colors.blues['60']
            }
            width={dim(40)}
            height={dim(40)}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius={dim(8)}
          >
            <Icon icon={eraser20Regular} color={colors.white} width={28} />
          </Box>
        </IconButton>
        <IconButton onClick={handleUndo}>
          <Box
            backgroundColor={colors.blues['60']}
            width={dim(40)}
            height={dim(40)}
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius={dim(8)}
          >
            <Icon icon={arrowUndo20Regular} color={colors.white} width={28} />
          </Box>
        </IconButton>
      </Box>
    </StyledA11yDrawingArea>
  );
};

export default DrawingArea;
