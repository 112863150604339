import styled from '@emotion/styled';
import { dim } from '../../../theme';
import { ThemedButtonProps } from '../../../types';
import { Button2 } from '../../inputs';

export const WelcomeBoundary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WelcomeHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WelcomeControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const WelcomeRoles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${dim(320)};
  margin-bottom: ${({ theme }) => theme.space[3]};
`;

export const WelcomeButton = styled(Button2)<ThemedButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
