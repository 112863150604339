import styled from '@emotion/styled';
import Box from '../../Box';
import { Header6, Text } from '../../typography';
import { BaseTheme, dim } from '../../../theme';
import {
  ComponentPropsWithoutRef,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { css } from '@emotion/react';

const StyledLimitedTextarea = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

const LimitedTextareaLabel = styled(Header6)`
  margin: 0 0 ${dim(8)};
  padding: 0;
  color: ${({ theme }) => theme.colors.grays[50]};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  font-weight: ${({ theme }) => theme.fontWeights[0]};
  font-family: ${({ theme }) => theme.fonts['body']};
`;

const LimitedTextareaControl = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

const LimitedTextareaIndicator = styled(Text)`
  margin: 0 0 0 ${dim(8)};
  color: ${({ theme }) => theme.colors.grays[50]};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  font-weight: ${({ theme }) => theme.fontWeights[0]};
  font-family: ${({ theme }) => theme.fonts['body']};
`;

// auto-expanding textarea
// cf. https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
const getTextareaStyling = (theme: BaseTheme) => {
  return css`
    box-sizing: border-box;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    background: none;
    width: 100%;
    overflow-x: hidden;
    max-width: 100%;
    line-height: ${theme.lineHeights[4]};
    font-size: ${dim(18)};
    font-weight: ${theme.fontWeights[0]};
    font-family: ${theme.fonts['body']};
    word-wrap: break-word;

    grid-area: 1 / 1 / 2 / 2;
  `;
};

const LimitedTextareaWrapper = styled.div`
  display: grid;
  flex: 1;
  &::after {
    content: attr(data-replicated-value) ' ';
    white-space: pre-wrap;
    visibility: hidden;
    ${({ theme }) => getTextareaStyling(theme)}
  }
`;

const LimitedTextareaInput = styled.textarea`
  ${({ theme }) => getTextareaStyling(theme)}
  resize: none;
  overflow: hidden;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grays[40]};
    font-size: ${dim(18)};
    font-weight: ${({ theme }) => theme.fontWeights[0]};
    font-family: ${({ theme }) => theme.fonts['body']};
    text-decoration: underline;
  }
`;

type LimitedTextareaProps = ComponentPropsWithoutRef<'textarea'> & {
  limit: number;
  labelText: string;
  marginTop?: string;
};

export const LimitedTextarea: FC<LimitedTextareaProps> = (props) => {
  const { limit, labelText, onChange, style, value: _value, ...rest } = props;

  const [count, setCount] = useState(0);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (!_value || typeof _value !== 'string') return;
    setCount(_value.length);
    setValue(_value);
  }, [_value]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value: inputValue } = event.target;
      const constrainedValue = inputValue.slice(0, limit);
      setCount(constrainedValue.length);
      setValue(constrainedValue);
      if (
        event.currentTarget?.parentNode &&
        event.currentTarget?.parentNode instanceof HTMLElement
      ) {
        event.currentTarget.parentNode.dataset.replicatedValue =
          constrainedValue;
      }
      onChange &&
        onChange({
          ...event,
          target: { ...event.target, value: constrainedValue },
        });
    },
    [limit, onChange],
  );

  return (
    <StyledLimitedTextarea style={style}>
      <LimitedTextareaLabel>{labelText}</LimitedTextareaLabel>
      <LimitedTextareaControl>
        <LimitedTextareaWrapper>
          <LimitedTextareaInput
            {...rest}
            value={value}
            onChange={handleChange}
          />
        </LimitedTextareaWrapper>
        <LimitedTextareaIndicator>
          {count}/{limit}
        </LimitedTextareaIndicator>
      </LimitedTextareaControl>
    </StyledLimitedTextarea>
  );
};
