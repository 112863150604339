import styled from '@emotion/styled';
import Box from '../../Box';
import React, { ReactElement } from 'react';
import { Text, Header6 } from '../../typography';
import { RecommendedStory } from './story';
import { dim } from '../../../theme';
import { StoryRoundedBox } from '../../RoundedBox';
import { down } from 'styled-breakpoints';

const RecommendedStories = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  width: 100%;

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
    width: calc(100vw - 2rem);
  }
`;

const _RecommendedReading: React.FC<{
  className?: string;
  children?:
    | ReactElement<typeof RecommendedStory>
    | ReactElement<typeof RecommendedStory>[];
}> = ({ className, children }) => {
  return (
    <StoryRoundedBox className={className}>
      <Header6 fontWeight={700} mb="3">
        Recommended Reading
      </Header6>
      <Text mb="3">
        If you liked this comic we think that you’ll enjoy these works from
        other authors outside of Adventures of Asia comics.
      </Text>
      <RecommendedStories>{children}</RecommendedStories>
    </StoryRoundedBox>
  );
};

export { RecommendedStory } from './story';

export const RecommendedReading = styled(_RecommendedReading)`
  width: 100%;
  margin: ${({ theme }) => theme.space[4]} 0 0 0;
  // display: flex;
  // flex-direction: column;
  // width: 100%;
  // align-items: flex-start;
  // padding: ${({ theme }) => theme.space[4]} ${dim(60)};
  //
  // // ${down('mobile')} {
  // //   padding: 0 0 ${({ theme }) => theme.space[4]} 0;
  // // }
`;
