import { BASE_FONT_SIZE } from '../constants';
import { ThemeInputSize } from './index';

export const DEFAULT_DESKTOP_WIDTH = 1440;
export const DEFAULT_MOBILE_WIDTH = 375;

interface DimOptions {
  isMobile?: boolean;
  isRelativeToDefault?: boolean;
}

export const dim = (
  value: number,
  units = 'rem',
  options?: DimOptions,
): string => {
  const { isMobile = false, isRelativeToDefault = false } = options || {
    isMobile: false,
    isRelativeToDefault: false,
  };
  const width = isMobile ? DEFAULT_MOBILE_WIDTH : DEFAULT_DESKTOP_WIDTH;
  if (value === 0) return '0';
  if (units === 'vw' || units === 'vh') {
    if (isRelativeToDefault) {
      return `calc(${dim(value)} + (100vw - ${dim(width)}) / 2)`;
    }
    return `${(value / width) * 100}${units}`;
  }
  if (units !== 'rem') return `${value}${units}`;
  return `${value / BASE_FONT_SIZE}${units}`;
};

export const pluralize = (count: number, singular: string, plural?: string) => {
  return count === 1 ? singular : !!plural ? plural : `${singular}s`;
};

export const makeSizesString = (sizes: number | number[]) => {
  const pixels = Array.isArray(sizes) ? sizes : [sizes];
  return pixels.map((pixel) => dim(pixel)).join(' ');
};

export const getSizedStyle = (
  sizes: {
    small: number | number[];
    large: number | number[];
  },
  size?: ThemeInputSize,
): string => {
  const _size = size || 'large';
  return makeSizesString(sizes[_size]);
};
