import { dim } from './utils';
import { createTheme } from 'styled-breakpoints';
import { BASE_FONT_SIZE } from '../constants';
import { ObjectOrArray, RequiredTheme } from 'styled-system';
import * as CSS from 'csstype';
import {
  ThemeElementPalette,
  ThemePalette,
  ThemeVariant,
} from './palettes/types';
import { bluePalette, grayPalette, greenPalette, redPalette } from './palettes';
import {
  blues,
  grays,
  greens,
  reds,
  listColors,
  ThemeFixedColor,
  ThemeHue,
  ThemeState,
  ThemeValue,
} from './colors';

export const themeInputSizes = ['small', 'large'] as const;
export type ThemeInputSize = Readonly<typeof themeInputSizes[number]>;

const breakpoints = [1080].map((size) => dim(size, 'px'));
const fontSizes = [14, BASE_FONT_SIZE, 21, 27, 36, 56, 80, 128].map((size) => {
  return dim(size, 'rem');
});
const space = [0, 4, 8, 16, 32, 64, 128, 256].map((size) => dim(size, 'rem'));

const fonts: ObjectOrArray<CSS.Property.FontFamily> = {
  header: 'Nunito',
  body: 'Proxima Nova',
};
const lineHeights = [0.75, 0.9, 1, 1.1, 1.24, 1.5, 1.6];
const letterSpacings = ['-0.04em', '-0.015em', '0', '0.02em'];
const borderWidths = [0, 1, 2].map((width) => dim(width));

export type ThemeRadii = Record<
  0 | 'sm' | 'md' | 'lg' | 'xl',
  CSS.Property.BorderRadius
>;

const radii: ThemeRadii = {
  0: 0,
  sm: dim(4),
  md: dim(8),
  lg: dim(12),
  xl: dim(16),
};

const fontWeights: ObjectOrArray<CSS.Property.FontWeight> = {
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
};

export const breakpointTheme = createTheme({
  mobile: breakpoints[0],
});

export type ThemeColor = Record<ThemeHue, ThemeValue> &
  Record<ThemeVariant, ThemeState> &
  Record<ThemeFixedColor, string & {}> & {
    palettes: Record<ThemePalette, ThemeElementPalette>;
  };
export const colors: ThemeColor = {
  blues,
  grays,
  reds,
  greens,
  ...listColors,
  palettes: {
    red: redPalette,
    blue: bluePalette,
    gray: grayPalette,
    green: greenPalette,
  },
  primary: {
    background: grays['50'],
    regular: blues['50'],
    active: blues['50'],
    disabled: grays['50'],
    highlight: blues['70'],
    hovered: blues['70'],
    error: reds['30'],
    overlay: 'rgba(52, 52, 52, 0.8)',
  },
  secondary: {
    background: grays['60'],
    regular: blues['60'],
    active: blues['60'],
    disabled: grays['70'],
    highlight: blues['80'],
    hovered: blues['80'],
    error: reds['50'],
    overlay: 'rgba(52, 52, 52, 0.8)',
  },
};
export const baseTheme: Omit<
  RequiredTheme,
  | 'mediaQueries'
  | 'sizes'
  | 'borders'
  | 'borderStyles'
  | 'shadows'
  | 'zIndices'
  | 'buttons'
  | 'colorStyles'
  | 'textStyles'
  | 'colors'
> & { colors: ThemeColor; radii: ThemeRadii } = {
  borderWidths,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  letterSpacings,
  lineHeights,
  radii,
  fontWeights,
  space,
  ...breakpointTheme,
};

export type BaseTheme = typeof baseTheme;
export * from './utils';
export * from './colors';
export * from './icons';
export * from './palettes';
