import styled from '@emotion/styled';
import { dim } from '../../theme';
import { space, SpaceProps } from 'styled-system';

export const COUNTRY_FLAG_IMAGE_SIZE = { width: 53, height: 33 };
export const CountryFlagImage = styled.div<SpaceProps>`
  display: flex;
  flex-grow: 0;
  width: ${dim(COUNTRY_FLAG_IMAGE_SIZE.width)};
  height: ${dim(COUNTRY_FLAG_IMAGE_SIZE.height)};
  ${space}
`;

export default CountryFlagImage;
