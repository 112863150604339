import styled from '@emotion/styled';
import React from 'react';
import { variant } from 'styled-system';
import { css } from '@emotion/react';
import { ReactComponent as TextInputBase1 } from './textInputBase01.svg';
import { ReactComponent as TextInputBase2 } from './textInputBase02.svg';
import {
  baseTheme,
  dim,
  makeSizesString,
  ThemePalette,
  ThemeVariant,
} from '../../../theme';

export const StyledTextInput = styled.input<{
  variant?: ThemeVariant;
  palette: ThemePalette;
  offset?: boolean;
}>`
  position: absolute;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: '${({ theme }) => theme.fonts['body']}', serif;
  font-size: ${dim(18)};
  width: ${({ offset }) => dim(offset ? 276 : 300)};

  ::placeholder {
    padding: 0;
    color: ${({ theme, palette }) =>
      theme.colors.palettes[palette].input.primary.default.text};
    font-family: '${({ theme }) => theme.fonts['body']}', serif;
  }

  ${({ offset }) => {
    return variant({
      variants: {
        primary: css`
          left: ${dim(offset ? 45 : 21)};
          top: ${dim(24)};
        `,
        secondary: css`
          left: ${dim(offset ? 45 : 21)};
          top: ${dim(30)};
        `,
      },
    });
  }}
`;

export const TextInputWrapper = styled.div<{
  palette: ThemePalette;
}>`
  display: flex;
  position: relative;
  width: ${dim(343)};
  svg.input-base {
    path {
      stroke: ${({ theme, palette }) =>
        theme.colors.palettes[palette].input.primary.default.border} !important;
    }
    filter: drop-shadow(
        ${makeSizesString([-1, 2, 0])}
          ${({ theme, palette }) =>
            theme.colors.palettes[palette].input.primary.default.shadow}
      )
      drop-shadow(${makeSizesString([0, 2, 8])} rgba(0, 0, 0, 0.07))
      drop-shadow(${makeSizesString([0, 2, 10])} rgba(0, 0, 0, 0.08));
  }
`;

// the SVG is offset 9px x and 7px y from the container for primary input

export const TextInputLabel = styled.label<{
  palette: ThemePalette;
  variant: ThemeVariant;
}>`
  display: flex;
  position: absolute;
  align-items: center;
  height: ${dim(25)};
  padding: 0 ${({ theme }) => theme.space[1]};
  width: auto;
  background: ${({ theme }) => theme.colors.white};
  font-family: '${({ theme }) => theme.fonts['body']}', serif;
  font-size: ${({ theme }) => theme.fontSizes[0]};
  color: ${({ theme, palette }) =>
    theme.colors.palettes[palette].input.primary.default.border};
  ${() => {
    return variant({
      variants: {
        primary: css`
          top: ${dim(-5)};
          left: ${dim(20)};
        `,
        secondary: css`
          top: ${dim(5)};
          left: ${dim(20)};
        `,
      },
    });
  }}
`;

const TextInputIcon = styled.div<{
  variant: ThemeVariant;
  side: 'left' | 'right';
}>`
  display: flex;
  position: absolute;
  ${({ side }) => {
    return variant({
      variants: {
        primary: css`
          top: ${dim(27)};
          left: ${side === 'left' ? dim(20) : 'initial'};
          right: ${side === 'right' ? dim(20) : 'initial'};
        `,
        secondary: css`
          top: ${dim(33)};
          left: ${side === 'left' ? dim(20) : 'initial'};
          right: ${side === 'right' ? dim(20) : 'initial'};
        `,
      },
    });
  }}
`;

export type TextInputProps = {
  variant: ThemeVariant;
  palette: ThemePalette;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  onClickIconLeft?: () => void;
  onClickIconRight?: () => void;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const TextInput: React.FC<TextInputProps> = (props) => {
  const {
    variant = 'primary',
    iconLeft,
    iconRight,
    onClickIconLeft,
    onClickIconRight,
    ...styledProps
  } = props;
  const { palette, name, placeholder, type = 'text' } = styledProps;
  const [showLabel, setShowLabel] = React.useState(false);
  const handleFocus = () => setShowLabel(true);
  const handleBlur = () => setShowLabel(false);
  return (
    <TextInputWrapper palette={palette}>
      {variant === 'secondary' ? (
        <TextInputBase2 className="input-base" />
      ) : (
        <TextInputBase1 className="input-base" />
      )}
      <StyledTextInput
        {...styledProps}
        variant={variant}
        offset={!!iconLeft}
        type={type}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {iconLeft && (
        <TextInputIcon variant={variant} side="left" onClick={onClickIconLeft}>
          {React.cloneElement(iconLeft, {
            color:
              baseTheme.colors.palettes[palette].button.primary.default.text,
          })}
        </TextInputIcon>
      )}
      {iconRight && (
        <TextInputIcon
          variant={variant}
          side="right"
          onClick={onClickIconRight}
        >
          {React.cloneElement(iconRight, {
            color:
              baseTheme.colors.palettes[palette].button.primary.default.text,
          })}
        </TextInputIcon>
      )}
      {showLabel && placeholder && (
        <TextInputLabel variant={variant} palette={palette} htmlFor={name}>
          {placeholder}
        </TextInputLabel>
      )}
    </TextInputWrapper>
  );
};

export default TextInput;
