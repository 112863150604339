import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { Caption2, Text } from '../../typography';
import { DrawingAreaLine, HotspotPanelProps } from '../../../types';
import { Image, Layer, Line, Stage } from 'react-konva';
import useImage from 'use-image';
import { useTheme } from '@emotion/react';
import Box from '../../Box';
import { dim } from '../../../theme';
import { PanelCTA } from '../PanelCTA';
import { down } from 'styled-breakpoints';

const StyledHotspotPanel = styled(Box)`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: ${dim(56)} 0 ${({ theme }) => theme.space[4]};
  ${down('mobile')} {
    padding: ${dim(40)} 0 ${({ theme }) => theme.space[3]};
  }
`;

const HotspotData = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space[3]};
  margin: ${({ theme }) => theme.space[3]};
  border: ${dim(1)} solid ${({ theme }) => theme.colors.blues['60']};
`;

export const HotspotPanel: React.FC<HotspotPanelProps> = ({
  width,
  height,
  src,
  alt,
  cta,
  hotspots,
  onHotspotToggled,
}) => {
  const [image] = useImage(src);
  const { colors } = useTheme();

  const handleHotspotToggled = useCallback(
    (key: number) => {
      return () => {
        onHotspotToggled(key);
      };
    },
    [onHotspotToggled],
  );

  return (
    <StyledHotspotPanel
      width={dim(width)}
      minHeight={dim(height)}
      aria-label={alt}
    >
      <PanelCTA>{cta}</PanelCTA>
      <Stage width={width} height={height} className="canvas-stage">
        <Layer>
          <Image image={image} />
        </Layer>
        <Layer>
          {Object.values(hotspots).map(({ key, active, region: { lines } }) => {
            return lines.map((line: DrawingAreaLine) => (
              <Line
                key={`${key}-${line.key}`}
                points={line.points.map((point) => point * width)}
                stroke={colors.blues['60']}
                strokeWidth={4}
                fill={colors.blues['60'] + (active ? '33' : '00')}
                tension={0.15}
                lineCap="round"
                closed
                globalCompositeOperation="source-over"
                onClick={handleHotspotToggled(key)}
                onTap={handleHotspotToggled(key)}
              />
            ));
          })}
        </Layer>
      </Stage>
      {Object.values(hotspots)
        .filter(({ active }) => active)
        .map(({ key, header, body }) => {
          return (
            <HotspotData key={key}>
              <Caption2 variant="plain" color="blues.60">
                {header}
              </Caption2>
              <Text>{body}</Text>
            </HotspotData>
          );
        })}
    </StyledHotspotPanel>
  );
};

export default HotspotPanel;
