import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import Box from '../../Box';
import {
  isMatchingAnswerRequired,
  isMatchingQuestionRequired,
  MatchingPanelProps,
} from '../../../types';
import { dim } from '../../../theme';
import { PanelCTA } from '../PanelCTA';
import { down } from 'styled-breakpoints';
import { PanelCaption } from '../PanelCaption';
import { MatchingQuestionItem } from './MatchingQuestionItem';
import { Text } from '../../typography';
import Button2 from '../../inputs/Button2';

const StyledMatchingPanel = styled(Box)`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: ${dim(56)} 0 ${({ theme }) => theme.space[4]};
  ${down('mobile')} {
    padding: ${dim(40)} 0 ${({ theme }) => theme.space[3]};
  }
`;

const MatchingAnswerItem = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin: ${({ theme }) => theme.space[2]} 0;
  padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[2]}
    ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
  border-radius: ${({ theme }) => theme.radii['md']};
  background-color: ${({ theme }) => theme.colors.blues['50']};
  font-family: ${({ theme }) => theme.fonts['body']};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${dim(14)};
`;

export const MatchingPanel: React.FC<MatchingPanelProps> = ({
  width,
  height,
  caption,
  questions,
  answers,
  response,
  image,
  cta,
  instructions,
  onAnswerChange,
  onSubmit,
}) => {
  const [responsesState, setResponsesState] = React.useState<
    Record<string, string | undefined>
  >(
    questions
      .map((q) => q?.label)
      .filter((q): q is string => typeof q === 'string')
      .reduce((acc, id) => ({ ...acc, [id]: null }), {}),
  );

  useEffect(() => {
    if (response) {
      setResponsesState((prev) => {
        return { ...prev, ...response };
      });
    }
  }, [response, setResponsesState]);

  const handleChange = (label: string) => {
    return (value: string) => {
      setResponsesState((prev) => {
        return { ...prev, [label]: value };
      });
      onAnswerChange(label, value);
    };
  };

  return (
    <StyledMatchingPanel width={dim(width)} minHeight={dim(height)}>
      {caption.replaceAll(' ', '') ? (
        <PanelCTA align="right">{cta}</PanelCTA>
      ) : null}
      {caption.replaceAll(' ', '') && image ? image : null}
      {caption.replaceAll(' ', '') ? (
        <Box>
          <PanelCaption>{caption}</PanelCaption>
          <Text>{instructions}</Text>
        </Box>
      ) : null}
      <Box mt="3">
        {questions.filter(isMatchingQuestionRequired).map((question) => {
          return (
            <MatchingQuestionItem
              key={question.label}
              question={question.text}
              label={question.label}
              answer={responsesState[question.label]}
              onChange={handleChange(question.label)}
            />
          );
        })}
      </Box>
      <Box mt="0">
        {answers.filter(isMatchingAnswerRequired).map((answer) => {
          return (
            <MatchingAnswerItem key={answer.label}>
              {answer.label}. {answer.text}
            </MatchingAnswerItem>
          );
        })}
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-end" mt="3">
        <Button2
          type="button"
          disabled={Object.values(responsesState).some((v) => !v)}
          onClick={onSubmit}
          palette={'blue'}
          variant={'secondary'}
        >
          Done
        </Button2>
      </Box>
    </StyledMatchingPanel>
  );
};

export default MatchingPanel;
