import React from 'react';
import styled from '@emotion/styled';
import { ReactComponent as SignupButtonBackground } from './signup_button.svg';
import Box from '../../../Box';
import { dim } from '../../../../theme';

const StyledSignupButton = styled(Box)`
  position: relative;
  display: block;
`;

const SignupButtonLabel = styled.span`
  white-space: nowrap;
  position: absolute;
  top: ${dim(21)};
  left: ${dim(45)};
  font-family: ${({ theme }) => theme.fonts['body']};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  color: ${({ theme }) => theme.colors.blues['60']};
`;

export const SignupButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <StyledSignupButton onClick={onClick} aria-label="sign up">
      <SignupButtonBackground width="136" height="50" viewBox="0 0 136 50" />
      <SignupButtonLabel>Sign Up</SignupButtonLabel>
    </StyledSignupButton>
  );
};
