import styled from '@emotion/styled';
import Box from '../../Box';
import { dim } from '../../../theme';
import React from 'react';
import { transition } from '../utis';
import { down } from 'styled-breakpoints';

export const HorizontalComicCardLayerLower = styled(Box, {
  target: 'styledHorizontalComicCardArtLower',
})`
  display: flex;
  position: absolute;
  transition: ${transition('transform', 0.3)};

  &:after {
    content: '';
    background-color: ${({ theme }) => theme.colors.blues['30']};
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: ${dim(10)};
    transition: ${transition('opacity')};
  }
`;

export const HorizontalComicCardLayerMid = styled(Box, {
  target: 'styledHorizontalComicCardArtMid',
})`
  display: flex;
  position: absolute;
  transition: ${transition('transform', 0.3)};
`;

export const HorizontalComicCardLayerUpper = styled(
  HorizontalComicCardLayerMid,
  {
    target: 'styledHorizontalComicCardArtUpper',
  },
)``;

const StyledHorizontalComicCardArt = styled(Box, {
  target: 'styledHorizontalComicCardArt',
})`
  position: relative;
  width: ${dim(400)};
  min-height: ${dim(240)};
  filter: hue-rotate();
  ${down('mobile')} {
    width: ${dim(254)};
    min-height: ${dim(150)};
  }
`;
export const HorizontalComicCardArt: React.FC<{
  lowerLayer: React.ReactNode;
  midLayer: React.ReactNode;
  upperLayer: React.ReactNode;
}> = ({ lowerLayer, midLayer, upperLayer }) => (
  <StyledHorizontalComicCardArt>
    {lowerLayer && (
      <HorizontalComicCardLayerLower>
        {lowerLayer}
      </HorizontalComicCardLayerLower>
    )}
    {midLayer && (
      <HorizontalComicCardLayerMid>{midLayer}</HorizontalComicCardLayerMid>
    )}
    {upperLayer && (
      <HorizontalComicCardLayerUpper>
        {upperLayer}
      </HorizontalComicCardLayerUpper>
    )}
  </StyledHorizontalComicCardArt>
);
