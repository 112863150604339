import React from 'react';
import styled from '@emotion/styled';
import { Header5, Text } from '../../typography';
import {
  GenericOverlay,
  roundedContainer,
  roundedContainerMobile,
} from '../GenericOverlay';
import Box from '../../Box';
import { down } from 'styled-breakpoints';
import { BaseTheme } from '../../../theme';
import IconButton from '../../inputs/IconButton';
import { Icon } from '@iconify/react';
import closeBig from '@iconify/icons-ci/close-big';
import { useTheme } from '@emotion/react';
import SocialMediaBar from '../../SocialMediaBar';
import { ShareTarget } from '../../../types';

const StyledShareOverlay = styled(GenericOverlay)``;

const ShareOverlayContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${({ theme }) => roundedContainer(theme)};
  margin: 0 auto;
  ${down('mobile')} {
    ${({ theme }) => roundedContainerMobile(theme)};
  }
`;

const ShareOverlayHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space[3]};
  width: 100%;
`;

const ShareOverlayBody = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow-y: auto;
  max-height: 67vh;

  ${down('mobile')} {
    width: 100%;
  }
`;

export interface ShareOverlayProps {
  title: string;
  link: string;
  onShare(target: ShareTarget): () => void;
  onRequestClose(): void;
}

type Overlay = React.FC<ShareOverlayProps>;

export const ShareOverlay: Overlay = ({
  title,
  link,
  onShare,
  onRequestClose,
}) => {
  const {
    colors: { black },
  } = useTheme() as BaseTheme;
  const handleBackdropClick = (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (ev.target === ev.currentTarget) {
      onRequestClose();
    }
  };
  return (
    <StyledShareOverlay onClick={handleBackdropClick}>
      <ShareOverlayContent aria-modal role={'dialog'}>
        <ShareOverlayHeader>
          <Header5 mb="0">{title}</Header5>
          <IconButton onClick={onRequestClose}>
            <Icon icon={closeBig} height={30} color={black} />
          </IconButton>
        </ShareOverlayHeader>
        <ShareOverlayBody>
          <Text pb="4" color={black}>
            {link}
          </Text>
          <Box
            mx="auto"
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            <SocialMediaBar withLayout={false} onShare={onShare} />
          </Box>
        </ShareOverlayBody>
      </ShareOverlayContent>
    </StyledShareOverlay>
  );
};

export default ShareOverlay;
