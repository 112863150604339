import React from 'react';
import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import overflowMenuVertical from '@iconify/icons-carbon/overflow-menu-vertical';
import { Caption, Header5, Text } from '../../typography';
import { ReadingListsItemData } from '../../../types';
import IconButton from '../../inputs/IconButton';
import { ReadingListsItemThumbnail } from './thumbnail';
import { dim, pluralize } from '../../../theme';
import { down } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-emotion';
import { DetailDropDown, DetailDropDownEntry } from '../../inputs';
import { Maybe } from '../../../types/utils';

const StyledReadingListsItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${dim(40)};
  width: 100%;
  cursor: pointer;
`;

const ReadingListsItemDetail = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 0 0 ${({ theme }) => theme.space[3]};
  justify-content: flex-start;
  align-items: flex-start;
`;

const ReadingListsItemHeader = styled(Text)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;
  height: ${dim(32)};
  margin-bottom: ${({ theme }) => theme.space[2]};
  ${down('mobile')} {
    height: ${dim(29)};
  }
`;

const ReadingListsItemTitle = styled(Header5)`
  font-weight: 400;
  ${down('mobile')} {
    font-size: ${dim(24)};
  }
`;

const ReadingListsItemHeaderCount = styled(Caption)`
  color: ${({ theme }) => theme.colors.black};
  ${down('mobile')} {
    font-size: ${dim(14)};
  }
`;

export const ReadingListsItem: React.FC<{
  readingList: Omit<ReadingListsItemData, 'body' | 'image'>;
  readingListImage?: Maybe<JSX.Element>;
  frameColor: string;
  onItemClick: (ev: React.MouseEvent<HTMLDivElement>) => void;
  onEditClick: () => void;
  onDeleteClick: () => void;
}> = ({
  readingList: { count, name },
  readingListImage,
  frameColor,
  onItemClick,
  onEditClick,
  onDeleteClick,
}) => {
  const [showDetail, setShowDetail] = React.useState(false);
  const isMobile = useBreakpoint(down('mobile'));
  const total = count?.total || 0;
  return (
    <StyledReadingListsItem onClick={onItemClick}>
      <ReadingListsItemThumbnail
        readingListImage={readingListImage}
        total={total}
        frameColor={frameColor}
      />
      <ReadingListsItemDetail>
        <ReadingListsItemHeader>
          <ReadingListsItemHeaderCount>
            {total} {pluralize(total, 'Story', 'Stories')}
          </ReadingListsItemHeaderCount>
          <IconButton
            onClick={(event) => {
              setShowDetail(true);
              event.stopPropagation();
            }}
            aria-label={`view ${name} details`}
          >
            <Icon icon={overflowMenuVertical} width={isMobile ? 29 : 32} />
          </IconButton>
        </ReadingListsItemHeader>
        {showDetail && (
          <DetailDropDown inset={50}>
            <DetailDropDownEntry
              onClick={(event: React.MouseEvent<HTMLParagraphElement>) => {
                onEditClick();
                setShowDetail(false);
                event.stopPropagation();
              }}
              label="Edit"
            ></DetailDropDownEntry>
            <DetailDropDownEntry
              onClick={(event: React.MouseEvent<HTMLParagraphElement>) => {
                onDeleteClick();
                setShowDetail(false);
                event.stopPropagation();
              }}
              label="Delete"
            ></DetailDropDownEntry>
          </DetailDropDown>
        )}
        <ReadingListsItemTitle>{name}</ReadingListsItemTitle>
      </ReadingListsItemDetail>
    </StyledReadingListsItem>
  );
};

export default ReadingListsItem;
