import React from 'react';
import styled from '@emotion/styled';
import { Header1, Text } from '../typography';
import { RichText } from '../typography/RichText';

const StyledAboutUs = styled.div`
  display: flex;
  flex-direction: column;
`;

const AboutUsInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.space[3]} 0;
`;

const AboutUsContacts = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.space[3]} 0;
`;

const AboutUsContact = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.space[3]};
`;

export interface AboutUsProps {
  title?: string;
  subtitle: string;
  body: string;
  contacts: {
    email: string;
    purpose: string;
  }[];
}

export const AboutUs: React.FC<AboutUsProps> = ({
  title = 'About Us',
  subtitle,
  body,
  contacts,
}) => {
  return (
    <StyledAboutUs>
      <Header1>{title}</Header1>
      <AboutUsInfo>
        <Text fontWeight="bold">{subtitle}</Text>
        <Text>
          <RichText text={body} />
        </Text>
      </AboutUsInfo>
      <AboutUsContacts>
        <Text fontWeight="bold">Contacts</Text>
        {contacts.map((contact) => {
          return (
            <AboutUsContact key={contact.email}>
              <Text fontWeight="bold">
                <a href={`mailto:${contact.email}`}>{contact.email}</a>
              </Text>
              <Text>
                <RichText text={contact.purpose} />
              </Text>
            </AboutUsContact>
          );
        })}
      </AboutUsContacts>
    </StyledAboutUs>
  );
};

export default AboutUs;
