import { black, grays, white } from '../colors';
import {
  ThemeElementPalette,
  ThemeElementPaletteVariantState,
  ThemeElementStateProperty,
} from './types';

const grayDefaultPrimary: Record<ThemeElementStateProperty, string & {}> = {
  background: white,
  border: grays['60'],
  shadow: grays['60'],
  text: grays['60'],
};
const grayDisabledPrimary: Record<ThemeElementStateProperty, string & {}> = {
  ...grayDefaultPrimary,
  border: grays['30'],
  text: grays['30'],
};
const grayDownPrimary: Record<ThemeElementStateProperty, string & {}> = {
  ...grayDefaultPrimary,
  background: grays['20'],
  border: grays['80'],
  text: grays['80'],
};
const grayHoverPrimary: Record<ThemeElementStateProperty, string & {}> = {
  ...grayDefaultPrimary,
  border: grays['50'],
  shadow: grays['50'],
  text: grays['50'],
};
const grayVariantDefaultPrimary: ThemeElementPaletteVariantState = {
  default: { ...grayDefaultPrimary },
  disabled: { ...grayDisabledPrimary },
  down: { ...grayDownPrimary },
  hover: { ...grayHoverPrimary },
};

const grayDefaultSecondary: Record<ThemeElementStateProperty, string & {}> = {
  background: grays['60'],
  border: grays['60'],
  shadow: grays['60'],
  text: white,
};
const grayDisabledSecondary: Record<ThemeElementStateProperty, string & {}> = {
  ...grayDefaultSecondary,
  background: grays['20'],
  shadow: grays['20'],
  border: grays['20'],
};
const grayDownSecondary: Record<ThemeElementStateProperty, string & {}> = {
  ...grayDefaultSecondary,
  background: grays['80'],
  border: grays['80'],
  text: grays['40'],
};
const grayHoverSecondary: Record<ThemeElementStateProperty, string & {}> = {
  ...grayDefaultSecondary,
  background: grays['50'] + 'FF',
  border: grays['50'] + 'FF',
  shadow: black + '26',
};
const grayVariantDefaultSecondary: ThemeElementPaletteVariantState = {
  default: { ...grayDefaultSecondary },
  disabled: { ...grayDisabledSecondary },
  down: { ...grayDownSecondary },
  hover: { ...grayHoverSecondary },
};

export const grayPalette: ThemeElementPalette = {
  button: {
    primary: { ...grayVariantDefaultPrimary },
    secondary: { ...grayVariantDefaultSecondary },
  },
  default: {
    primary: { ...grayVariantDefaultPrimary },
    secondary: { ...grayVariantDefaultSecondary },
  },
  input: {
    primary: { ...grayVariantDefaultPrimary },
    secondary: { ...grayVariantDefaultSecondary },
  },
};
