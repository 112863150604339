import React from 'react';
import styled from '@emotion/styled';
import {
  background,
  BackgroundProps,
  layout,
  LayoutProps,
} from 'styled-system';
import { dim } from '../../../theme';
import { Caption, Header5, Header7, Text } from '../../typography';
import { StoryIntroData } from '../../../types';
import Box from '../../Box';
import { down, up } from 'styled-breakpoints';

const IndexComicCardThumbnail = styled(Box, { target: 'comic-card-thumbnail' })<
  BackgroundProps & LayoutProps
>`
  display: flex;
  flex-grow: 0;
  width: ${dim(200)};
  height: ${dim(355)};
  margin: 0;
  border-radius: ${dim(20)};
  filter: drop-shadow(
    ${dim(16.5122)} ${dim(3.88523)} ${dim(11.6557)} rgba(0, 0, 0, 0.05)
  );
  z-index: 2;
  overflow: hidden;
  ${down('mobile')} {
    width: ${dim(125)};
    height: ${dim(222)};
    margin: 0;
    filter: drop-shadow(
      ${dim(10.9545)} ${dim(2.57752)} ${dim(7.73256)} rgba(0, 0, 0, 0.05)
    );
  }
  ${background}
  ${layout};
`;

const IndexComicCardDetail = styled(Text, { target: 'comic-card-detail' })`
  display: block;
  position: relative;
  width: ${dim(200)};
  min-height: ${dim(130)};
  margin-top: 0;
  margin-bottom: 0;
  padding: ${dim(22)} 0 0;
  background-color: unset;
  color: ${({ theme }) => theme.colors.black};
  box-sizing: border-box;
  ${down('mobile')} {
    width: ${dim(125)};
    min-height: ${dim(105)};
    padding: ${({ theme }) => theme.space[3]} 0 0;
  }
`;

const IndexComicCardGenres = styled(Caption, { target: 'comic-card-genres' })`
  ${up('mobile')} {
    font-size: ${dim(14)};
  }
`;

const IndexComicCardTitle = styled(Header5, { target: 'comic-card-title' })`
  margin-top: ${({ theme }) => theme.space[2]};
  font-size: ${dim(24)};
  font-weight: ${({ theme }) => theme.fontWeights['semiBold']};
  ${down('mobile')} {
    margin-top: ${({ theme }) => theme.space[1]};
    font-size: ${dim(18)};
  }
`;

const IndexComicCardCreators = styled(Header7, {
  target: 'comic-card-creators',
})`
  margin-top: ${dim(6)};
  font-size: ${dim(14)};
  font-weight: ${({ theme }) => theme.fontWeights['regular']};
  ${down('mobile')} {
    margin: ${({ theme }) => theme.space[2]} 0;
    font-size: ${dim(12)};
  }
`;

const StyledIndexComicCard = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${dim(222)};
  background-color: unset;
  border-radius: ${dim(24)};
  padding: ${dim(11)};

  ${down('mobile')} {
    padding: ${({ theme }) => theme.space[2]};
    width: ${dim(141)};
  }
`;

type Optional<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>;

export const IndexComicCard: React.FC<
  Optional<
    Omit<
      StoryIntroData,
      'prologue' | 'images' | 'countries' | 'country' | 'characters'
    > & {
      imageVertical?: React.ReactNode;
      onClick: () => void;
    },
    'creators'
  >
> = ({ genres, title, creators, imageVertical, onClick }) => {
  return (
    <StyledIndexComicCard onClick={onClick} aria-label={title} role="link">
      <IndexComicCardThumbnail>{imageVertical}</IndexComicCardThumbnail>
      <IndexComicCardDetail>
        <IndexComicCardGenres>
          {genres.map((genre) => genre.name).join(', ')}
        </IndexComicCardGenres>
        <IndexComicCardTitle>{title}</IndexComicCardTitle>
        {!!creators && (
          <IndexComicCardCreators>
            {creators?.map(({ creator }) => creator.name).join(', ')}
          </IndexComicCardCreators>
        )}
      </IndexComicCardDetail>
    </StyledIndexComicCard>
  );
};
